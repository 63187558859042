import React from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { SELECTION_CHANGE_COMMAND, $getSelection, $isRangeSelection, $isTextNode } from "lexical";

const LOW_PRIORITY = 1;

class SelectionPluginClass extends React.Component {
	componentDidMount() {
		const { editor, onSelect } = this.props;

		// Register a command listener for SELECTION_CHANGE_COMMAND
		this.unregisterSelection = editor.registerCommand(
			SELECTION_CHANGE_COMMAND,
			() => {
				const selection = $getSelection();

				// Ensure we have a valid range selection
				if ($isRangeSelection(selection)) {
					// Grab the caret (anchor) offset
					const offset = selection.anchor.offset;

					// Declare variable to hold if there is a valid mention in this selection
					let isCurrentlyMentioning = false;
					let mentionText = "";

					// Get the text node where the caret (anchor) is
					const anchorNode = selection.anchor.getNode();

					// Ensure it's a text node
					if ($isTextNode(anchorNode)) {
						// Full text content of the anchor node
						const fullText = anchorNode.getTextContent();
						// The text *before* the caret
						const textBeforeCursor = fullText.slice(0, offset);

						// Matches an "@" that is preceded by either start of the line/string (^)
						// or whitespace (\s), capturing all non-whitespace (\S) characters after "@"
						// until the end of the string ($).
						const match = textBeforeCursor.match(/(?:^|\s)@(\S*)$/);

						if (match) {
							isCurrentlyMentioning = true;

							// match[1] will be the mention text after the '@'
							mentionText = match[1];
						}
					}

					// Call the parent onSelect callback with this offset
					if (onSelect) {
						onSelect(isCurrentlyMentioning, mentionText);
					}
				}

				// Return false to allow other plugins to also handle this
				return false;
			},
			LOW_PRIORITY
		);
	}

	componentWillUnmount() {
		// Unregister the command listener on unmount
		if (this.unregisterSelection) {
			this.unregisterSelection();
		}
	}

	render() {
		// This plugin doesn't render anything
		return null;
	}
}

// Functional wrapper, similar to PressEnterPlugin
function SelectionPlugin({ onSelect }) {
	const [editor] = useLexicalComposerContext();
	return <SelectionPluginClass editor={editor} onSelect={onSelect} />;
}

export default SelectionPlugin;
