import React, { PureComponent } from "react";
import { List } from "react-content-loader";
import * as Icon from "react-feather";

import _ from "underscore";

import UtilityService from "../../../../services/UtilityService";
import RealtimeService from "../../../../services/WebsocketsConnection";
import NotificationService from "../../../../services/NotificationService";
import TeamChatService from "../../../../services/TeamChatService";
import LightboxService from "../../../../services/LightboxService";
import ToastService from "../../../../services/ToastService";
import UserService from "../../../../services/UserService";
import LocationService from "../../../../services/LocationService";

import Spinners from "../../../../components/common/Spinners";

import TeamChatMessage from "./TeamChatMessage";

import "./team-chat-message-list.css";

const MESSAGE_ORDERING_LOOKBACK = 10;

class TeamChatMessageList extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			count: 0,
			messages: [],
			media: [],
			tasks: [],
			limit: 25,
			mediaIndex: 0,
			referenceMessageId: null,

			isBottomReached: false,
			isTopReached: false,

			scrolledToBottom: true,
			isScrolling: false,
			hasNewMessages: false,
			showNewestButton: false
		};

		this.bottomOfThread = null;
		this.newLine = null;

		this.messageRef = null;
		this.tcml = null;

		this.loadMoreDebounced = _.debounce(
			async (top, height) => {
				let loadedMore = await this.loadMore(top);
				if (loadedMore && top && this.props.setScrollTop) {
					this.props.setScrollTop(height);
				}

				return { isBottomReached: this.state.isBottomReached, isTopReached: this.state.isTopReached };
			},
			1000,
			{
				leading: true,
				trailing: true
			}
		);
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	componentDidMount() {
		this.resetComponent();
		NotificationService.subscribeOnce("realtimeConnected", "teamChatMessageList_componentDidMount", () => {
			UtilityService.gracefullyReset(() => this.resetComponent());
		});

		NotificationService.subscribeOnce("internalMessageUpdated", "messageList_componentDidMount", event => {
			this.updateMessage(event);
		});
	}

	componentDidUpdate = async prevProps => {
		let { senderId, conversationId, referenceMessageId } = this.props;

		if (prevProps.senderId !== senderId || prevProps.conversationId !== conversationId) {
			await this.update({
				isTopReached: false,
				isBottomReached: false,
				scrolledToBottom: true,
				hasNewMessages: false,
				referenceMessageId: null
			});
			this.resetComponent();
			return;
		}

		if (prevProps.referenceMessageId !== referenceMessageId) {
			this.resetComponent();
			return;
		}
	};

	scrolledToBottom(scrolledToBottom) {
		// While isScrolling the scrolledToBottom does not change
		if (this.state.isScrolling) {
			return;
		}

		// If we've scrolled to the bottom of the thread
		if (scrolledToBottom) {
			this.update({
				hasNewMessages: false
			});
		}

		this.update({
			scrolledToBottom
		});
	}

	showNewestButton(showSeeNewestMessagesButton) {
		this.update({ showNewestButton: showSeeNewestMessagesButton });
	}

	scrollToBottom(animated = false, forceBottom = false) {
		let options = animated ? { behavior: "smooth", block: "center", inline: "end" } : !forceBottom;

		// We want the React lifecycle to complete before scrolling
		setTimeout(() => {
			if (this.bottomOfThread) {
				this.bottomOfThread.scrollIntoView(options);
			}
		}, 0);
	}

	scrollToReference() {
		setTimeout(() => {
			if (this.messageRef) {
				this.messageRef.scrollIntoView({ behavior: "instant", block: "center", inline: "end" });
			}
		}, 0);
	}

	async resetComponent() {
		let { senderId, conversationId, highlightReference, referenceMessageId } = this.props;

		referenceMessageId = referenceMessageId || null;
		let cachedMessages = this.state.messages;

		await this.update({
			referenceMessageId
		});

		let isReferenceInMessages = this.isReferenceInMessages();

		await this.update({
			loading: true,
			senderId,
			conversationId,
			highlightReference,
			scrolledToBottom: true,
			hasNewMessages: false,
			messages: []
		});

		let fetch = { fetchTop: !this.state.isTopReached, fetchBottom: !this.state.isBottomReached };

		if (referenceMessageId) {
			fetch = { fetchTop: true, fetchBottom: true, isReferenceInMessages };
		}

		this.fetchMessagePins();

		let fetchResult = await this.fetchMessages(fetch);

		if (fetchResult === null) {
			await this.update({
				messages: cachedMessages
			});
		}

		await this.update({ loading: false });

		referenceMessageId = this.state.referenceMessageId;

		if (!referenceMessageId) {
			this.scrollToBottom();
		}

		this.fetchTasks();
	}

	fetchTasks = async () => {
		let { conversationId } = this.props;

		let locationId = UserService.getActiveLocation().id;

		let tasks = await LocationService.fetchTeamChatTasks({ locationId, conversationId, ignoreDone: true });

		await this.update({ tasks });
	};

	fetchMessagePins = async () => {
		let { fetchMessagePins } = this.props;

		if (fetchMessagePins) {
			fetchMessagePins();
		}
	};

	markMessagesRead(messages) {
		messages.forEach(message => {
			if (message.unread) {
				RealtimeService.markMessageRead(message);
			}
		});
	}

	getScrollHeight = () => {
		if (this.tcml) {
			this.tcml.scrollTop = this.tcml.scrollHeight;

			return this.tcml.scrollHeight;
		}
	};

	isReferenceInMessages = () => {
		let { referenceMessageId, messages } = this.state;

		if (!messages || messages.length === 0) {
			return false;
		}
		return messages.findIndex(m => m.id === referenceMessageId) >= 0;
	};

	async fetchMessages(
		{ fetchTop = false, fetchBottom = false, isReferenceInMessages = false } = { fetchTop: false, fetchBottom: false, isReferenceInMessages: false }
	) {
		let { conversationId, senderId, referenceMessageId, isBottomReached, isTopReached } = this.state;

		if (isBottomReached && fetchBottom && isReferenceInMessages) {
			return null;
		}

		if (isTopReached && fetchTop) {
			return null;
		}

		let { messages, media } = this.state;

		let result = await TeamChatService.fetchMessages({
			conversationId,
			userId: senderId,
			messageId: referenceMessageId,
			fetchTop,
			fetchBottom
		});

		if (!result) {
			ToastService.error("Failed to fetch messages. Please try again.");
			return;
		}

		let { messages: newMessages, isTopReached: newIsTopReached, isBottomReached: newIsBottomReached, unreadMessageId } = result;

		// Extract all the src urls for any media for the full screen image viewer
		let newMedia = UtilityService.extractMedia(newMessages);

		// If we are feching messages from the top
		if (fetchTop) {
			media.unshift(...newMedia);
			messages.unshift(...newMessages);
		} else if (fetchBottom) {
			// If we are fetching messages from the bottom
			media.push(...newMedia);
			messages.push(...newMessages);
		} else {
			media = newMedia;
			messages = newMessages;
		}

		// For sanity, we ensure that all the messages are unique
		messages = _.uniq(messages, m => m.id);

		if (!referenceMessageId && unreadMessageId) {
			referenceMessageId = unreadMessageId;
		}

		let updateStates = {
			messages: messages,
			referenceMessageId,
			media: media
		};

		if (newIsTopReached) {
			updateStates.isTopReached = newIsTopReached;
		}

		updateStates.isBottomReached = newIsBottomReached;

		updateStates.scrolledToBottom = newIsBottomReached;

		await this.update(updateStates);

		if (referenceMessageId) {
			this.scrollToReference();
		}

		// Notify other sessions that you have read the conversation
		RealtimeService.markInternalConversationRead(conversationId, senderId);

		// Notify this session that you have read the conversation
		setTimeout(() => {
			NotificationService.publish("markInternalMessageRead", {});
		}, 1000);
	}

	async loadMore(isTop = false) {
		let { messages, isTopReached, isBottomReached } = this.state;

		let referenceMessageId = null;

		if (!messages || messages.length < 1) {
			return;
		}

		if (isTop && !isTopReached) {
			referenceMessageId = messages[0].id;
		} else if (!isTop && !isBottomReached) {
			referenceMessageId = messages[messages.length - 1].id;
		}

		if (!referenceMessageId) {
			return false;
		}

		await this.update({
			referenceMessageId,
			highlightReference: false
		});

		await this.fetchMessages(isTop ? { fetchTop: true } : { fetchBottom: true });
		return true;
	}

	async updateMessage({ message_id, content, content_html, content_json, reactions, status_events, status, unread }) {
		let { messages } = this.state;

		for (let message of messages) {
			if (message.id === message_id) {
				if (content) {
					message.content = content;
				}

				if (content_html) {
					message.content_html = content_html;
				}

				if (content_json) {
					message.content_json = content_json;
				}

				if (reactions) {
					message.reactions = reactions;
				}

				if (status_events) {
					message.status_events = status_events;
				}

				if (status) {
					message.status = status;
				}

				if (unread) {
					TeamChatService.setUnreadMessage(message_id);
				}
			}
		}

		await this.update({ messages });

		this.forceUpdate();
	}

	async appendMessage(message, animatedScroll = false, forceBottom = false) {
		let { scrolledToBottom } = this.state;

		await this.update(state => {
			let messages = state.messages.concat(message);

			messages = _.uniq(messages, m => m.id);

			return {
				messages: messages
			};
		});

		await this.updateMedia();

		let userId = UserService.get().id;

		// If we are not at the bottom of the thread, show the has new messages button
		if (!scrolledToBottom && message.sender_user_id !== userId) {
			await this.update({
				hasNewMessages: true
			});
			return;
		}

		this.scrollToBottom(animatedScroll, forceBottom);
	}

	async updateMedia() {
		let { messages } = this.state;
		let media = UtilityService.extractMedia(messages);

		await this.update({
			media: media
		});
	}

	async onNewMessage(message, animatedScroll = true, forceBottom = true) {
		let { messages, conversationId, senderId } = this.state;

		let lastMessage = messages[messages.length - 1];

		// Append the completly new message
		if (lastMessage && lastMessage.id < message.id) {
			await this.appendMessage(message, animatedScroll, forceBottom);

			RealtimeService.markInternalConversationRead(conversationId, senderId);
		} else {
			// Do a simple look back to see if we can insert an old message
			// This can happen when many messages come at the same time in the wrong order
			const indexToBeginSearch = Math.max(0, messages.length - MESSAGE_ORDERING_LOOKBACK);

			for (let i = indexToBeginSearch; i < messages.length; i++) {
				const prevMessage = messages[i];

				if (prevMessage.id > message.id) {
					// Insert the incoming message before the previous message
					await this.insertMessageBefore(message, prevMessage);

					RealtimeService.markInternalConversationRead(conversationId, senderId);
					break;
				}
			}
		}
	}

	async insertMessageBefore(newMessage, beforeMessage) {
		const index = this.state.messages.findIndex(message => message.id === beforeMessage.id);

		if (index !== -1) {
			const updatedMessages = [...this.state.messages];

			updatedMessages.splice(index, 0, newMessage);

			await this.update({ messages: updatedMessages });
		}
	}

	async onMediaClicked(url) {
		await this.update({
			mediaIndex: this.getMediaIndex(url)
		});
		LightboxService.setMedia({ media: this.state.media, mediaIndex: this.state.mediaIndex });
		LightboxService.open();
	}

	async onForwardMessage(message) {}

	onReplyToMessageClicked = async messageId => {
		if (this.props.onReplyToMessageClicked) {
			await this.props.onReplyToMessageClicked(messageId);
		}
	};

	onMarkAsUnread = async message => {
		this.updateMessage({
			message_id: message.id,
			unread: true
		});
	};

	onPinMessage = async () => {
		this.fetchMessagePins();
	};

	getMediaIndex(url) {
		let { media } = this.state;

		for (let i = 0; i < media.length; i++) {
			if (media[i].src === url) {
				return i;
			}
		}
		return 0;
	}

	onNewestClicked = async () => {
		await this.update({
			referenceMessageId: null,
			isScrolling: true,
			showNewestButton: false
		});

		await this.fetchMessages();

		this.scrollToBottom(true, true);

		await this.update({
			hasNewMessages: false,
			scrolledToBottom: true
		});

		// While isScrolling the scrolledToBottom does not change
		setTimeout(() => {
			this.update({
				isScrolling: false
			});
		}, 1000);
	};

	onReplyMessageClicked = async messageReplyId => {
		if (this.props.onReplyClicked) {
			this.props.onReplyClicked(messageReplyId);
		}
	};

	onCreateTaskMessageClick = async messageId => {
		if (this.props.onCreateTaskMessageClick) {
			this.props.onCreateTaskMessageClick(messageId);
		}
	};

	renderMessageItem(message, index) {
		let { messages, referenceMessageId, highlightReference, tasks } = this.state;
		let { messagePins } = this.props;

		let previousMessage = messages[index - 1];

		let isReference = message.id === referenceMessageId;

		// Check if there is an associated task
		let hasAssociatedTask = false;
		if (tasks && tasks.length > 0) {
			hasAssociatedTask = tasks.find(t => t.message_id === message.id) ? true : false;
		}

		return (
			<TeamChatMessage
				key={message.id}
				ref={ref => {
					if (isReference) {
						this.messageRef = ref;
					}
				}}
				isReference={isReference}
				highlightReference={highlightReference || false}
				hasAssociatedTask={hasAssociatedTask}
				message={message}
				previousMessage={previousMessage}
				onMediaClicked={url => this.onMediaClicked(url)}
				onReplyClicked={messageReplyId => this.onReplyMessageClicked(messageReplyId)}
				onReplyToMessageClicked={messageId => this.onReplyToMessageClicked(messageId)}
				onMarkAsUnread={this.onMarkAsUnread}
				onCreateTaskMessageClick={() => this.onCreateTaskMessageClick(message.id)}
				onPinMessage={this.onPinMessage}
				messagePins={messagePins}
			/>
		);
	}

	renderMessageList() {
		let { messages } = this.state;

		return messages.map((message, index) => {
			return this.renderMessageItem(message, index);
		});
	}

	renderNewestButton() {
		let { hasNewMessages, loading, scrolledToBottom, showNewestButton } = this.state;

		if (scrolledToBottom) {
			return null;
		}

		if (hasNewMessages) {
			return (
				<div className="mb-tcml-newest-unread" onClick={this.onNewestClicked}>
					<Icon.ChevronDown size="20" />
					You have new messages
				</div>
			);
		}

		if (loading) {
			return null;
		}

		if (!showNewestButton) {
			return null;
		}

		return (
			<div className="mb-tcml-newest" onClick={this.onNewestClicked}>
				<Icon.ChevronDown size="20" />
			</div>
		);
	}

	render() {
		let { loading, isTopReached, isBottomReached } = this.state;

		return (
			<div className="mb-tcml" ref={ref => (this.tcml = ref)}>
				<div className="mb-message-list-loader">
					{loading && (
						<>
							<List />
							<List />
							<List />
						</>
					)}
				</div>
				{!loading && !isTopReached && (
					<div className="mb-tcml-load-more">
						<Spinners loading={true} type="circle" size="2px" />
					</div>
				)}
				{!loading && this.renderMessageList()}
				{!loading && !isBottomReached && (
					<div className="mb-tcml-load-more">
						<Spinners loading={true} type="circle" size="2px" />
					</div>
				)}
				<div className="mb-tcml-bottom">
					<div ref={ref => (this.bottomOfThread = ref)} />
				</div>
				{this.renderNewestButton()}
			</div>
		);
	}
}

export default TeamChatMessageList;
