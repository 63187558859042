import React from "react";
import * as Icon from "react-feather";
import ReactTooltip from "react-tooltip";

import ReengagementService from "../../services/ReengagementService";
import UserService from "../../services/UserService";
import { AnalyticsService } from "../../services/AnalyticsService";
import SupportChatService from "../../services/SupportChatService";
import CrmIntegrationsService from "../../services/CrmIntegrationsService";

import withLocation from "../../components/common/WithLocation";
import DashboardCard from "./DashboardCard";
import { DASHBOARD_CARDS } from "../../constants/Dashboard";

class ReengagementsSent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			reengagementsSent: 0,
			hasCrmIntegrations: false
		};
	}

	componentDidMount() {
		this.fetchData();
	}

	onLocationChanged = () => {
		this.fetchData();
	};

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	fetchData = async () => {
		try {
			let locationId = UserService.getActiveLocation().id;
			let result = await AnalyticsService.getReengagementsSentCount({ locationId });
			let crmIntegrations = await CrmIntegrationsService.fetch({
				locationId,
				params: { limit: 1, status: ["active"] }
			});

			await this.update({
				reengagementsSent: result.count,
				hasCrmIntegrations: crmIntegrations && crmIntegrations.length > 0 ? true : false
			});
		} catch (error) {
			console.log(error);
		}
	};

	onReengagementsGetStarted = () => {
		SupportChatService.showNewMessage("Hi, I would like to enable Reengagement features. Would you be able to help?");
	};

	renderHeader = () => {
		const { cardName, isEnabled } = this.props;
		let { hasCrmIntegrations } = this.state;

		return (
			<div className="dashboard__card__header">
				<div className="dashboard__card__header__icon">
					<Icon.Calendar size="42" />
				</div>
				<div className="dashboard__card__header__name dashboard__card__header__name--margin-left">
					{cardName ? cardName : "Reengagements Sent"} <Icon.Info size={20} data-tip data-for="dashboard-widget-reengagements-sent-info" />{" "}
				</div>
				<ReactTooltip
					id="dashboard-widget-reengagements-sent-info"
					className="mb-react-tooltip mb-react-tooltip--medium"
					arrowColor="#333"
					type="info"
					effect="solid"
					place="right"
				>
					Shows the total number of reengagement messages sent.
					{isEnabled && !isEnabled() ? " Requires the Reengagement module to be enabled." : null}
					{!hasCrmIntegrations ? " Requires a CRM Integration to be active." : null}
				</ReactTooltip>
			</div>
		);
	};

	renderBody = () => {
		let { reengagementsSent, hasCrmIntegrations } = this.state;
		const { isEnabled, isPermissible } = this.props;

		if (isEnabled && !isEnabled()) {
			return (
				<div className="dashboard__widget__conversions">
					<div className="mb-button" onClick={this.onReengagementsGetStarted}>
						Get Started
					</div>
				</div>
			);
		}

		let content = "N/A";

		// Only show if there are active crm integrations and there is at least one conversion
		if (hasCrmIntegrations && reengagementsSent && reengagementsSent > 0) {
			content = reengagementsSent;
		}
		if (isPermissible && !isPermissible()) {
			content = "N/A";
		}

		return (
			<div className="dashboard__card__stat">
				<div className="dashboard__card__stat__count">{content}</div>
			</div>
		);
	};

	render() {
		return (
			<DashboardCard className={DASHBOARD_CARDS.reengagementsSent.className} height={DASHBOARD_CARDS.reengagementsSent.h}>
				{this.renderHeader()}
				{this.renderBody()}
			</DashboardCard>
		);
	}
}

export default withLocation(ReengagementsSent);
