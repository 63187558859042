import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import c3 from "c3";

import GAService from "../../services/GAService";

import Page from "../../components/common/Page";
import Header from "../../components/common/Header";
import Tabs from "../../components/common/Tabs";
import Tab from "../../components/common/Tab";
import withLocation from "../../components/common/WithLocation";
import WidgetAnalytics from "./WidgetAnalytics";

import { AnalyticsConstants } from "../../constants/AnalyticsConstants";

import "../../App.css";
import "react-day-picker/lib/style.css";
import "../../styles/css/scenes/analytics.css";

class WidgetAnalyticsPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedTab: this.props.selectedTab ? this.props.selectedTab : AnalyticsConstants.APP_NAME.webchat
		};
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
	}

	handleFromChange = start => {
		// Change the from date and focus the "to" input field
		this.setState({ start });
	};

	handleToChange = end => {
		this.setState({ end }, this.showFromMonth);
	};

	showFromMonth() {
		const { start, end } = this.state;
		if (!start) {
			return;
		}
		if (moment(end).diff(moment(start), "months") < 2) {
			this.to.getDayPicker().showMonth(start);
		}
	}

	generateChart(id, columns) {
		try {
			const { start, end } = this.state;
			const isMonth = moment(end).diff(moment(start), "days") >= 90;
			c3.generate({
				bindto: id,
				data: {
					x: "x",
					columns: columns,
					type: "line"
				},
				axis: {
					x: {
						label: "Day",
						type: "timeseries",
						tick: {
							format: function(x) {
								if (isMonth) {
									const monthString = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
									return "" + x.getFullYear() + " " + monthString[x.getMonth()];
								}
								//return monthString[x.getMonth()];
								return "" + x.getFullYear() + "-" + (x.getMonth() + 1) + "-" + x.getDate();
							},
							rotate: window.innerWidth > 768 ? 0 : 75,
							multiline: false
							//format: '%Y' // format string is also available for timeseries data
						}
					},
					y: {
						label: "Counts"
					}
				}
			});
		} catch (error) {
			console.log(error);
		}
	}

	onTabSelect = async tab => {
		await this.update({ selectedTab: tab.id });
	};

	render() {
		const { selectedTab } = this.state;

		let cardName = "Widget Usage";

		if (selectedTab === AnalyticsConstants.APP_NAME.webchat) {
			cardName = "Webchat Widget Usage";
		} else if (selectedTab === AnalyticsConstants.APP_NAME.reviewsWidget) {
			cardName = "Reviews Widget Usage";
		} else if (selectedTab === AnalyticsConstants.APP_NAME.bookingWidget) {
			cardName = "Booking Widget Usage";
		}

		return (
			<Page>
				<Header title="Widget Analytics" />

				<Tabs onSelect={this.onTabSelect} selected={selectedTab}>
					<Tab id={AnalyticsConstants.APP_NAME.webchat} value={"Webchat"} />
					<Tab id={AnalyticsConstants.APP_NAME.reviewsWidget} value={"Reviews Widget"} />
					<Tab id={AnalyticsConstants.APP_NAME.bookingWidget} value={"Booking Widget"} />
				</Tabs>
				<div className="dh-page__cards">
					<WidgetAnalytics cardName={cardName} selectedTab={selectedTab} />
				</div>
			</Page>
		);
	}
}

export default withRouter(withLocation(WidgetAnalyticsPage));
