import React from "react";
import * as Icon from "react-feather";
import { Redirect, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import Page from "../../../components/common/Page";
import Header from "../../../components/common/Header";
import Filters from "../../../components/common/Filters";
import withLocation from "../../../components/common/WithLocation";
import Action from "../../../components/common/Action";
import Workflow from "./Workflow";
import WorkflowRecipesModal from "./WorkflowRecipesModal/WorkflowRecipesModal";

import GAService from "../../../services/GAService";
import UserService from "../../../services/UserService";
import WorkflowService from "../../../services/WorkflowService";
import ToastService from "../../../services/ToastService";

import { STATUS } from "../../../constants/KeywordsConstants";

import "../../../styles/css/components/commons/workflows.css";

class Workflows extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			workflowsConfig: null,
			workflows: [],
			statusFilter: STATUS.active,
			showWorkflowRecipes: false
		};

		this.addWorkflowRef = null;
		this.recipesModal = null;
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	componentDidMount = async () => {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.resetComponent();
	};

	onLocationChanged = () => {
		this.resetComponent();
	};

	resetComponent = async () => {
		let locationId = UserService.getActiveLocation().id;

		await this.fetchWorkflowsConfig();

		if (this.recipesModal) {
			this.recipesModal.resetComponent();
		}

		let { statusFilter } = this.state;
		let workflows = await WorkflowService.fetchWorkflows({ locationId, status: [statusFilter], sortField: "priority", sortOrder: "asc" });
		await this.update({
			workflows,
			showWorkflowRecipes: false
		});
	};

	fetchWorkflowsConfig = async () => {
		let { t } = this.props;

		let workflowsConfig = await WorkflowService.getCachedWorkflowsConfig();

		if (!workflowsConfig) {
			ToastService.error(t("An error occurred trying to get workflow configuration data."));
			return;
		}

		await this.update({
			workflowsConfig
		});
	};

	onStatusSelected = async item => {
		await this.update({ statusFilter: item.id });
		await this.resetComponent();
	};

	isStatusSelected = item => {
		return item === this.state.statusFilter;
	};

	getFilters = () => {
		let { workflowsConfig } = this.state;
		let statuses = Object.keys(STATUS).map(item => {
			return { id: item, value: workflowsConfig ? workflowsConfig.statuses[item] : item };
		});

		let filters = {
			statuses: {
				title: "",
				items: statuses,
				onClick: this.onStatusSelected,
				isSelected: this.isStatusSelected
			}
		};

		return filters;
	};

	onWorkflowRecipeSelected = async workflowRecipe => {
		await this.update({ showWorkflowRecipes: false });

		if (!workflowRecipe) {
			return;
		}

		let { workflows } = this.state;

		let newWorkflows = [...workflows];

		let addWorkflows = JSON.parse(workflowRecipe);

		for (let i = 0; i < addWorkflows.length; i++) {
			const newWorkflow = addWorkflows[i];

			// Massage some data just to be safe
			newWorkflow.id = 0;
			newWorkflow.location_id = UserService.getActiveLocation().id;
			newWorkflow.action_template_id = null;
			newWorkflow.action_crm_id = null;
			newWorkflow.action_bot_id = null;
			newWorkflow.action_inbox_id = null;
			newWorkflow.action_tag_id = 0;
			newWorkflow.action_users = null;
			newWorkflow.action_group_id = 0;
			newWorkflow.action_conversation_id = 0;
			newWorkflow.action_tag_id = 0;
			newWorkflow.action_webhook_id = 0;

			// Append workflow to the end
			newWorkflows.push(newWorkflow);
		}

		await this.update({
			workflows: newWorkflows
		});
	};

	onAddWorkflow = async index => {
		let { workflowsConfig, statusFilter } = this.state;
		let { t } = this.props;

		let newWorkflows = {
			location_id: UserService.getActiveLocation().id,
			name: t("Workflow Name"),
			priority: 1,
			trigger_type: workflowsConfig.assignableTriggers[0].value,
			conditions: [],
			action_type: workflowsConfig.assignableActions[0].value,
			status: statusFilter // Default the status to be the same as the selected status filter
		};

		let { workflows } = this.state;

		// Append workflow to the end
		let copy = workflows.slice();
		copy.splice(index, 0, newWorkflows);

		await this.update({
			workflows: copy
		});

		if (this.addWorkflowRef) {
			this.addWorkflowRef.scrollIntoView({ behavior: "smooth", block: "center", inline: "end" });
		}
	};

	/**
	 * Save a specific workflow
	 * @param {Number} index The index of where the workflow is in the array
	 * @param {Object} workflow The new workflow object
	 * @param {Boolean} save If we want to save the workflow in the backend
	 * @returns
	 */
	onWorkflowChanged = async (index, workflow, save) => {
		let { workflows } = this.state;
		let { t } = this.props;

		// If the workflow with the specified index does not exist. Then do not proceed!
		if (!workflows[index]) {
			return;
		}

		// Update the workflow we made changes to
		let oldWorkflow = { ...workflows[index] };

		workflows[index] = workflow;

		// If we don't want to save the workflow in the backend, we can just return here.
		if (!save) {
			await this.update({ workflows });
			return workflows[index];
		}

		let newWorkflow = null;

		// If we want to save the workflow, we will have to properly format the condition parameters
		if (workflow.conditions) {
			for (let i = 0; i < workflow.conditions.length; i++) {
				const condition = workflow.conditions[i];

				if (!condition.params) {
					continue;
				}

				// Properly format param values
				condition.params = WorkflowService.formatParamValues(condition.params);
			}
		}

		// If there is an Id for the workflow, we are updating an existing workflow
		if (workflow.id) {
			newWorkflow = await WorkflowService.update(workflow);
		} else {
			// Create a new workflow
			workflow.locationId = UserService.getActiveLocation().id;
			newWorkflow = await WorkflowService.create(workflow);
		}

		// If there was an error saving the workflow
		if (!newWorkflow) {
			ToastService.error(t("An error occurred trying to save workflow"));
			return workflows[index];
		}

		workflows[index] = newWorkflow;

		await this.update({ workflows });

		// Only reset the component if the priority changes or the status changes
		if (oldWorkflow.status !== newWorkflow.status || oldWorkflow.priority !== newWorkflow.priority) {
			await this.resetComponent();
		}

		return newWorkflow;
	};

	onRemoveWorkflow = async index => {
		let { workflows } = this.state;
		let { t } = this.props;

		let copy = workflows.slice();

		copy.splice(index, 1);

		await this.update({
			workflows: copy
		});

		ToastService.info(t("Succesfully discarded workflow"));
	};

	renderAddMore = () => {
		let { workflows } = this.state;

		let user = UserService.get();
		if (!user.GroupPermission.create_workflows) {
			return null;
		}

		return (
			<div ref={ref => (this.addWorkflowRef = ref)} className="workflows__add-more">
				<Icon.PlusCircle className="workflows__add-more__icon" size={50} strokeWidth={1} onClick={() => this.onAddWorkflow(workflows.length)} />
			</div>
		);
	};

	renderWorkflows = () => {
		let { workflows, workflowsConfig } = this.state;
		if (!workflows || workflows.length < 1) {
			return null;
		}

		return (
			<div className="workflows">
				{workflows.map((workflow, index) => (
					<Workflow
						key={workflow.id ? workflow.id : `new-${index}`}
						index={workflow.id ? workflow.id : `new-${index}`}
						workflow={workflow}
						onWorkflowChanged={(newWorkflow, save) => this.onWorkflowChanged(index, newWorkflow, save)}
						onRemoveWorkflow={() => this.onRemoveWorkflow(index)}
						workflowsConfig={workflowsConfig}
					/>
				))}
				{this.renderAddMore()}
			</div>
		);
	};

	render() {
		let { workflows, showWorkflowRecipes } = this.state;
		let { t } = this.props;
		let user = UserService.get();

		if (!WorkflowService.canViewWorkflows()) {
			return <Redirect to="/settings" />;
		}

		return (
			<Page>
				<Header title={t("Workflows")}>
					<div className="workflows-actions__recipes">
						<WorkflowRecipesModal
							ref={ref => (this.recipesModal = ref)}
							show={showWorkflowRecipes}
							onClose={() => this.update({ showWorkflowRecipes: false })}
							onSelect={this.onWorkflowRecipeSelected}
						/>

						<Action id="workflow-recipe" label={t("Workflow Recipe")} icon={Icon.BookOpen} onClick={() => this.update({ showWorkflowRecipes: true })} />
					</div>
					<Action id="refresh" label={t("Refresh Data")} icon={Icon.RefreshCcw} onClick={() => this.resetComponent()} />
					{user.GroupPermission.create_workflows && (
						<Action id="create-new" label={t("Create New")} icon={Icon.Plus} onClick={() => this.onAddWorkflow(workflows.length)} />
					)}
				</Header>
				<Filters filters={this.getFilters()} />
				{this.renderWorkflows()}
			</Page>
		);
	}
}

export default withRouter(withLocation(withTranslation(null, { withRef: true })(Workflows)));
