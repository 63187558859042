import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import UserService from "../../services/UserService";
import PollingService from "../../services/PollingService";
import Alert from "./Alert";

class CheckLoginInterval extends Component {
	state = {
		isLoggedIn: true
	};

	componentDidMount() {
		PollingService.addListener("checkAuth", () => this.checkAuthApiCall());
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	async checkAuthApiCall() {
		try {
			await UserService.checkAuthToken();
		} catch (errorResponse) {
			if (typeof errorResponse.response !== "undefined") {
				if (errorResponse.response.status === 401) {
					console.log(errorResponse.response.data.error);
					await this.update({ isLoggedIn: false });
				}
			}
		}
	}

	render() {
		let { isLoggedIn } = this.state;

		if (!isLoggedIn) {
			return (
				<Alert
					type="error"
					show={true}
					title={"Session Expired"}
					confirm="Okay"
					onClose={() => {
						UserService.clear();
						this.props.history.push("/login");
					}}
				>
					<div>{"Your session has expired. Please login again."}</div>
				</Alert>
			);
		}
		return null;
	}
}

export default withRouter(CheckLoginInterval);
