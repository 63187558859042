import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { withTranslation } from "react-i18next";

import Main from "./components/layouts/Main";
import NotFound from "./components/layouts/NotFound";
import ResetMediumSelect from "./scenes/ResetPassword/ResetMediumSelect";
import Login from "./scenes/Login/Login";
import ResetPasswordPage from "./scenes/ResetPassword/ResetPasswordPage";
import VerifySmsToken from "./scenes/ResetPassword/VerifySmsToken";
import VerifyEmailToken from "./scenes/ResetPassword/VerifyEmailToken";
import UserSignUp from "./scenes/UserInvites/UserSignUp";
import ErrorBoundary from "./components/common/ErrorBoundary";
import Maintenance from "./scenes/Maintenance/Maintenance";
import VerifyPin from "./scenes/Login/VerifyPin";
import Modal from "./components/common/DHModal";

import ToastService from "./services/ToastService";
import StatusService from "./services/StatusService";
import Version from "./services/VersionService";
import PollingService from "./services/PollingService";

import { BACKEND_STATUSES, APP_STOPPAGE_REASONS } from "./constants/Errors";

import "./App.css";

const REFRESH_AWS_TIMER_CHECK = 30 * 1000; // every 30 seconds
const IDLE_LIMIT = 1000 * 60 * 60 * 4; // 4 hours max idle time

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			backendStatusTimer: null,
			shouldCheckIdleStatus: false,
			stopWebApp: false,
			stoppageReason: null,
			showNewVersionModal: false,
			updating: false,
			loading: true
		};

		// Store the last interaction time
		this.lastInteractionTime = Date.now();

		this.versionUpdateInterval = null;
		this.networkErrorToast = null;
		this.idleToast = null;
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	async componentDidMount() {
		PollingService.initialize();
		await this.listenForUpdates();
		this.registerIdleActivity();
		await this.checkBackendStatus();
		this.pollBackendStatus();

		await this.update({ loading: false });
	}

	componentWillUnmount() {
		this.clearBackendPolling();
		this.deregisterIdleActivity();
		PollingService.clearAllListeners();
	}

	registerIdleActivity = () => {
		document.addEventListener("visibilitychange", this.handleVisibilityChange);
	};

	deregisterIdleActivity = () => {
		document.removeEventListener("visibilitychange", this.handleVisibilityChange);
	};

	handleVisibilityChange = async () => {
		let { shouldCheckIdleStatus } = this.state;

		if (shouldCheckIdleStatus && document.visibilityState === "visible") {
			// Stop checking idle status
			await this.update({ shouldCheckIdleStatus: false });

			// Check if we need to refresh the page
			this.checkIdleTime();
		} else if (!shouldCheckIdleStatus && document.visibilityState === "hidden") {
			// We will now be checking idle status next time visibility changes
			await this.update({ shouldCheckIdleStatus: true });

			// Set current time to be the last interaction time
			this.lastInteractionTime = Date.now();
		}
	};

	checkIdleTime = () => {
		let { t } = this.props;

		if (Date.now() - this.lastInteractionTime >= IDLE_LIMIT) {
			if (this.idleToast) {
				ToastService.dismiss(this.idleToast);
			}

			let idleToastMessage = t("Refreshing data due to idle time.");
			this.idleToast = ToastService.error(idleToastMessage, "mb-toast mb-toast--error", 25 * 1000);

			// Refresh the page after 3 seconds
			setTimeout(function() {
				window.location.reload(true);
			}, 3000);
		}
	};

	pollBackendStatus = () => {
		let { backendStatusTimer } = this.state;

		backendStatusTimer = setInterval(() => {
			this.checkBackendStatus();
		}, REFRESH_AWS_TIMER_CHECK);

		this.update({ backendStatusTimer });
	};

	clearBackendPolling = () => {
		let { backendStatusTimer } = this.state;

		if (backendStatusTimer) {
			clearInterval(backendStatusTimer);
		}
	};

	checkBackendStatus = async () => {
		let { t } = this.props;

		try {
			// If we are local, don't check anything
			if (process.env.REACT_APP_ENV !== "production" && process.env.REACT_APP_ENV !== "stage") {
				return;
			}

			let backendStatus = await StatusService.checkBackendStatus();

			if (backendStatus.primaryStatus === BACKEND_STATUSES.maintenance || backendStatus.webAppStatus === BACKEND_STATUSES.maintenance) {
				await this.update({ stopWebApp: true, stoppageReason: APP_STOPPAGE_REASONS.maintenance });
			} else {
				await this.update({ stopWebApp: false, stoppageReason: null });
			}
		} catch (error) {
			// Network Error
			if (this.networkErrorToast) {
				ToastService.dismiss(this.networkErrorToast);
			}

			let toastError = t("Network Error: Could not connect to the internet.");

			this.networkErrorToast = ToastService.error(toastError, "mb-toast mb-toast--error", 25 * 1000);

			console.log(error);
		}
	};

	async listenForUpdates() {
		if (!this.versionUpdateInterval) {
			await this.upgrade();
			this.versionUpdateInterval = PollingService.addListener("upgradeVersionAndUserData", () => this.upgrade());
		}
	}

	async upgrade() {
		const shouldUpgrade = await Version.shouldUpgrade();
		if (shouldUpgrade) {
			await this.update({ showNewVersionModal: true });
		}

		await Version.updateUserData();
	}

	handleHardReload = async () => {
		let { updating } = this.state;

		if (updating) {
			return;
		}

		await this.update({ updating: true });

		setTimeout(function() {
			window.location.reload(true);
		}, 1000);
	};

	renderUpgradeModal() {
		let { t } = this.props;
		let { updating, showNewVersionModal } = this.state;

		if (!showNewVersionModal) {
			return null;
		}

		return (
			<Modal show={showNewVersionModal} title={t("New Version")} onHide={() => {}}>
				<div className="modal__flex-container">
					{t("A new version of DemandHub is available.")}
					<br />
					<br />
					<div className="modal__actions">
						<div className={`mb-button ${updating ? "mb-button--disabled" : ""}`} onClick={this.handleHardReload}>
							{t("Upgrade")}
						</div>
					</div>
				</div>
			</Modal>
		);
	}

	render() {
		let { stopWebApp, stoppageReason, loading } = this.state;

		let showMaintenancePage = stopWebApp && (stoppageReason === APP_STOPPAGE_REASONS.maintenance || stoppageReason === APP_STOPPAGE_REASONS.degraded);

		return (
			<BrowserRouter>
				<>
					{this.renderUpgradeModal()}
					{showMaintenancePage && <Maintenance stoppageReason={stoppageReason} />}
					{!showMaintenancePage && !loading && (
						<ErrorBoundary goTo="/login">
							<Switch>
								<Route path="/error" render={props => <NotFound {...props} general={true} />} />
								<Route path="/login" component={Login} />
								<Route path="/verify/:token" component={VerifyPin} />
								<Route exact path="/forgot/password" component={ResetMediumSelect} />
								<Route path="/forgot/password/verify-token/phone" component={VerifySmsToken} />
								<Route path="/forgot/password/verify-token/email" component={VerifyEmailToken} />
								<Route path="/password/reset" component={ResetPasswordPage} />
								<Route path="/invite/:guid" component={UserSignUp} />
								<Route path="/index.html" render={() => <Redirect to="/" />} />
								<ErrorBoundary goTo="/dashboard" checkAuthToken={true} goToOnAuthFailure="/login">
									<Route path="/" component={Main} />
								</ErrorBoundary>
							</Switch>
						</ErrorBoundary>
					)}
				</>
			</BrowserRouter>
		);
	}
}

export default withTranslation(null, { withRef: true })(App);
