import _ from "underscore";
import moment from "moment";
import "whatwg-fetch";
import AppConfig from "../config/app/web-app.config.js";

import UserService from "./UserService";
import Kichiri from "./KichiriService";
import UtilityService from "./UtilityService";

import { TEMPLATE_TYPE } from "../constants/TemplateConstants";
import { MEDIUM, MEDIA_TYPES_CONVERSION, MEDIA_TYPES } from "../constants/Messenger";
import { SM_STATES, SM_TYPES } from "../constants/ScheduledMessages";
import LocationService from "./LocationService.js";

const Base58 = require("base58");
const heicConvert = require("heic-convert");

// This global variable will hold the sms provider metadata for the location's number
var providerMetaData = {};

var localMedia = {};

let messengerListReference = null;

const MessagesService = {
	/**
	 * Get the relative dates for message dividers
	 *
	 * @param {Date} date
	 */
	getRelativeDate(date) {
		let momentDate = moment(date);

		if (momentDate.year() !== moment().year()) {
			return momentDate.format("ddd, MMM Do, YYYY");
		}

		return momentDate.calendar(null, {
			sameDay: "[Today]",
			nextDay: "[Tomorrow]",
			nextWeek: "dddd",
			lastDay: "[Yesterday]",
			lastWeek: "ddd, MMM Do",
			sameElse: "ddd, MMM Do"
		});
	},

	/**
	 * Helper function for formating a phone number to a more readable format
	 *
	 * @param {String} phone
	 * @return {String}
	 */
	formatPhoneNumber(phone) {
		try {
			return phone.replace(/(\+)(\d{1})(\d{3})(\d{3})(\d{4})/, "($3) $4-$5");
		} catch (error) {
			console.log(error);
		}

		return "";
	},

	/**
	 * Helper function to determine whether to append a date inbetween message to delinate a new date
	 *
	 * @param {Date} previous
	 * @param {Date} current
	 * @return {Boolean}
	 */
	shouldAppendDate(previous, current) {
		var previousDate = moment(previous).startOf("day");
		var currentDate = moment(current).startOf("day");

		return currentDate.diff(previousDate, "days") > 0;
	},

	/**
	 * Returns even friendlier medium display name
	 *
	 * @param {String} medium
	 * @param {String} phone
	 * @param {String} email
	 * @return {String}
	 */
	getMediumData(medium, phone, email) {
		switch (medium) {
			case "sms":
				phone = this.formatPhoneNumber(phone);
				if (!phone) {
					phone = "Private Number";
				}
				return phone;
			case "facebook":
				return "Facebook";
			case "instagram":
				return "Instagram";
			case "google":
				return "Google";
			case "email":
				return email;
			case "web":
				return "Web Chat";
			case "demandhub":
				return "TeamChat";
			case "secure":
				return "Secure Chat";
			default:
				return "Unknown";
		}
	},

	/**
	 * Return the url link for the profile pic
	 *
	 * @param {Object} conversation
	 * @return {String}
	 */
	getContactProfile(conversation) {
		return "https://cdn.demandhub.co/web-app/assets/sms.svg"; //conversation.profile_url || "https://cdn.demandhub.co/web-app/assets/sms.svg";
	},

	/**
	 * Fetches the total number of conversations
	 *
	 * @param {Number} locationId
	 * @param {String} status
	 * @return {Promise}
	 */
	async fetchTotalConversations(locationId, status) {
		let user = UserService.get();

		let count = 0;

		try {
			let response = await Kichiri.message.countConversations(
				{},
				{
					location: locationId,
					status: status
				},
				user.auth_token
			);

			count = response.data.count;
		} catch (error) {
			console.log(error);
		}

		return count;
	},

	/**
	 * Fetches all the conversations depending on the status and filter
	 *
	 * @param {Number} locationId
	 * @param {String} [status="active"]
	 * @param {String} [filter="all"]
	 * @param {Array} filterUserIds
	 * @param {Number} inboxId
	 * @param {Number} [limit=1000]
	 * @param {Number} [offset=0]
	 * @param {Array} conversationFilters
	 * @return {Promise}
	 */
	async fetchConversations({
		locationId,
		status = "active",
		filter = "all",
		filterUserIds = null,
		inboxId = null,
		limit = 10,
		offset = 0,
		conversationFilters = []
	}) {
		let user = UserService.get();

		let conversations = [];

		try {
			let response = await Kichiri.message.getConversations(
				{},
				{
					location: locationId,
					limit,
					offset,
					status,
					filter,
					filterUserIds,
					inboxId,
					conversationFilters
				},
				user.auth_token
			);

			conversations = response.data;
		} catch (error) {
			console.log(error);
		}

		return conversations;
	},

	/**
	 * Fetches all the messages for a specific contact and location
	 *
	 * @param {Number} locationId
	 * @param {Number} contactId
	 * @param {Number} [limit=1000]
	 * @param {Number} [offset=0]
	 * @return {Promise}
	 */
	async fetchMessages(locationId, contactId, limit = 10, offset = 0) {
		let user = UserService.get();

		let messages = [];

		try {
			let response = await Kichiri.message.fetchMessages(
				{},
				{
					location: locationId,
					contact: contactId,
					limit: limit,
					offset: offset
				},
				user.auth_token
			);

			messages = response.data.reverse();
		} catch (error) {
			console.log(error);
		}

		return messages;
	},

	/**
	 * Count the messages for a contact and location
	 *
	 * @param {Number} locationId
	 * @param {Number} contactId
	 * @return {Promise}
	 */
	async countMessages(locationId, contactId) {
		let user = UserService.get();

		try {
			let request = {
				location: locationId,
				contact: contactId
			};

			let response = await Kichiri.message.countMessages({}, request, user.auth_token);

			return response.data.count;
		} catch (error) {
			console.log(error);
		}

		return 0;
	},

	/**
	 * Count internal messages for a conversation
	 *
	 * @param {Number} conversationId
	 * @return {Promise}
	 */
	async countInternalMessages(conversationId) {
		let user = UserService.get();

		try {
			let request = { conversationId };

			let response = await Kichiri.message.countInternalMessages({}, request, user.auth_token);

			return response.data.count;
		} catch (error) {
			console.log(error);
		}

		return 0;
	},

	/**
	 * Creates an interal conversation which can be a channel, group, or DM
	 *
	 * @param {Array} userIds
	 * @param {String} name
	 * @param {String} type
	 * @param {String} channelType
	 * @return {Promise}
	 */
	async startInternalConversation(userIds, name, type, channelType) {
		let user = UserService.get();

		let conversation = null;

		try {
			let response = await Kichiri.message.startInternalConversation(
				{
					userIds: userIds,
					name: name,
					type: type,
					channelType
				},
				{},
				user.auth_token
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return conversation;
	},

	/**
	 * Sends a message for customer messaging
	 *
	 * @param {Number} locationId
	 * @param {Number} contactId
	 * @param {String} content
	 * @param {Array} mediaIds
	 * @param {String} [medium="sms"]
	 * @param {Number} [selectedTemplateId=null]
	 * @param {Boolean} [templateSelected=false]
	 * @param {String} eventType
	 * @param {Date} sendAfter
	 * @return {Promise}
	 */
	async sendMessage({
		locationId,
		contactId,
		content,
		subject,
		mediaIds = [],
		medium = "sms",
		selectedTemplateId = null,
		templateSelected = false,
		eventType = "message",
		sendAfter = null,
		showSignature = false
	}) {
		let user = UserService.get();
		let message = null;

		try {
			let response = await Kichiri.message.sendMessage(
				{
					location: locationId,
					contact: contactId,
					sender: user.id,
					message: content,
					subject,
					mediaIds,
					medium,
					selectedTemplateId,
					templateSelected,
					type: eventType,
					sendAfter,
					showSignature
				},
				{},
				user.auth_token
			);

			message = response.data;
		} catch (error) {
			console.log(error);
			return null;
		}

		return message;
	},

	/**
	 * Sends a message for team messaging
	 *
	 * @param {Number} locationId
	 * @param {Number} conversationId
	 * @param {String} content
	 * @param {String} contentJSON
	 * @param {String} contentHtml
	 * @param {Array} mediaIds
	 * @param {String} reply
	 * @param {Message} forward

	 * @return {Promise}
	 */
	async sendInternalMessage({ locationId, conversationId, content, contentJSON, contentHtml, mediaIds, reply, forward }) {
		let user = UserService.get();
		let message = null;

		try {
			let response = await Kichiri.message.sendMessage(
				{
					location: locationId,
					conversation: conversationId,
					sender: user.id,
					message: content,
					messageJSON: contentJSON,
					messageHtml: contentHtml,
					medium: MEDIUM.demandhub.key,
					mediaIds,
					reply,
					forward
				},
				{},
				user.auth_token
			);

			message = response.data;
		} catch (error) {
			console.log(error);
		}

		return message;
	},

	/**
	 * Searches all the customer messages for a specific location
	 *
	 * @param {Number} searchTerm
	 * @param {Number} locationId
	 * @return {Promise}
	 */
	async searchMessages(searchTerm, locationId) {
		let user = UserService.get();

		if (searchTerm.length === 0) {
			return [];
		}

		let requestBody = { searchTerm, locationId };

		try {
			let response = await Kichiri.message.searchMessages(requestBody, {}, user.auth_token);

			let parsedResults = response.data.map(result => {
				return this.getPhantomConversation({
					contactId: result.Contact.id,
					id: result.Contact.id,
					name: result.Contact.name,
					phone: result.Contact.phone,
					email: result.Contact.email,
					content: result.content,
					createdAt: result.created_at_date,
					preferredMedium: result.Contact.preferred_medium,
					status: result.Contact.status,
					assignedUserId: result.Contact.assigned_user_id,
					inbox: result.Contact && result.Contact.Inbox ? result.Contact.Inbox : null,
					paymentMethods: [],
					messengerIntegrations: []
				});
			});

			return parsedResults;
		} catch (error) {
			console.log(`An error occurred trying to fetch search results for conversations`);
		}

		return [];
	},

	/**
	 * Starts a new conversation with a new or existing contact
	 *
	 * @param {Number} locationId
	 * @param {String} name
	 * @param {String} phone
	 * @param {String} email
	 * @param {String} facebookId
	 * @param {String} instagramId
	 * @param {String} googleId
	 * @returns
	 */
	async createNewConversation({ locationId, name, phone, email, facebookId, instagramId, googleId }) {
		let user = UserService.get();

		try {
			let response = await Kichiri.message.startConversation(
				{
					location: locationId,
					name: name,
					phone: phone,
					email: email,
					facebookId,
					instagramId,
					googleId
				},
				{},
				user.auth_token
			);

			return response.data;
		} catch (error) {
			console.log(`An error occurred trying to create a new conversation.`);
		}

		return null;
	},

	/**
	 * Helper function to update a conversation in a list of conversation objects
	 *
	 * @param {Array} conversations
	 * @param {Message} message
	 * @param {Number} unread
	 * @return {Array}
	 */
	updateConversationList(conversations, message, unread) {
		let copy = conversations.slice();

		copy.forEach(function(convo) {
			if (convo.contact_id === message.contact_id) {
				convo.direction = message.direction;
				convo.content = message.content;
				convo.last_sent = message.created_at;
				convo.media_type = message.media && message.media.length > 0 ? message.media[0].type : null;
				convo.assigned_user_id = message.Contact.assigned_user_id;
				convo.message_state = message.message_state;
				convo.delivery_state = message.delivery_state;
				convo.delivery_code = message.delivery_code;
				convo.delivery_description = message.delivery_description;
				convo.sender_user_name = message.sender_user_name;
				convo.sender_user_id = message.sender_user_id;

				let appendUnread = convo.direction === "out" ? 0 : unread;
				convo.unread = unread === 0 ? 0 : parseInt(convo.unread, 10) + appendUnread;
			}
		});

		return copy;
	},

	/**
	 * Helper function to remove a conversation in a list of conversation objects
	 *
	 * @param {Array} conversations
	 * @param {Message} message
	 * @return {Array}
	 */
	removeFromConversationList(conversations, message) {
		let copy = conversations.slice();

		// Find the conversation and remove it from the conversation list
		for (let i = 0; i < copy.length; i++) {
			if (copy[i].contact_id === message.contact_id) {
				copy.splice(i, 1);
				break;
			}
		}

		return copy;
	},

	/**
	 * Helper function that appends a conversation to a list of conversation using a message object
	 *
	 * @param {Array} conversations
	 * @param {Message} message
	 * @return {Array}
	 */
	appendConversation(conversations, message) {
		let copy = conversations.slice();

		let conversation = {
			contact_id: message.contact_id,
			content: message.content,
			direction: message.direction,
			last_sent: message.updated_at,
			name: message.Contact.name,
			phone: message.Contact.phone,
			email: message.Contact.email,
			unread: 1,
			medium: message.medium,
			status: message.Contact.status,
			assigned_user_id: message.Contact.assigned_user_id,
			message_state: message.message_state,
			delivery_state: message.delivery_state,
			delivery_code: message.delivery_code,
			delivery_description: message.delivery_description
		};

		copy.push(conversation);

		return copy;
	},

	/**
	 * Helper function to sort a list of conversations
	 *
	 * @param {Array} conversations
	 * @return {Array}
	 */
	sortConversationList(conversations) {
		let copy = conversations.slice();

		copy.sort((c1, c2) => {
			let c1Date = moment(c1.last_sent);
			let c2Date = moment(c2.last_sent);

			if (c1Date < c2Date) {
				return 1;
			}

			if (c1Date > c2Date) {
				return -1;
			}

			return 0;
		});

		return copy;
	},

	/**
	 * Update the conversation status to either Open or Closed
	 *
	 * @param {Number} locationId
	 * @param {Number} contactId
	 * @param {String} status
	 * @return {Promise}
	 */
	async updateConversationStatus(locationId, contactId, status) {
		let user = UserService.get();

		try {
			let response = await Kichiri.contacts.update(
				{
					locationId: locationId,
					contactId: contactId,
					status: status
				},
				{},
				user.auth_token
			);

			return response;
		} catch (error) {
			console.log(`An error occurred trying to change a contact's status.`);
		}

		return null;
	},

	/**
	 * Upload media for a message
	 *
	 * @param {String} file
	 * @param {String} mode
	 * @param {String} medium
	 * @param {Boolean} optimize
	 * @param {Number} companyId
	 * @param {Number} locationId
	 * @return {String}
	 */
	async uploadMedia({ file, mode, medium, optimize = true, companyId, locationId }) {
		let user = UserService.get();

		try {
			let endpoint = `${AppConfig.API_SERVER}/api/message/media/upload`;
			let name = file.name;
			let extension = name.substring(name.lastIndexOf(".") + 1);

			let data = new FormData();

			if (file.type === "image/heic") {
				file = await this.convertHeicFileToJpg(file);
				extension = "jpeg";
			}

			data.append("file", file);
			data.append("locationId", locationId);
			data.append("companyId", companyId);
			data.append("extension", extension);
			data.append("mode", mode);
			data.append("medium", medium);
			data.append("optimize", optimize);

			let response = await fetch(
				endpoint,
				{
					method: "post",
					body: data,
					headers: {
						authorization: user.auth_token
					}
				},
				30000
			);

			if (!response.ok) {
				throw new Error("Error uploading media.");
			}

			let responseData = await response.json();
			return responseData;
		} catch (error) {
			console.log(error);
			throw new Error(`An error occurred trying to upload a media item to the platform.`);
		}
	},

	/**
	 * Fetches all the assignable users for that location
	 *
	 * @param {Number} locationId
	 * @return {Promise}
	 */
	async fetchAssignableUsers(locationId) {
		let user = UserService.get();

		try {
			let response = await Kichiri.user.fetchAssignable(
				{},
				{
					locationId: locationId
				},
				user.auth_token
			);

			return response.data;
		} catch (error) {
			console.log(`An error occurred trying to fetch assignable users ${error}`);
		}

		return [];
	},

	/**
	 * Update Assigned User for a contact
	 *
	 * @param {Number} locationId
	 * @param {Number} contactId
	 * @param {Number} assignedUserId
	 * @return {Promise}
	 */
	async updateAssignedUserForContact(locationId, contactId, assignedUserId) {
		let user = UserService.get();

		try {
			await Kichiri.contacts.update(
				{
					locationId: locationId,
					contactId: contactId,
					assignedUserId: assignedUserId
				},
				{},
				user.auth_token
			);

			return true;
		} catch (error) {
			console.log(error);
		}

		return false;
	},

	/**
	 * Updates a specific Contact's name
	 *
	 * @param {Number} locationId
	 * @param {Number} contactId
	 * @param {String} name
	 * @return {Promise}
	 */
	async updateContactName(locationId, contactId, name) {
		let user = UserService.get();

		try {
			await Kichiri.contacts.update(
				{
					locationId: locationId,
					contactId: contactId,
					name: name
				},
				{},
				user.auth_token
			);

			return true;
		} catch (error) {
			console.log(error);
		}

		return false;
	},

	/**
	 * Marks a message as spam
	 *
	 * @param {Number} messageId
	 * @return {Promise}
	 */
	async markMessageAsSpam(messageId) {
		let user = UserService.get();

		try {
			let request = { messageId };
			await Kichiri.message.markMessageAsSpam(request, {}, user.auth_token);

			return true;
		} catch (error) {
			console.log(error);
		}

		return false;
	},

	/**
	 * Marks a message as deleted
	 *
	 * @param {Number} messageId
	 * @return {Promise}
	 */
	async deleteMessage(messageId) {
		let user = UserService.get();

		try {
			let request = { messageId };
			await Kichiri.message.delete(request, {}, user.auth_token);

			return true;
		} catch (error) {
			console.log(error);
		}

		return false;
	},

	/**
	 * Fetches all the members in a conversation
	 *
	 * @param {Number} conversationId
	 * @return {Promise}
	 */
	async fetchMembers(conversationId) {
		let token = UserService.getAuthToken();

		try {
			let response = await Kichiri.message.fetchMembers(
				{},
				{
					conversationId: conversationId
				},
				token
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Clears all the message drafts stored for the current user
	 */
	clearMessageDrafts() {
		for (let i = 0; i < localStorage.length; i++) {
			if (localStorage.key(i).indexOf("dh_convo_message_draft_") !== -1) {
				localStorage.removeItem(localStorage.key(i));
			}
		}
	},

	/**
	 * Cache the message draft for a specific message
	 *
	 * @param {Number} contactId
	 * @param {String} content
	 */
	setMessageDraft(contactId, content) {
		localStorage.setItem("dh_convo_message_draft_" + contactId, content);
	},

	/**
	 * Cache the signature enable / disable preference for a specific contact
	 *
	 * @param {Number} contactId
	 * @param {String} content
	 */
	setSignaturePreference(contactId, enableSignatureBool) {
		localStorage.setItem("dh_convo_signature_enable_" + contactId, enableSignatureBool);
	},

	/**
	 * Get the cached signature enable preference for a specific contact
	 *
	 * @param {Number} contactId
	 * @param {String} content
	 * @return {Boolean} returns boolean if we have a signature preference set, null otherwise
	 */
	getSignaturePreference(contactId) {
		let signatureEnabled = localStorage.getItem("dh_convo_signature_enable_" + contactId);

		if (!signatureEnabled) {
			return null;
		} else if (signatureEnabled === "false") {
			return false;
		}

		return true;
	},

	/**
	 * Fetch the possible cached message for a specific contact
	 *
	 * @param {Number} contactId
	 * @return {String}
	 */
	getMessageDraft(contactId) {
		let messageDraft = "";
		messageDraft = localStorage.getItem("dh_convo_message_draft_" + contactId);

		if (!messageDraft) {
			messageDraft = "";
		}

		return messageDraft;
	},

	/**
	 * Send ADF Lead to the Location's CRM
	 *
	 * @return {Promise}
	 */
	async sendCRMLead(locationId, contactId) {
		try {
			let user = UserService.get();
			let response = await Kichiri.crm.sendLead({ locationId, contactId }, {}, user.auth_token);
			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Fetches the SMS safe number for a location
	 * From scene/Messages populateLocationNumber meethod. TODO: Add this information into Localstorage so we don't have to make a request
	 * @param {Number} locationId
	 * @return {String}
	 */
	async getSafeNumber(locationId) {
		try {
			if (!locationId) {
				locationId = UserService.getActiveLocation().id;
			}

			let data = await LocationService.fetchLocation(locationId);

			let number = data.numbers.find(n => n.safe === 1);

			return this.formatPhoneNumber(number.number);
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Creates a video chat object
	 *
	 * @param {Number} contactId
	 */
	async createVideoChat(contactId) {
		let user = UserService.get();
		let locationId = UserService.getActiveLocation().id;
		let userId = user.id;

		try {
			let response = await Kichiri.video_chat.createVideoChat(
				{
					locationId,
					contactId,
					userId
				},
				{},
				user.auth_token
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Creates a new secure chat
	 *
	 * @param {Number} contactId
	 * @returns {Object}
	 */
	async createSecureChat({ contactId }) {
		let user = UserService.get();
		let userId = user.id;

		try {
			let response = await Kichiri.secure_chat.create(
				{
					contactId,
					userId
				},
				{},
				user.auth_token
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * End a secure chat for a contact
	 *
	 * @param {Number} secureChatPublicId
	 * @returns {Object}
	 */
	async endSecureChat({ secureChatPublicId }) {
		let user = UserService.get();

		try {
			let response = await Kichiri.secure_chat.update(
				{
					secureChatPublicId,
					status: "inactive",
					userId: user.id
				},
				{},
				user.auth_token
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Fetches reply suggestions for the customers last inbound message from DeepChat
	 *
	 * @param {Integer} locationId
	 * @param {String} lastInboundMessage
	 */
	async getReplySuggestions(locationId, lastInboundMessage) {
		try {
			let authToken = UserService.getAuthToken();
			let response = await Kichiri.message.getReplySuggestions({}, { locationId: locationId, message: lastInboundMessage }, authToken);
			let suggestions = response.data.suggestions;
			let replySuggestions = [];
			for (let i = 0; i < suggestions.length; i++) {
				var obj = {
					id: i,
					content: suggestions[i]
				};
				replySuggestions.push(obj);
			}
			return replySuggestions;
		} catch (err) {
			throw new Error(`MessagesService - error while fetching reply suggestions: ${err}`);
		}
	},

	/**
	 * Determines if Video chat is enabled and the user can create video chat requests
	 *
	 */
	isVideoChatEnabled() {
		try {
			let user = UserService.get();
			let location = UserService.getActiveLocation();

			let { view_video_chats, create_video_chats } = user.GroupPermission;
			let { messenger_video_chat } = location.LocationFeature;

			return view_video_chats && create_video_chats && messenger_video_chat;
		} catch (error) {
			console.log(error);
		}

		return false;
	},

	/**
	 * Determines if Secure chat is enabled and the user can create secure chat requests
	 *
	 * @returns {Boolean}
	 */
	isSecureChatEnabled() {
		try {
			let user = UserService.get();
			let location = UserService.getActiveLocation();

			let { messenger_secure_chat } = location.LocationFeature;
			let { view_secure_chats, create_secure_chats } = user.GroupPermission;

			return view_secure_chats && create_secure_chats && messenger_secure_chat;
		} catch (error) {
			console.log(error);
		}

		return false;
	},

	/**
	 * Attempt to retry sending a message
	 *
	 * @param {Number} messageId
	 * @param {Boolean} viaEmail
	 */
	async retryMessageSend({ messageId, viaEmail }) {
		let token = UserService.getAuthToken();
		let location = UserService.getActiveLocation();
		try {
			let response = await Kichiri.message.retryMessageSend(
				{
					locationId: location.id,
					messageId,
					viaEmail
				},
				{},
				token
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Fetches a single scheduled message
	 *
	 * @param {Number} scheduledMessageId
	 */
	async getScheduledMessage(scheduledMessageId) {
		try {
			let response = await Kichiri.scheduled_message.get({ scheduledMessageId }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Fetch all the scheduled messages for a location
	 *
	 * @param {Number} locationId
	 * @param {Object} params
	 */
	async fetchScheduledMessages({
		locationId,
		searchTerm = "",
		sortField = null,
		sortOrder = null,
		state = SM_STATES.pending,
		limit = 25,
		type = SM_TYPES.general,
		sendAfterStartDate,
		sendAfterEndDate
	}) {
		try {
			if (!locationId) {
				locationId = UserService.getActiveLocation().id;
			}

			let response = await Kichiri.scheduled_message.fetch(
				{},
				{
					locationId,
					searchTerm,
					sortField,
					sortOrder,
					state,
					limit,
					type,
					sendAfterStartDate,
					sendAfterEndDate
				},
				UserService.getAuthToken()
			);
			return response.data;
		} catch (error) {
			console.log(error);
		}

		return [];
	},

	/**
	 * Create a scheduled message
	 *
	 * @param {Number} locationId
	 * @param {Array} tagIds
	 * @param {Array} contactIds
	 * @param {Array} mediaIds
	 * @param {Number} senderId
	 * @param {Date} sendAfter
	 * @param {String} content
	 * @param {String} type
	 * @param {Number} templateId
	 * @param {Boolean} isAnonymous
	 */
	async createScheduledMessage({
		locationId,
		tagIds = [],
		contactIds = [],
		mediaIds = [],
		senderId = null,
		sendAfter = null,
		content = "",
		type = SM_TYPES.general,
		templateId,
		isAnonymous = false
	}) {
		try {
			if (!locationId) {
				locationId = UserService.getActiveLocation().id;
			}

			let response = await Kichiri.scheduled_message.create(
				{
					locationId,
					tagIds,
					contactIds,
					mediaIds,
					senderId,
					sendAfter,
					content,
					type,
					templateId,
					isAnonymous
				},
				{},
				UserService.getAuthToken()
			);
			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Update a scheduled message
	 *
	 * @param {Number} scheduledMessageId
	 * @param {Array} tagIds
	 * @param {Array} contactIds
	 * @param {Array} mediaIds
	 * @param {Number} senderId
	 * @param {Date} sendAfter
	 * @param {String} content
	 * @param {String} status
	 * @param {Number} templateId
	 * @param {Boolean} isAnonymous
	 */
	async updateScheduledMessage({
		scheduledMessageId,
		tagIds,
		contactIds,
		mediaIds,
		senderId,
		sendAfter,
		content,
		state,
		status,
		templateId,
		isAnonymous = false
	}) {
		try {
			let response = await Kichiri.scheduled_message.update(
				{
					scheduledMessageId,
					tagIds,
					contactIds,
					mediaIds,
					senderId,
					sendAfter,
					content,
					state,
					status,
					templateId,
					isAnonymous
				},
				{},
				UserService.getAuthToken()
			);
			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Sort of an ugly function, but indicates whether or not the business is within the 7 day threshold to be able to send messages to facebook contact
	 *
	 * @param {Array} messages
	 */
	canSendFacebookMessages(messages) {
		let now = moment();

		for (let i = messages.length - 1; i >= 0; i--) {
			let m = messages[i];
			let messageDate = moment(m.created_at);

			// Check to see that the last inbound facebook message was received within the last 7 days
			if (m.message_type === "general" && m.event_type === "message" && m.direction === "in" && m.medium === "facebook") {
				return now.diff(messageDate, "days") <= 7;
			}
		}

		return true;
	},
	/**
	 * Takes a note as it is currently stored in the backend and extracts the note content
	 *
	 * @param {string} note
	 * @return {string}
	 */
	formatNote(note) {
		let firstQuoteMark = note.indexOf('"');
		let lastQuoteMark = note.lastIndexOf('"');

		// Convert 'Note Added \n "The note" \n Rocky Cocky' -> The note
		note = note.substring(firstQuoteMark + 1, lastQuoteMark);
		return note;
	},

	/**
	 * Fetch the metadata which includes error codes associated with providers and dh
	 *
	 * @return {Object}
	 */
	async storeProviderMetaData() {
		try {
			let response = await Kichiri.admin.getMessengerProviderMetadata({}, {}, UserService.getAuthToken());
			providerMetaData = response.data;
			return;
		} catch (error) {
			console.log(error);
		}
		providerMetaData = {};
	},

	/**
	 * Get the metadata and error codes associated with the locations sms provider
	 * Note: You must first call storeProviderMetaData() to fill this object
	 *
	 * @return {Object}
	 */
	getProviderMetaData() {
		// Return the variable declare at the top of the file holding the meta data
		return providerMetaData;
	},

	/**
	 * Get the api type of the number of a location
	 * @param {Number} locationId
	 * @return {String}
	 */
	async getProvider(locationId) {
		try {
			if (!locationId) {
				locationId = UserService.getActiveLocation().id;
			}

			let data = await LocationService.fetchLocation(locationId);

			let number = data.numbers.find(n => n.safe === 1);

			return number.api;
		} catch (error) {
			console.log(error);
		}

		return "";
	},

	/**
	 * Marks an entire conversation as read
	 *
	 * @param {String} contactId
	 * @return {Boolean}
	 */
	async markEntireConversationRead(contactId) {
		let user = UserService.get();
		let location = UserService.getActiveLocation().id;

		try {
			await Kichiri.message.markConversationAsRead({ location, contact: contactId }, {}, user.auth_token);
		} catch (error) {
			console.log(error);
			return false;
		}

		return true;
	},

	/**
	 * Marks all messages after a certain message as unread
	 *
	 * @param {Integer} messageId
	 * @param {Integer} contactId
	 * @return {Integer}
	 */
	async markMessagesAsUnread(messageId, contactId) {
		let user = UserService.get();
		let locationId = UserService.getActiveLocation().id;

		try {
			let response = await Kichiri.message.markConversationAsUnread({ messageId, contactId, locationId }, {}, user.auth_token);
			return response.data.messagesUpdatedCount;
		} catch (error) {
			console.log(error);
			return 0;
		}
	},

	/**
	 * Get a single message
	 *
	 * @param {int} messageId
	 * @return {Object}
	 */
	async getMessage(messageId) {
		let user = UserService.get();

		try {
			let response = await Kichiri.message.getMessage({ messageId }, {}, user.auth_token);
			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Updates a customer message
	 *
	 * @param {Number} messageId
	 * @param {String} status
	 * @returns
	 */
	async updateMessage({ messageId, status }) {
		let user = UserService.get();

		try {
			let response = await Kichiri.message.updateCustomerMessage({ messageId, status }, {}, user.auth_token);
			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Get a media object
	 *
	 * @param {String/Integer} mediaId The media id (String UUID -> means that it is stored locally, otherwise it is an Integer -> meaning that it's already a media object in our backend)
	 * @param {String} publicId The public id of a media
	 * @return {Object}
	 */
	async getMedia({ mediaId, publicId }) {
		let user = UserService.get();

		// If we found the media locally, just return that
		if (this.getLocalMedia(mediaId)) {
			return this.getLocalMedia(mediaId);
		}

		try {
			// Check if mediaId is an int
			if (typeof mediaId !== "undefined" && !UtilityService.isInt(mediaId)) {
				console.log(`Media ${mediaId} was not found locally.`);
				return null;
			}

			// If mediaId is an int, then it is already a media in our db and we will just fetch it.

			if (mediaId) {
				mediaId = Base58.int_to_base58(mediaId);
			}

			let response = await Kichiri.media.fetchMedia({ mediaGuid: mediaId }, { publicId }, user.auth_token);
			return response.data.media;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 *
	 * @param {Array} mediaIds The local media ids that we want to upload to the backend
	 * @param {String} mode The mode of the message/media (customer chat or team chat)
	 * @param {String} medium The medium for the media
	 * @param {Boolean} optimize Set to true if the media should be compressed and reduced in size
	 * @param {Number} limit A limit of how many media we want to upload
	 * @param {Number} companyId
	 * @param {Number} locationId
	 *
	 * @returns A list of Media objects
	 */
	async uploadLocalMedia({ mediaIds, mode, medium, optimize = true, limit = null, companyId, locationId }) {
		try {
			if (!mediaIds) {
				return [];
			}

			if (!limit) {
				limit = mediaIds.length;
			}

			let newMediaIds = [];

			// Upload all the local media
			for (let i = 0; i < mediaIds.length; i++) {
				const mediaId = mediaIds[i];

				let localMedia = await this.getMedia({ mediaId });

				// If we are attaching something
				if (localMedia.local) {
					let uploadedMedia = await this.uploadMedia({ file: localMedia.file, mode, medium: medium, optimize, companyId, locationId });

					newMediaIds.push(uploadedMedia);
				} else {
					// Even if it's not local, lettuce still add it to the list
					newMediaIds.push(localMedia);
				}

				// If we have reached the limit of media that we want to upload
				if (i >= limit - 1) {
					break;
				}
			}
			return newMediaIds;
		} catch (error) {
			console.log(error);
			return null;
		}
	},

	/**
	 * Clear all locally stored media
	 */
	clearLocalMedia() {
		localMedia = {};
	},

	/**
	 * Get a locally stored media object
	 * @param {String} mediaId
	 * @returns
	 */
	getLocalMedia(mediaId) {
		if (!localMedia || !localMedia[mediaId]) {
			return null;
		}

		return localMedia[mediaId];
	},

	/**
	 * Store media locally
	 * @param {File} file uploaded file object
	 * @returns
	 */
	storeLocalMedia({ file }) {
		if (!localMedia) {
			localMedia = {};
		}

		if (!file) {
			return;
		}

		let extension = UtilityService.getExtensionFromUrl(file.name);
		let downloadUrl = URL.createObjectURL(file);

		let mediaId = UtilityService.uuid();
		let type = MEDIA_TYPES.file;

		if (type === UtilityService.isAudio(extension)) {
			type = MEDIA_TYPES.audio;
		} else if (MEDIA_TYPES_CONVERSION[extension] === MEDIA_TYPES.image) {
			type = MEDIA_TYPES.image;
		}

		// Create a new media object to be stored locally
		localMedia[mediaId] = {
			id: mediaId,
			file,
			file_name: file.name,
			download_url: downloadUrl,
			type: type,
			mimeType: file.type,
			extension,
			local: true,
			height: null,
			width: null
		};

		// For image media types, to a best effort to get the width and height of the image
		if (MEDIA_TYPES_CONVERSION[extension] === MEDIA_TYPES.image) {
			try {
				let img = new Image();
				img.src = downloadUrl;

				img.onload = () => {
					let media = this.getLocalMedia(mediaId);
					media.width = img.width;
					media.height = img.height;
					localMedia[mediaId] = media;
				};
			} catch (error) {
				console.log(`Image media error - ${error}`);
			}
		}

		return localMedia[mediaId];
	},

	/**
	 * Remove a locally stored media object
	 * @param {String} mediaId
	 */
	removeLocalMedia(mediaId) {
		delete localMedia[mediaId];
	},

	/**
	 * Convert a heic file to a jpg file
	 * @param {Object} file
	 * @returns A File upbject
	 */
	async convertHeicFileToJpg(file) {
		let originalFile = file;
		try {
			if (file.type !== "image/heic") {
				return originalFile;
			}

			let buffer = new Uint8Array(await file.arrayBuffer());
			buffer = await heicConvert({
				buffer: buffer,
				format: "JPEG",
				quality: 0.8
			});

			let fileName = file.name.substr(0, file.name.indexOf("."));
			fileName = `${fileName}.jpeg`;
			let jpgFile = new File([buffer], fileName, { type: "image/jpeg" });
			return jpgFile;
		} catch (error) {
			console.log(`Error converting heic to jps - ${error}`);
		}
		return originalFile;
	},

	/**
	 * Searches all the contacts for a specific location using the search term
	 *
	 * @param {String} searchTerm
	 * @param {Number} locationId
	 * @param {Boolean} allowRestrictedViewAll
	 * @param {Boolean} hasPaymentMethod
	 * @param {Boolean} receiveTransactionalSms
	 * @param {Boolean} receiveTransactionalEmail
	 * @return {Promise}
	 */
	async searchConversations({ searchTerm, locationId, allowRestrictedViewAll = false, hasPaymentMethod, receiveTransactionalSms, receiveTransactionalEmail }) {
		let user = UserService.get();
		if (searchTerm.length === 0) {
			return [];
		}

		let requestBody = { searchTerm, locationId, allowRestrictedViewAll, hasPaymentMethod, receiveTransactionalSms, receiveTransactionalEmail };

		try {
			let response = await Kichiri.message.searchConversations(requestBody, {}, user.auth_token);

			return response.data.map(searchResult => {
				let {
					id,
					name,
					phone,
					email,
					assigned_user_id,
					medium,
					preferred_medium,
					content,
					created_at,
					status,
					inbox,
					PaymentMethods,
					MessengerIntegrations
				} = searchResult;

				return this.getPhantomConversation({
					contactId: id,
					name,
					phone,
					email,
					assignedUserId: assigned_user_id,
					messageMedium: medium,
					preferredMedium: preferred_medium,
					content,
					createdAt: created_at,
					status,
					inbox,
					paymentMethods: PaymentMethods,
					messengerIntegrations: MessengerIntegrations
				});
			});
		} catch (error) {
			console.log(`An error occurred trying to fetch search results for conversations`);
		}

		return [];
	},

	/**
	 * Currently throughout MessengerBeta we retrieve a "conversation" object from the backend that have properties from the Contact, Message, and Inbox models.
	 * We did this so that we could render a Conversation component in the List component or a search result (Result.js) in the ResultList component.
	 * We call this the PhantomConversation object.
	 *
	 * This function can be used to pass in the search results from the following endpoints:
	 *
	 * /api/message/search/conversations
	 * /api/message/search/messages
	 *
	 * And convert them into objects that can be used to render within the Result.js component.
	 *
	 * The plan in the future is to do this in the backend, and have a single representation of a Customer Conversation (PhantomConversation), but it requires some more exploration and thought.
	 *
	 * @param {Number} contactId
	 * @param {String} name
	 * @param {String} phone
	 * @param {String} email
	 * @param {Number} assignedUserId
	 * @param {String} messageMedium
	 * @param {String} preferredMedium
	 * @param {String} content
	 * @param {String} createdAt
	 * @param {String} status
	 * @param {Object} inbox
	 * @param {Array} paymentMethods
	 * @returns
	 */
	getPhantomConversation({
		contactId,
		name,
		phone,
		email,
		assignedUserId,
		messageMedium,
		preferredMedium,
		content,
		createdAt,
		status,
		inbox,
		paymentMethods,
		messengerIntegrations
	}) {
		let convo = null;

		try {
			let resolvedMedium = messageMedium || preferredMedium;

			// Edge case, if the message/convo has a medium, but that medium's data is not set properly. For now we only check sms and email
			// If the resolved medium is sms but there is not valid phone number, and if there is a valid email
			if (resolvedMedium === MEDIUM.sms.key && (!phone || phone.length < 1) && email && email.length > 0) {
				resolvedMedium = MEDIUM.email.key;
			} else if (resolvedMedium === MEDIUM.email.key && (!email || email.length < 1) && phone && phone.length > 0) {
				// If the resolved medium is email but there is not valid email, and if there is a valid phone
				resolvedMedium = MEDIUM.sms.key;
			}

			let mediumData = this.getMediumData(resolvedMedium, phone, email);

			// I would love nothing but to keeps these as camel case, but this "phantom" customer conversation object is used everywhere, so for now we have this nasty "ab"-normalization
			convo = {
				id: contactId,
				contact_id: contactId,
				name,
				phone,
				email,
				preferred_medium: resolvedMedium,
				medium: resolvedMedium,
				medium_data: mediumData,
				status,
				assigned_user_id: assignedUserId,
				content,
				created_at: createdAt,
				paymentMethods,
				messengerIntegrations
			};

			if (inbox) {
				convo.inbox = inbox;
			}
		} catch (error) {
			console.log("An error occurred trying to render a phantom conversation from a contact search result.");
			console.log(error);
		}

		return convo;
	},

	/**
	 * Generate content given some message replacements
	 *
	 * @param  {String} content
	 * @param  {Object} replacements
	 * @return {String}
	 */
	generateContent: function(content, replacements) {
		Object.keys(replacements).forEach(function(key) {
			content = content.replace(`%${key}%`, replacements[key]);
		});

		return content;
	},

	/**
	 * Fetches all the media messages for the specified customer conversation
	 *
	 * @param {Number} contactId
	 * @param {Number} limit
	 * @param {Number} offset
	 * @returns {Array}
	 */
	async fetchMediaForContact({ contactId, limit = 50, offset = 0 }) {
		let user = UserService.get();
		let locationId = UserService.getActiveLocation().id;

		try {
			let queryParams = {
				locationId,
				limit,
				offset
			};

			// Fetch the media messages within the limit and offset
			let { data: media } = await Kichiri.contacts.fetchMedia({ contactId }, queryParams, user.auth_token);

			// Add authentication to the media urls
			for (let i = 0; i < media.length; i++) {
				let mediaItem = media[i];
				mediaItem.download_url = UtilityService.appendQueryAuthToken(mediaItem.download_url, locationId, user.auth_token);
			}

			return media;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Get a single message with replaced message variables
	 *
	 * @param {Integer} locationId
	 * @param {Integer} contactId
	 * @param {String} message
	 * @param {Object} replacements Manual override replacements
	 *
	 * @return {String} Returns passed in message with message variables replaced
	 */
	async replaceMessage({ locationId, contactId, message, replacements }) {
		let user = UserService.get();

		try {
			let response = await Kichiri.message.getReplacedMessage({}, { locationId, contactId, message, replacements }, user.auth_token);
			return response.data;
		} catch (error) {
			console.log(error);
		}

		return message;
	},

	/**
	 * Send a draft message
	 * @param {String} phone
	 * @param {String} email
	 * @param {String} message
	 * @param {Array} media
	 * @returns {Boolean} True on success else false
	 */
	async sendDraft({ phone, email, message, media }) {
		try {
			await Kichiri.message.sendDraft({ phone, email, message, media }, {}, UserService.getAuthToken());
			return true;
		} catch (error) {
			console.log(error);
		}
		return false;
	},

	/**
	 * Fetch voicemail for a location
	 * @param {Integer} locationId
	 * @param {String} status
	 * @param {String} searchTerm
	 * @param {Integer} sortOrder
	 * @param {Integer} sortField
	 * @param {Integer} limit
	 * @param {Integer} offset
	 *
	 * @returns {Array}
	 */
	async fetchVoicemail({ locationId, status, searchTerm, sortOrder, sortField, limit, offset }) {
		try {
			let response = await Kichiri.voice.fetchVoicemail(
				{},
				{ locationId, status, searchTerm, sortOrder, sortField, limit, offset },
				UserService.getAuthToken()
			);
			return response.data;
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Update a voicemail
	 * @param {Integer} voicemailId
	 * @param {String} status
	 *
	 * @returns {Object}
	 */
	async updateVoicemail({ voicemailId, status }) {
		try {
			let response = await Kichiri.voice.updateVoicemail({ voicemailId, status }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Fetch messages that have Customer Scheduled Messages
	 * @returns {Array}
	 */
	async fetchCustomerQueuedMessages({ locationId }) {
		try {
			let { data } = await Kichiri.message.fetchCustomerQueuedMessages({}, { locationId }, UserService.getAuthToken());

			return data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Count the number of Customer Scheduled Messages By Contact
	 *
	 * @param {Integer} locationId
	 *
	 * @returns {Integer}
	 */
	async countCustomerScheduledMessages({ locationId }) {
		try {
			let { data } = await Kichiri.message.countCustomerScheduledMessages(
				{},
				{
					locationId
				},
				UserService.getAuthToken()
			);

			return data.count;
		} catch (error) {
			console.log(error);
		}

		return 0;
	},

	/**
	 * Helper function to set the global list reference
	 *
	 * @param {Object} ref
	 */
	setMessengerListReference(ref) {
		messengerListReference = ref;
	},

	/**
	 * Helper function to trigger the "Start new Converation" modal to show from anywhere
	 */
	async triggerShowSendMessage() {
		let retryCount = 10;

		while (!messengerListReference && retryCount > 0) {
			await UtilityService.timeout(1000);
			retryCount--;
		}

		if (!messengerListReference) {
			return;
		}

		messengerListReference.triggerShowNewConversationModal();
	},

	/**
	 * Toggle a message pin. If one already exists unpin (delete) the message pin, else we create the message pin
	 * @param {Integer} locationId
	 * @param {Integer} userId
	 * @param {Integer} conversationId
	 * @param {Integer} messageId
	 */
	async pinMessage({ locationId, userId, conversationId, messageId }) {
		try {
			let response = await Kichiri.message.pinMessage({ locationId, userId, conversationId, messageId }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Fetch message pins.
	 * @param {Integer} locationId
	 * @param {Integer} userId
	 * @param {Integer} conversationId
	 * @param {Integer} messageId
	 * @param {Boolean} includeMessages
	 */
	async fetchMessagePins({ locationId, userId, conversationId, messageId, includeMessages }) {
		try {
			let response = await Kichiri.message.fetchMessagePins({}, { locationId, userId, conversationId, messageId, includeMessages }, UserService.getAuthToken());

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	}
};

export default MessagesService;
