import React, { useEffect } from "react";
import { $generateHtmlFromNodes } from "@lexical/html";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { $getRoot, $getSelection, $isRangeSelection, FOCUS_COMMAND, CLEAR_EDITOR_COMMAND, FORMAT_TEXT_COMMAND } from "lexical";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { ListNode, ListItemNode } from "@lexical/list";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin";

import ToolbarPlugin from "./ToolbarPlugin";
import PressEnterPlugin from "./PressEnterPlugin";
import SelectionPlugin from "./SelectionPlugin";
import FilePastePlugin from "./FilePastePlugin";
// import TreeViewPlugin from "./TreeViewPlugin"; In case we need to debug, keep this

// Import the Lexical CSS
import "./DHLexical.css";

// Example theme object
const theme = {
	ltr: "dh-lexical-editor-ltr",
	rtl: "dh-lexical-editor-rtl",
	paragraph: "dh-lexical-editor-paragraph",
	quote: "dh-lexical-editor-quote",
	heading: {
		h1: "dh-lexical-editor-heading-h1",
		h2: "dh-lexical-editor-heading-h2",
		h3: "dh-lexical-editor-heading-h3",
		h4: "dh-lexical-editor-heading-h4",
		h5: "dh-lexical-editor-heading-h5",
		h6: "dh-lexical-editor-heading-h6"
	},
	list: {
		nested: {
			listitem: "dh-lexical-editor-nested-listitem"
		},
		ol: "dh-lexical-editor-list-ol",
		ul: "dh-lexical-editor-list-ul",
		listitem: "dh-lexical-editor-listItem",
		listitemChecked: "dh-lexical-editor-listItemChecked",
		listitemUnchecked: "dh-lexical-editor-listItemUnchecked"
	},
	hashtag: "dh-lexical-editor-hashtag",
	image: "dh-lexical-editor-image",
	link: "dh-lexical-editor-link",
	text: {
		bold: "dh-lexical-editor-textBold",
		code: "dh-lexical-editor-textCode",
		italic: "dh-lexical-editor-textItalic",
		strikethrough: "dh-lexical-editor-textStrikethrough",
		subscript: "dh-lexical-editor-textSubscript",
		superscript: "dh-lexical-editor-textSuperscript",
		underline: "dh-lexical-editor-textUnderline",
		underlineStrikethrough: "dh-lexical-editor-textUnderlineStrikethrough"
	},
	code: "dh-lexical-editor-code",
	codeHighlight: {
		atrule: "dh-lexical-editor-tokenAttr",
		attr: "dh-lexical-editor-tokenAttr",
		boolean: "dh-lexical-editor-tokenProperty",
		builtin: "dh-lexical-editor-tokenSelector",
		cdata: "dh-lexical-editor-tokenComment",
		char: "dh-lexical-editor-tokenSelector",
		class: "dh-lexical-editor-tokenFunction",
		"class-name": "dh-lexical-editor-tokenFunction",
		comment: "dh-lexical-editor-tokenComment",
		constant: "dh-lexical-editor-tokenProperty",
		deleted: "dh-lexical-editor-tokenProperty",
		doctype: "dh-lexical-editor-tokenComment",
		entity: "dh-lexical-editor-tokenOperator",
		function: "dh-lexical-editor-tokenFunction",
		important: "dh-lexical-editor-tokenVariable",
		inserted: "dh-lexical-editor-tokenSelector",
		keyword: "dh-lexical-editor-tokenAttr",
		namespace: "dh-lexical-editor-tokenVariable",
		number: "dh-lexical-editor-tokenProperty",
		operator: "dh-lexical-editor-tokenOperator",
		prolog: "dh-lexical-editor-tokenComment",
		property: "dh-lexical-editor-tokenProperty",
		punctuation: "dh-lexical-editor-tokenPunctuation",
		regex: "dh-lexical-editor-tokenVariable",
		selector: "dh-lexical-editor-tokenSelector",
		string: "dh-lexical-editor-tokenSelector",
		symbol: "dh-lexical-editor-tokenProperty",
		tag: "dh-lexical-editor-tokenProperty",
		url: "dh-lexical-editor-tokenOperator",
		variable: "dh-lexical-editor-tokenVariable"
	}
};

const initialConfig = {
	theme: theme,
	nodes: [ListNode, ListItemNode],
	onError(error) {
		console.error(error);
	}
};

// This functional component will use the hook and pass the editor back to the class component
function EditorContextProvider({ setEditorInstance }) {
	const [editor] = useLexicalComposerContext();

	useEffect(() => {
		if (editor) {
			// Pass the editor instance back to the class component
			setEditorInstance(editor);
		}
	}, [editor, setEditorInstance]);

	// This component does not render anything
	return null;
}

class DHLexical extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.editor = null;
		this.loadJsonState = this.loadJsonState.bind(this);
		this.createStateFromText = this.createStateFromText.bind(this);
		this.clearInput = this.clearInput.bind(this);
	}

	componentDidUpdate(prevProps) {
		// If shouldDisableEnterPlugin prop changes, update the editor property
		if (prevProps.shouldDisableEnterPlugin !== this.props.shouldDisableEnterPlugin) {
			this.editor.shouldDisableEnterPlugin = this.props.shouldDisableEnterPlugin;
		}
	}

	setEditorInstance = editor => {
		this.editor = editor;
		this.editor.shouldDisableEnterPlugin = this.props.shouldDisableEnterPlugin;
	};

	handleEditorStateChange = editorState => {
		editorState.read(() => {
			const root = $getRoot(); // Get the root node of the document
			const textContent = root.getTextContent(); // Get the text content of the root node
			const jsonContent = editorState.toJSON(); // Get the JSON representation of the editor state
			const htmlContent = $generateHtmlFromNodes(this.editor); // Make sure this is awaited

			// If you want to pass both the plain text and its JSON representation up to a parent component
			if (this.props.onChange) {
				this.props.onChange(textContent, jsonContent, htmlContent);
			}
		});
	};

	handleEnterKey = event => {
		if (this.props.onEnter) {
			// Call the parent component's onEnter function
			this.props.onEnter();
		}
	};

	loadJsonState = jsonState => {
		if (!this.editor || !jsonState) {
			return;
		}

		this.editor.update(() => {
			// Create a new editor state from the JSON state
			const newState = this.editor.parseEditorState(jsonState);

			// Replace the current editor state with the new one
			this.editor.setEditorState(newState);

			// Since the editor's state has changed, manually trigger the handleEditorStateChange
			// This ensures that any parent components listening for changes are notified
			this.handleEditorStateChange(newState);
		});
	};

	createStateFromText = text => {
		if (!this.editor) {
			return;
		}

		this.editor.update(() => {
			// Clear the editor content
			this.editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);

			// Create a new editor state
			const newState = this.editor.parseEditorState({
				root: {
					children: [
						{
							type: "paragraph",
							children: [
								{
									type: "text",
									text: text
								}
							]
						}
					],
					direction: "ltr",
					format: "",
					indent: 0,
					type: "root",
					version: 1
				}
			});

			// Set the new state
			this.editor.setEditorState(newState);

			// Notify parent component
			this.handleEditorStateChange(newState);
		});
	};

	clearInput = () => {
		if (!this.editor) {
			return;
		}

		this.editor.update(() => {
			// Clear the editor content
			this.editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);

			// Reset formatting
			const selection = $getSelection();
			if ($isRangeSelection(selection)) {
				// Check and remove active formats
				if (selection.hasFormat("bold")) {
					this.editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
				}
				if (selection.hasFormat("italic")) {
					this.editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
				}
				if (selection.hasFormat("underline")) {
					this.editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
				}
				if (selection.hasFormat("strikethrough")) {
					this.editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough");
				}
			}
		});
	};

	insertString = insertionString => {
		this.editor.update(() => {
			const selection = $getSelection();

			if (selection) {
				selection.insertText(insertionString);
			}
		});
	};

	insertMention = (mention, messageInputMentionText) => {
		this.editor.update(() => {
			const selection = $getSelection();

			if ($isRangeSelection(selection)) {
				// Get the current node and offset
				const anchorNode = selection.anchor.getNode();
				const currentOffset = selection.anchor.offset;

				// Calculate the start position for deletion
				const startOffset = currentOffset - messageInputMentionText.length;

				// Create a new range selection from start to current position
				selection.setTextNodeRange(anchorNode, startOffset, anchorNode, currentOffset);

				// Delete the selected text in one operation
				selection.deleteCharacter(false);

				// Get the next character to check if we need to add a space
				const parentText = anchorNode.getTextContent();
				const nextChar = parentText[startOffset] || "";

				// Insert mention and conditionally add a space
				selection.insertText(mention);
				if (nextChar !== " ") {
					selection.insertText(" ");
				}
			}
		});
	};

	focus = () => {
		if (!this.editor) {
			return;
		}

		this.editor.update(() => {
			this.editor.dispatchCommand(FOCUS_COMMAND);

			// Set cursor at end of content as fallback
			const root = $getRoot();
			const lastChild = root.getLastChild();
			if (lastChild) {
				lastChild.select();
			}
		});
	};

	render() {
		const { disabled, placeholder, onSelect, onFilePaste } = this.props;

		return (
			<LexicalComposer initialConfig={initialConfig}>
				<EditorContextProvider setEditorInstance={this.setEditorInstance} />
				<div className="dh-lexical__editor-container">
					<ToolbarPlugin />
					<div className={`dh-lexical__compose ${disabled ? "dh-lexical--disabled" : ""}`}>
						<RichTextPlugin
							contentEditable={
								<ContentEditable
									style={{ outline: "none" }}
									id="mb-thread-compose-text"
									className="dh-lexical__content-editable"
									onKeyDown={e => {
										if (this.props.onKeyboardEvent) {
											this.props.onKeyboardEvent(e);
										}
									}}
								/>
							}
							placeholder={<div className="dh-lexical__placeholder">{placeholder}</div>}
							ErrorBoundary={LexicalErrorBoundary}
							className="dh-lexical__input"
						/>
						<HistoryPlugin />
						<AutoFocusPlugin />
						<ListPlugin />
						<ClearEditorPlugin />
						<SelectionPlugin onSelect={onSelect} />
						<OnChangePlugin onChange={this.handleEditorStateChange} />
						<PressEnterPlugin onEnter={this.handleEnterKey} />
						<FilePastePlugin onFilePaste={onFilePaste} />
						{/* <TreeViewPlugin /> */}
					</div>
				</div>
			</LexicalComposer>
		);
	}
}

export default DHLexical;
