import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import * as Icon from "react-feather";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate, parseDate } from "react-day-picker/moment";
import queryString from "query-string";
import { withTranslation } from "react-i18next";

import { SORT_ORDER } from "../../constants/CommonConstants";
import { SM_COLUMNS, SM_STATES, SM_FILTERS_STATE, SM_TABS, SM_TYPES, SM_TYPES_FULL } from "../../constants/ScheduledMessages";
import { GA_CATEGORIES, GA_ACTIONS } from "../../constants/GAConstants";

import Page from "../../components/common/Page";
import Header from "../../components/common/Header";
import Filters from "../../components/common/Filters";
import List from "../../components/common/List";
import SearchInput from "../../components/common/SearchInput";
import Action from "../../components/common/Action";
import Alert from "../../components/common/Alert";
import withLocation from "../../components/common/WithLocation";
import Tabs from "../../components/common/Tabs";
import Tab from "../../components/common/Tab";

import ScheduledMessageModal from "./ScheduledMessageModal";

import GAService from "../../services/GAService";
import UtilityService from "../../services/UtilityService";
import MessagesService from "../../services/MessagesService";
import UserService from "../../services/UserService";
import ToastService from "../../services/ToastService";
import SupportChatService from "../../services/SupportChatService";
import LocationService from "../../services/LocationService";
import ReengagementService from "../../services/ReengagementService";
import NotificationService from "../../services/NotificationService";

import "../../styles/css/scenes/scheduled-messages.css";

class ScheduledMessages extends Component {
	constructor(props) {
		super(props);

		let { searchTerm, sortField, sortOrder, dateRangeStart, dateRangeEnd, state } = queryString.parse(this.props.location.search);

		if (dateRangeStart) {
			dateRangeStart = moment(dateRangeStart).toDate();
		} else {
			dateRangeStart = moment()
				.subtract(30, "days")
				.toDate();
		}

		if (dateRangeEnd) {
			dateRangeEnd = moment(dateRangeEnd).toDate();
		} else {
			dateRangeEnd = moment()
				.add(30, "days")
				.toDate();
		}

		this.state = {
			showBlockedAlert: false,

			loading: true,
			loadedAll: false,
			data: [],

			limit: 25,
			pageSize: 50,
			sortField: sortField ? sortField : SM_COLUMNS.created_at.id,
			sortOrder: sortOrder ? sortOrder : SORT_ORDER.desc,
			searchTerm: searchTerm || "",
			state: state ? state : SM_FILTERS_STATE.pending.id,
			tab: null,
			type: null,
			dateRangeStart,
			dateRangeEnd,

			showModal: false,
			selectedScheduledMessage: null,

			showDeleteAlert: false,
			showCancelAlert: false
		};
	}

	async componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		await this.chooseDefaultTabAndType();
		await this.fetchScheduledMessages();

		NotificationService.subscribe("onUrlChange", ({ location, action }) => {
			this.setTabAndRoute();
		});
	}

	onLocationChanged = async location => {
		await this.chooseDefaultTabAndType();
		await this.fetchScheduledMessages();
	};

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	fetchScheduledMessages = async () => {
		await this.update({ loading: true });

		try {
			let locationId = UserService.getActiveLocation().id;
			let { searchTerm, sortField, sortOrder, limit, state, type, dateRangeStart, dateRangeEnd } = this.state;

			let sms = await MessagesService.fetchScheduledMessages({
				locationId,
				searchTerm,
				sortField,
				sortOrder,
				limit,
				state,
				type,
				sendAfterStartDate: dateRangeStart,
				sendAfterEndDate: dateRangeEnd
			});

			sms = sms.slice();

			await this.update({
				data: sms,
				loadedAll: sms.length < limit
			});
		} catch (error) {
			console.log(error);
		}

		await this.update({ loading: false });
	};

	onTabSelect = async tab => {
		if (tab.id === SM_TABS.general.id) {
			await this.update({ type: SM_TYPES.general });
		} else if (tab.id === SM_TABS.nps.id) {
			await this.update({ type: SM_TYPES.nps });
		} else if (tab.id === SM_TABS.reviewInvite.id) {
			await this.update({ type: SM_TYPES.reviewInvite });
		} else if (tab.id === SM_TABS.reengagement.id) {
			await this.update({ type: SM_TYPES.reengagement });
		}
		await this.update({ tab: tab.id, searchTerm: "" });

		this.props.history.push(SM_TABS[tab.id].route);
		this.setUrlParams();

		GAService.GAEvent({
			category: GA_CATEGORIES.scheduledMessages.name,
			action: GA_ACTIONS.generic.tabSelect,
			label: `Selected tab: ${tab.value}`
		});

		await this.fetchScheduledMessages();
	};

	sortBy = async sortField => {
		let { sortOrder } = this.state;

		sortOrder = sortOrder === SORT_ORDER.asc ? SORT_ORDER.desc : SORT_ORDER.asc;

		await this.update({ sortField, sortOrder });
		this.setUrlParams();
		await this.fetchScheduledMessages();
	};

	getFilters() {
		let filters = {
			state: {
				items: Object.values(SM_FILTERS_STATE),
				title: "",
				onClick: this.onStateClicked,
				isSelected: this.isStateClicked
			}
		};

		return filters;
	}

	getHeaders = () => {
		let columns = { ...SM_COLUMNS };

		let { type } = this.state;

		if (type !== SM_TYPES.general && type !== SM_TYPES.reengagement) {
			delete columns["content"];
		}

		let headers = {
			items: columns,
			sortBy: this.sortBy
		};

		return headers;
	};

	onStateClicked = async item => {
		await this.update({ state: item.id });
		this.setUrlParams();
		await this.fetchScheduledMessages();
	};

	isStateClicked = item => {
		return item === this.state.state;
	};

	isTypeClicked = item => {
		return item === this.state.type;
	};

	onSearch = async value => {
		const searchTerm = value;
		await this.update({ searchTerm });
		this.setUrlParams();
		await this.fetchScheduledMessages();
	};

	onModalHide = async refresh => {
		await this.update({ showModal: false, selectedScheduledMessage: null });

		if (refresh) {
			await this.fetchScheduledMessages();
		}
	};

	openModal = async (scheduledMessage = null) => {
		let { type } = this.state;

		let isScheduledMessagesEnabled = LocationService.isMessengerPermissible && LocationService.isScheduledMessagesEnabled();
		let isGeneralEnabled = LocationService.isScheduledGeneralEnabled();
		let isNpsEnabled = LocationService.isNpsPermissible() && LocationService.isScheduledNpsEnabled();
		let isReviewInvitesEnabled = LocationService.isReviewInvitesEnabled() && LocationService.isScheduledReviewInvitesEnabled();

		let blockCreation = false;

		if (!isScheduledMessagesEnabled) {
			blockCreation = true;
		} else if (type === SM_TYPES.general && !isGeneralEnabled) {
			blockCreation = true;
		} else if (type === SM_TYPES.nps && !isNpsEnabled) {
			blockCreation = true;
		} else if (type === SM_TYPES.reviewInvite && !isReviewInvitesEnabled) {
			blockCreation = true;
		}

		if (blockCreation) {
			await this.update({
				showBlockedAlert: true
			});
			return;
		}

		await this.update({ showModal: true, selectedScheduledMessage: scheduledMessage });
	};

	openCancelAlert = selectedScheduledMessage => {
		this.update({
			showCancelAlert: true,
			selectedScheduledMessage: selectedScheduledMessage
		});
	};

	onConfirmCancel = async confirmed => {
		this.update({
			showCancelAlert: false,
			selectedScheduledMessage: null
		});

		if (confirmed) {
			await this.onCancel();
			await this.fetchScheduledMessages();
		}
	};

	onCancel = async () => {
		let { selectedScheduledMessage } = this.state;
		let { t } = this.props;

		if (!selectedScheduledMessage || !selectedScheduledMessage.id) {
			return;
		}

		let sm = await MessagesService.getScheduledMessage(selectedScheduledMessage.id);

		sm = await MessagesService.updateScheduledMessage({
			scheduledMessageId: selectedScheduledMessage.id,
			state: "cancelled"
		});

		if (!sm) {
			ToastService.info(t("An error occurred trying to cancelled your scheduled message."));
			return;
		}

		ToastService.info(t("Successfully cancelled scheduled message!"));
	};

	openDeleteAlert = (selectedScheduledMessage = null) => {
		this.update({
			showDeleteAlert: true,
			selectedScheduledMessage: selectedScheduledMessage
		});
	};

	onConfirmDelete = async confirmed => {
		this.update({
			showDeleteAlert: false,
			selectedScheduledMessage: null
		});

		if (confirmed) {
			await this.onDelete();
			await this.fetchScheduledMessages();
		}
	};

	onDelete = async () => {
		let { selectedScheduledMessage } = this.state;
		let { t } = this.props;

		if (!selectedScheduledMessage || !selectedScheduledMessage.id) {
			return;
		}

		let sm = await MessagesService.getScheduledMessage(selectedScheduledMessage.id);
		let user = await UserService.get();

		sm = await MessagesService.updateScheduledMessage({
			scheduledMessageId: selectedScheduledMessage.id,
			senderId: user.id,
			sendAfter: sm.send_after,
			content: sm.content,
			status: "deleted"
		});

		if (!sm) {
			ToastService.info(t("An error occurred trying to delete your scheduled message."));
			return;
		}

		ToastService.info(t("Successfully deleted scheduled message!"));
	};

	onBlockedFeatureAlertClose = async confirm => {
		if (confirm) {
			SupportChatService.showNewMessage();
		}

		await this.update({
			showBlockedAlert: false
		});
	};

	onLoadMore = async () => {
		let { limit, pageSize } = this.state;

		await this.update({
			limit: limit + pageSize
		});

		await this.fetchScheduledMessages();
	};

	chooseDefaultTabAndType = async () => {
		let route = await this.setTabAndRoute();
		this.props.history.push(route);
		this.setUrlParams();
	};

	setTabAndRoute = async () => {
		let isScheduledMessagesEnabled = LocationService.isMessengerPermissible && LocationService.isScheduledMessagesEnabled();
		let isGeneralEnabled = LocationService.isScheduledGeneralEnabled();
		let isNpsEnabled = LocationService.isNpsPermissible() && LocationService.isScheduledNpsEnabled();
		let isReviewInvitesEnabled = LocationService.isReviewInvitesEnabled() && LocationService.isScheduledReviewInvitesEnabled();
		let isReengagementsPermissible = ReengagementService.canViewReengagements();

		let tab = SM_TABS.general.id;
		let type = SM_TYPES.general;
		let route = SM_TABS.general.route;

		let tabs = Object.values(SM_TABS);
		let routes = tabs.map(smt => smt.route);
		let routeIndex = routes.indexOf(this.props.location.pathname);

		if (routeIndex < 0) {
			await this.update({ tab, type: SM_TYPES.general });
			this.props.history.push(route);
			this.setUrlParams();
			return;
		}

		route = routes[routeIndex];
		tab = tabs[routeIndex].id;

		if (tab === SM_TABS.general.id && isScheduledMessagesEnabled && isGeneralEnabled) {
			type = SM_TYPES.general;
		} else if (tab === SM_TABS.reviewInvite.id && isReviewInvitesEnabled) {
			type = SM_TYPES.reviewInvite;
		} else if (tab === SM_TABS.nps.id && isNpsEnabled) {
			type = SM_TYPES.nps;
		} else if (tab === SM_TABS.reengagement.id && isReengagementsPermissible) {
			type = SM_TYPES.reengagement;
		} else {
			tab = SM_TABS.general.id;
			type = SM_TYPES.general;
		}

		await this.update({ tab, type });
		return route;
	};

	setUrlParams() {
		if (!this.props.history) {
			return;
		}

		let { searchTerm, sortField, sortOrder, dateRangeStart, dateRangeEnd, state } = this.state;

		let params = {};

		if (searchTerm) {
			params.searchTerm = searchTerm;
		}

		if (sortField) {
			params.sortField = sortField;
		}

		if (sortOrder) {
			params.sortOrder = sortOrder;
		}

		if (dateRangeStart) {
			params.dateRangeStart = moment(dateRangeStart).format("YYYY-MM-DD");
		}

		if (dateRangeEnd) {
			params.dateRangeEnd = moment(dateRangeEnd).format("YYYY-MM-DD");
		}

		if (state) {
			params.state = state;
		}

		params = new URLSearchParams(params);
		this.props.history.replace({
			pathname: this.props.location.pathname,
			search: params.toString()
		});
	}

	handleFromChange = async dateRangeStart => {
		await this.update({ dateRangeStart });
		this.setUrlParams();
		await this.fetchScheduledMessages();
	};

	handleToChange = async dateRangeEnd => {
		await this.update({ dateRangeEnd });
		this.showFromMonth();
		this.setUrlParams();
		await this.fetchScheduledMessages();
	};

	showFromMonth() {
		const { dateRangeStart, dateRangeEnd } = this.state;
		if (!dateRangeStart) {
			return;
		}
		if (moment(dateRangeEnd).diff(moment(dateRangeStart), "months") < 2) {
			this.datePickerTo.getDayPicker().showMonth(dateRangeStart);
		}
	}

	renderActions(recordData) {
		let { state } = this.state;
		let { t } = this.props;

		let user = UserService.get();

		return (
			<div className="scheduled_messages__record-actions">
				{user.GroupPermission.view_scheduled_messages && state === SM_STATES.sent && (
					<Action transparent id={`view-scheduled-message-${recordData.id}`} label={t("View")} icon={Icon.Info} onClick={() => this.openModal(recordData)} />
				)}
				{user.GroupPermission.update_scheduled_messages && state === SM_STATES.pending && (
					<Action transparent id={`edit-scheduled-message-${recordData.id}`} label={t("Edit")} icon={Icon.Edit} onClick={() => this.openModal(recordData)} />
				)}
				{user.GroupPermission.update_scheduled_messages && state === SM_STATES.pending && (
					<Action
						transparent
						id={`cancel-scheduled-message-${recordData.id}`}
						label={t("Cancel")}
						icon={Icon.X}
						onClick={() => this.openCancelAlert(recordData)}
					/>
				)}
				{user.GroupPermission.delete_scheduled_messages && state === SM_STATES.pending && (
					<Action
						transparent
						id={`delete-scheduled-message-${recordData.id}`}
						label={t("Delete")}
						icon={Icon.Trash}
						onClick={() => this.openDeleteAlert(recordData)}
					/>
				)}
			</div>
		);
	}

	getContactNames = recordData => {
		let { t } = this.props;

		let contactNames = "";

		if (recordData.Tags && recordData.Tags.length > 0) {
			if (recordData.Tags.length > 1) {
				contactNames = `${t("Groups")}: ${recordData.Tags[0].name}, ...`;
			} else {
				contactNames = `${t("Group")}: ${recordData.Tags[0].name}`;
			}

			if (recordData.Contacts && recordData.Contacts.length > 1) {
				contactNames += ` ${t("and some contacts")}`;
			} else if (recordData.Contacts && recordData.Contacts.length === 1) {
				contactNames += ` ${t("and a contact")}`;
			}

			return contactNames;
		}

		let contact = recordData.Contacts.find(c => c.name);

		if (contact) {
			contactNames = contact.name;
		} else if (recordData.Contacts) {
			contactNames = recordData.Contacts[0] ? recordData.Contacts[0].phone || recordData.Contacts[0].email : "";
		}

		if (recordData.recipients > 1) {
			contactNames += ", ...";
		}

		return contactNames;
	};

	renderRecord = recordData => {
		let senderName = "";
		if (recordData.User) {
			senderName = UserService.createFullName({ firstName: recordData.User.first_name, lastName: recordData.User.last_name });
		}

		let { localTimeZoneString } = UtilityService.getTimeZoneHelpers();

		try {
			let { type } = this.state;
			let record = [];

			if (type === SM_TYPES.general || type == SM_TYPES.reengagement) {
				record.push(recordData.content);
			}

			record.push(
				this.getContactNames(recordData),
				recordData.recipients,
				recordData.responses,
				`${senderName}`,
				recordData.send_after ? (
					<span className="dh-tip" tip={localTimeZoneString}>
						{moment(recordData.send_after).format("MMM DD YYYY h:mm a")}
					</span>
				) : (
					""
				),
				recordData.created_at ? (
					<span className="dh-tip" tip={localTimeZoneString}>
						{moment(recordData.created_at).format("MMM DD YYYY h:mm a")}
					</span>
				) : (
					""
				),
				this.renderActions(recordData)
			);

			return record;
		} catch (error) {
			console.log(error.message);
		}
		return null;
	};

	renderDatePicker = () => {
		let { dateRangeStart, dateRangeEnd } = this.state;
		let { t } = this.props;

		const modifiers = { dateRangeStart, dateRangeEnd };

		return (
			<div className="scheduled-messages__header__date-picker">
				<div className="scheduled-messages__header__date-picker__label" data-tip data-for="date-range-selector-rtt">
					{t("Date Range")} <Icon.Info size={13} />
					<ReactTooltip id="date-range-selector-rtt" className="mb-react-tooltip" arrowColor="#333" type="info" effect="solid" place="bottom">
						{t("Filter on the Send At time")}
					</ReactTooltip>
				</div>
				<div className="input-group scheduled-messages__header__date-picker__inputs">
					<div className="InputFromTo">
						<DayPickerInput
							value={dateRangeStart}
							placeholder={t(" From")}
							format="LL"
							formatDate={formatDate}
							parseDate={parseDate}
							dayPickerProps={{
								selectedDays: [dateRangeStart, { from: dateRangeStart, to: dateRangeEnd }],
								disabledDays: { after: dateRangeEnd },
								toMonth: dateRangeEnd,
								modifiers,
								numberOfMonths: 2,
								onDayClick: () => this.datePickerTo.getInput().focus()
							}}
							onDayChange={this.handleFromChange}
						/>{" "}
						<DayPickerInput
							ref={el => (this.datePickerTo = el)}
							value={dateRangeEnd}
							placeholder={t(" To")}
							format="LL"
							formatDate={formatDate}
							parseDate={parseDate}
							dayPickerProps={{
								selectedDays: [dateRangeStart, { from: dateRangeStart, to: dateRangeEnd }],
								disabledDays: { before: dateRangeStart },
								modifiers,
								month: dateRangeStart,
								fromMonth: dateRangeStart,
								numberOfMonths: 2
							}}
							onDayChange={this.handleToChange}
						/>
					</div>
				</div>
			</div>
		);
	};

	render() {
		let {
			data,
			loading,
			loadedAll,
			showModal,
			searchTerm,
			selectedScheduledMessage,
			showDeleteAlert,
			showCancelAlert,
			showBlockedAlert,
			type,
			tab,
			sortOrder,
			sortField
		} = this.state;

		if (!this.props.match.params.tab) {
			return <Redirect to={SM_TABS.general.route} />;
		}

		let user = UserService.get();
		let { t } = this.props;

		let createPermission = false;
		let isReviewInvitesEnabled = LocationService.isReviewInvitesEnabled() && LocationService.isScheduledReviewInvitesEnabled();
		let isNpsEnabled = LocationService.isNpsPermissible() && LocationService.isScheduledNpsEnabled();
		let createLabel = "";

		if (type === SM_TYPES.general) {
			createPermission = user.GroupPermission.create_scheduled_messages;
			createLabel = SM_TYPES_FULL.general;
		} else if (type === SM_TYPES.nps) {
			createPermission = user.GroupPermission.create_nps;
			createLabel = SM_TYPES_FULL.nps;
		} else if (type === SM_TYPES.reviewInvite) {
			createPermission = user.GroupPermission.create_invites;
			createLabel = SM_TYPES_FULL.reviewInvite;
		} else if (type === SM_TYPES.reengagement) {
			createPermission = user.GroupPermission.create_reengagements;
			createLabel = SM_TYPES_FULL.reengagement;
		}

		let contact = selectedScheduledMessage ? selectedScheduledMessage.Contacts.find(c => c.name) : [];

		let contactName = "";

		if (contact) {
			contactName = contact.name;
		} else if (selectedScheduledMessage) {
			contactName = selectedScheduledMessage.Contacts[0] ? selectedScheduledMessage.Contacts[0].phone || selectedScheduledMessage.Contacts[0].email : "";
		}

		if (selectedScheduledMessage && selectedScheduledMessage.Contacts && selectedScheduledMessage.Contacts.length > 1) {
			contactName += t(" and {{number}} others...", { number: selectedScheduledMessage.Contacts.length });
		}

		return (
			<>
				<Page>
					<Header title={t("Campaigns")} disclaimer={"(formerly Scheduled Messages)"} />
					<Tabs onSelect={this.onTabSelect} selected={tab}>
						<Tab id={SM_TABS.general.id} value={SM_TABS.general.value} />
						{isReviewInvitesEnabled && <Tab id={SM_TABS.reviewInvite.id} value={SM_TABS.reviewInvite.value} />}
						{isNpsEnabled && <Tab id={SM_TABS.nps.id} value={SM_TABS.nps.value} />}
						{ReengagementService.canViewReengagements() && <Tab id={SM_TABS.reengagement.id} value={SM_TABS.reengagement.value} />}
					</Tabs>

					<div className="scheduled-messages__header">
						<div className="scheduled-messages__header__search">
							{(type === SM_TYPES.general || type === SM_TYPES.reengagement) && (
								<SearchInput placeholder={t("Search ...")} onChange={this.onSearch} initValue={searchTerm} leading={false} />
							)}
							{this.renderDatePicker()}
						</div>
						<div className="scheduled-messages__header__actions">
							<Action id="refresh" label={t("Refresh")} icon={Icon.RefreshCcw} onClick={() => this.fetchScheduledMessages()} />
							{createPermission && (
								<Action
									id="scheduled-messages__modal-button__action"
									label={t("Create")}
									icon={Icon.Plus}
									text={t("Create")}
									onClick={() => this.openModal(null)}
								/>
							)}
						</div>
					</div>

					<Filters filters={this.getFilters()} />
					<List
						items={data}
						loading={loading}
						loadedAll={loadedAll}
						headers={this.getHeaders()}
						renderRecord={this.renderRecord}
						onLoadMore={this.onLoadMore}
						sortOrder={sortOrder}
						sortField={sortField}
						noDataTitle={t(
							"You don't have any scheduled messages! Schedule a custom or templated message to be sent at a later date to one or more of your contacts."
						)}
						noDataIcon={
							<img alt="Scheduled Messages" className="scheduled-messages__no-data-img" src="https://cdn.demandhub.co/web-app/assets/scheduled_messages.svg" />
						}
						noDataButton={createPermission ? t("Create") : null}
						noDataButtonOnClick={() => (createPermission ? this.openModal(null) : null)}
					/>
					<ScheduledMessageModal
						scheduledMessageId={selectedScheduledMessage ? selectedScheduledMessage.id : null}
						show={showModal}
						onHide={this.onModalHide}
						type={type}
					/>
				</Page>
				<Alert show={showDeleteAlert} onClose={this.onConfirmDelete} title={t("Are you sure?")} confirm={t("Yes")} cancel={t("No")}>
					{t("Are you sure you would like to delete this scheduled message?")}
				</Alert>
				<Alert show={showCancelAlert} onClose={this.onConfirmCancel} title={t("Are you sure?")} confirm={t("Yes")} cancel={t("No")}>
					{t("Are you sure you would like to cancel this scheduled message?")}
				</Alert>
				<Alert type="info" show={showBlockedAlert} title={t("Upgrade Subscription")} confirm={t("Chat with us")} onClose={this.onBlockedFeatureAlertClose}>
					{t("To enable Scheduled Messages, please contact support@demandhub.co or chat with us.")}
				</Alert>
			</>
		);
	}
}

export default withRouter(withLocation(withTranslation(null, { withRef: true })(ScheduledMessages)));
