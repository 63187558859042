import AverageRating from "../scenes/Dashboard/AverageRating";
import ClickRate from "../scenes/Dashboard/ClickRate";
import InboundChatLeads from "../scenes/Dashboard/InboundChatLeads";
import InviteGoal from "../scenes/Dashboard/InviteGoal";
import ReviewGoal from "../scenes/Dashboard/ReviewGoal";
import ReviewSummary from "../scenes/Dashboard/ReviewSummary";
import ReviewsToRespondTo from "../scenes/Dashboard/ReviewsToRespondTo";
import TotalMessages from "../scenes/Dashboard/TotalMessages";
import TotalReviews from "../scenes/Dashboard/TotalReviews";
import WidgetConversions from "../scenes/Dashboard/WidgetConversions";
import ReengagementAttribution from "../scenes/Dashboard/ReengagementAttribution";
import ReengagementConversions from "../scenes/Dashboard/ReengagementConversions";
import ReengagementRevenue from "../scenes/Dashboard/ReengagementRevenue";
import WidgetConversionsRevenue from "../scenes/Dashboard/WidgetConversionsRevenue";
import ReengagementsSent from "../scenes/Dashboard/ReengagementsSent";
import PaymentsCount from "../scenes/Dashboard/PaymentsCount";
import KeywordAnalyticsCard from "../scenes/Analytics/KeywordAnalyticsCard";
import ScheduledMessageAnalytics from "../scenes/Analytics/ScheduledMessageAnalytics";
import WidgetAnalytics from "../scenes/Analytics/WidgetAnalytics";
import { AnalyticsConstants } from "./AnalyticsConstants";
import SubscriberAnalytics from "../scenes/Analytics/Contacts/SubscriberAnalytics";
import LeadsBarChart from "../scenes/Analytics/LeadSource/LeadsBarChart";
import LeadsPieChart from "../scenes/Analytics/LeadSource/LeadsPieChart";
import LeadsByDate from "../scenes/Analytics/LeadSource/LeadsByDate";
import LeadsByHour from "../scenes/Analytics/LeadSource/LeadsByHour";
import MessagesAnalytics from "../scenes/Analytics/Messenger/MessagesAnalytics";
import MessageStates from "../scenes/Analytics/Messenger/MessageStates";
import FailedMessageDeliverability from "../scenes/Analytics/Messenger/FailedMessageDeliverability";
import ReviewSitePerformance from "../scenes/Analytics/ReviewSites/ReviewSitePerformance";
import ReviewSitesResults from "../scenes/Analytics/ReviewSites/ReviewSitesResults";
import ReviewsByLocation from "../scenes/Analytics/Location/ReviewsByLocation";
import RatingsByLocation from "../scenes/Analytics/Location/RatingsByLocation";
import ReviewCountsOverYear from "../scenes/Analytics/Location/ReviewCountsOverYear";
import LocationAnalyticsTable from "../scenes/Analytics/Location/LocationAnalyticsTable";
import TotalReplies from "../scenes/Dashboard/TotalReplies";
import ReviewsBySite from "../scenes/Analytics/Location/ReviewsBySite";
import RatingsBySite from "../scenes/Analytics/Location/RatingsBySite";

import UserService from "../services/UserService";
import LocationService from "../services/LocationService";
import ReengagementService from "../services/ReengagementService";
import PaymentService from "../services/PaymentService";
import WorkflowService from "../services/WorkflowService";
import ConversionsBarChart from "../scenes/Dashboard/ConversionsBarChart";

export const DASHBOARD_COLUMNS = {
	name: {
		id: "name",
		value: "Dashboard Name",
		sortable: true,
		sortField: "name",
		width: 2
	},
	author_user_id: {
		id: "author_user_id",
		value: "Author",
		sortable: true,
		sortField: "author_user_id",
		width: 1
	},
	is_default: {
		id: "is_default",
		value: "Is Default",
		sortable: true,
		sortField: "is_default",
		width: 1
	},
	created_at: {
		id: "created_at",
		value: "Created At",
		sortable: true,
		sortField: "created_at",
		width: 1
	},
	actions: {
		id: "actions",
		value: "Actions",
		sortable: false,
		sortField: "actions",
		width: 1
	}
};

/**
 * These are the different dashboard cards that are available to be displayed on the dashboard.
 * For new metrics and cards an entry must be made here as well for that component
 */
export const DASHBOARD_CARDS = {
	inviteGoalThisMonth: {
		id: "inviteGoalThisMonth",
		name: "Invite Goal This Month",
		description: "Show the progress of the invites sent goal.",
		w: 1,
		h: 2,
		component: InviteGoal,
		className: "dashboard__card--goal",
		props: {
			isEnabled: () => LocationService.isReviewInvitesEnabled(),
			isPermissible: () => UserService.get().GroupPermission.view_reviews
		}
	},
	reviewGoalThisMonth: {
		id: "reviewGoalThisMonth",
		name: "Review Goal This Month",
		description: "Show the progress of the reviews sent goal.",
		w: 1,
		h: 2,
		component: ReviewGoal,
		className: "dashboard__card--goal",
		props: {
			isEnabled: () => LocationService.isReviewInvitesEnabled(),
			isPermissible: () => UserService.get().GroupPermission.view_reviews
		}
	},
	reviewsToRespondTo: {
		id: "reviewsToRespondTo",
		name: "Reviews To Respond To",
		description: "Show the number of reviews that need to be responded to.",
		w: 1,
		h: 2,
		component: ReviewsToRespondTo,
		className: "dashboard__widget",
		props: {
			isEnabled: () => LocationService.isReviewsEnabled(),
			isPermissible: () => UserService.get().GroupPermission.view_reviews
		}
	},
	averageRating: {
		id: "averageRating",
		name: "Average Rating",
		description: "Show the average rating of this location across all review sites.",
		w: 1,
		h: 2,
		component: AverageRating,
		className: "dashboard__widget",
		props: {
			isEnabled: () => LocationService.isReviewsEnabled(),
			isPermissible: () => UserService.get().GroupPermission.view_reviews
		}
	},
	totalReviews: {
		id: "totalReviews",
		name: "Total Reviews",
		description: "Show the total amount of reviews of this location across all review sites.",
		w: 1,
		h: 2,
		component: TotalReviews,
		className: "dashboard__widget",
		props: {
			isEnabled: () => LocationService.isReviewsEnabled(),
			isPermissible: () => UserService.get().GroupPermission.view_reviews
		}
	},
	totalReplies: {
		id: "totalReplies",
		name: "Total Replies",
		description: "Show the review replies of this location across all review sites.",
		w: 1,
		h: 2,
		component: TotalReplies,
		className: "dashboard__widget",
		isEnabled: () => LocationService.isReviewsEnabled(),
		isPermissible: () => UserService.get().GroupPermission.view_reviews
	},
	clickRate: {
		id: "clickRate",
		name: "Click Rate",
		description: "Show the click rate of this location across all review sites.",
		w: 1,
		h: 2,
		component: ClickRate,
		className: "dashboard__widget",
		props: {
			isEnabled: () => LocationService.isReviewInvitesEnabled(),
			isPermissible: () => UserService.get().GroupPermission.view_reviews
		}
	},
	inboundChatLeads: {
		id: "inboundChatLeads",
		name: "Inbound Chat Leads",
		description: "Show the amount of inbound leads from webchat.",
		w: 1,
		h: 2,
		component: InboundChatLeads,
		className: "dashboard__widget",
		props: {
			isEnabled: () => LocationService.isMessengerPermissible(),
			isPermissible: () => UserService.get().GroupPermission.view_reports
		}
	},
	reviewSummary: {
		id: "reviewSummary",
		name: "Reviews Summary",
		description: "Show a review summary of each review site.",
		w: 1,
		h: 5,
		component: ReviewSummary,
		className: "reviews-summary",
		props: {
			isEnabled: () => LocationService.isReviewInvitesEnabled(),
			isPermissible: () => UserService.get().GroupPermission.view_reports
		}
	},
	totalMessages: {
		id: "totalMessages",
		name: "Total Messages",
		description: "A pie chart showing the total messages sent.",
		w: 1,
		h: 5,
		component: TotalMessages,
		className: "dashboard__card--total-messages",
		props: {
			isEnabled: () => LocationService.isMessengerPermissible(),
			isPermissible: () => UserService.get().GroupPermission.view_reports
		}
	},
	widgetConversions: {
		id: "widgetConversions",
		name: "Widget Conversions",
		description: "Show conversions from webchat and booking widget.",
		w: 1,
		h: 2,
		component: WidgetConversions,
		className: "dashboard__widget",
		props: {
			isEnabled: () => LocationService.isWebchatPermissible() || LocationService.isBookingsPermissible(),
			isPermissible: () => UserService.get().GroupPermission.view_reports
		}
	},
	widgetConversionsRevenue: {
		id: "widgetConversionsRevenue",
		name: "Widget Conversion Revenues",
		description: "Show the revenue from webchat or booking widget conversions.",
		w: 1,
		h: 2,
		component: WidgetConversionsRevenue,
		className: "dashboard__widget",
		props: {
			isEnabled: () => LocationService.isWebchatPermissible() || LocationService.isBookingsPermissible(),
			isPermissible: () => UserService.get().GroupPermission.view_reports
		}
	},
	reengagementAttribution: {
		id: "reengagementAttribution",
		name: "Reengagement Attribution",
		description: "Show reengagement attribution data.",
		w: 1,
		h: 5,
		component: ReengagementAttribution,
		className: "",
		props: {
			isEnabled: () => ReengagementService.isReengagementEnabled(),
			isPermissible: () => UserService.get().GroupPermission.view_reports
		}
	},
	reengagementConversions: {
		id: "reengagementConversions",
		name: "Reengagement Conversions",
		description: "Show the number of conversions from appointment reengagements.",
		w: 1,
		h: 2,
		component: ReengagementConversions,
		className: "dashboard__card--reeng-conversions dashboard__widget",
		props: {
			isEnabled: () => ReengagementService.isReengagementEnabled(),
			isPermissible: () => UserService.get().GroupPermission.view_reports
		}
	},
	generatedRevenueReengagement: {
		id: "generatedRevenueReengagement",
		name: "Generated Revenue Reengagement",
		description: "Show the generated revenue from reengagements.",
		w: 1,
		h: 2,
		component: ReengagementRevenue,
		className: "dashboard__card--reeng-revenue dashboard__widget",
		props: {
			isEnabled: () => ReengagementService.isReengagementEnabled(),
			isPermissible: () => UserService.get().GroupPermission.view_reports
		}
	},
	reengagementsSent: {
		id: "reengagementsSent",
		name: "Reengagements Sent",
		description: "Show the number of reengagements sent.",
		w: 1,
		h: 2,
		component: ReengagementsSent,
		className: "dashboard__card--reeng-revenue dashboard__widget",
		props: {
			isEnabled: () => ReengagementService.isReengagementEnabled(),
			isPermissible: () => UserService.get().GroupPermission.view_reports
		}
	},
	paymentsCount: {
		id: "paymentsCount",
		name: "Payments",
		description: "Show payments and payment requests sent.",
		w: 1,
		h: 2,
		component: PaymentsCount,
		className: "dashboard__widget",
		props: {
			isEnabled: () => PaymentService.isPaymentsEnabled(),
			isPermissible: () => UserService.get().GroupPermission.view_reports
		}
	},
	keyword: {
		id: "keyword",
		name: "Keyword Usage",
		description: "Show keyword usage.",
		w: 1,
		h: 6,
		component: KeywordAnalyticsCard,
		className: "dashboard__card--keyword-usage",
		props: {
			isCard: true,
			isEnabled: () => WorkflowService.isKeywordsEnabled(),
			isPermissible: () => UserService.get().GroupPermission.view_reports
		}
	},
	scheduledMessageAnalytics: {
		id: "scheduledMessageAnalytics",
		name: "Scheduled Message Usage",
		description: "Show the amount of scheduled messages sent and the amount of responses.",
		w: 1,
		h: 6,
		component: ScheduledMessageAnalytics,
		className: "",
		props: {
			isCard: true,
			isEnabled: () => LocationService.isScheduledMessagesEnabled(),
			isPermissible: () => UserService.get().GroupPermission.view_reports
		}
	},
	leadsBarChart: {
		id: "leadsBarChart",
		name: "Leads Bar Chart",
		description: "Show the number of leads by date.",
		w: 1,
		h: 6,
		component: LeadsBarChart,
		className: "lead-source-analytics__chart-section",
		props: {
			isCard: true,
			isEnabled: () => true,
			isPermissible: () => UserService.get().GroupPermission.view_contacts && UserService.get().GroupPermission.view_reports
		}
	},
	leadsPieChart: {
		id: "leadsPieChart",
		name: "Leads Pie Chart",
		description: "Show the number of leads by type.",
		w: 1,
		h: 6,
		component: LeadsPieChart,
		className: "lead-source-analytics__chart-section",
		props: {
			isCard: true,
			isEnabled: () => true,
			isPermissible: () => UserService.get().GroupPermission.view_contacts && UserService.get().GroupPermission.view_reports
		}
	},
	leadsByDate: {
		id: "leadsByDate",
		name: "Leads By Date",
		description: "Show the number of leads by date period.",
		w: 1,
		h: 6,
		component: LeadsByDate,
		className: "lead-source-analytics__chart-section",
		props: {
			isCard: true,
			isEnabled: () => true,
			isPermissible: () => UserService.get().GroupPermission.view_contacts && UserService.get().GroupPermission.view_reports
		}
	},
	leadsByHour: {
		id: "leadsByHour",
		name: "Leads By Hour",
		description: "Show the number of leads by hour of the day.",
		w: 1,
		h: 6,
		component: LeadsByHour,
		className: "lead-source-analytics__chart-section",
		props: {
			isCard: true,
			isEnabled: () => true,
			isPermissible: () => UserService.get().GroupPermission.view_contacts && UserService.get().GroupPermission.view_reports
		}
	},
	subscriberAnalytics: {
		id: "subscriberAnalytics",
		name: "Contact Subscriber Data",
		description: "Show subscribe and unsubscribe counts.",
		w: 1,
		h: 6,
		component: SubscriberAnalytics,
		className: "dashboard__card--keyword-usage",
		props: {
			isCard: true,
			cardName: "Contact Subscriber Data",
			isEnabled: () => true,
			isPermissible: () => UserService.get().GroupPermission.view_contacts && UserService.get().GroupPermission.view_reports
		}
	},
	widgetWebchatAnalytics: {
		id: "widgetWebchatAnalytics",
		name: "Webchat Widget Usage",
		description: "Show Webchat widget usage.",
		w: 1,
		h: 6,
		component: WidgetAnalytics,
		className: "dashboard__card--keyword-usage",
		props: {
			isCard: true,
			cardName: "Webchat Widget Usage",
			selectedTab: AnalyticsConstants.APP_NAME.webchat,
			isEnabled: () => LocationService.isMessengerPermissible(),
			isPermissible: () => UserService.get().GroupPermission.view_reports
		}
	},
	widgetReviewsAnalytics: {
		id: "widgetReviewsAnalytics",
		name: "Reviews Widget Usage",
		description: "Show Reviews widget usage.",
		w: 1,
		h: 6,
		component: WidgetAnalytics,
		props: {
			isCard: true,
			cardName: "Reviews Widget Usage",
			selectedTab: AnalyticsConstants.APP_NAME.reviewsWidget,
			isEnabled: () => LocationService.isMessengerPermissible(),
			isPermissible: () => UserService.get().GroupPermission.view_reports
		}
	},
	widgetBookingAnalytics: {
		id: "widgetBookingAnalytics",
		name: "Booking Widget Usage",
		description: "Show Booking widget usage.",
		w: 1,
		h: 6,
		component: WidgetAnalytics,
		props: {
			isCard: true,
			cardName: "Booking Widget Usage",
			selectedTab: AnalyticsConstants.APP_NAME.bookingWidget,
			isEnabled: () => LocationService.isMessengerPermissible(),
			isPermissible: () => UserService.get().GroupPermission.view_reports
		}
	},
	// Supers and CS only. Skip from showing as a selectable dashboard card
	conversionsBarChart: {
		id: "conversionsBarChart",
		name: "Conversions Bar Cahrt",
		description: "Show conversion analytics by day",
		w: 1,
		h: 6,
		component: ConversionsBarChart,
		skip: true, // Will not be a selectable in a dashboard
		props: {
			isCard: true,
			cardName: "Conversion Analytics",
			isEnabled: () => UserService.isSuperAdminOrCustomerSuccessOrAccountOwner,
			isPermissible: () => UserService.get().GroupPermission.view_reports
		}
	},
	messagesAnalytics: {
		id: "messagesAnalytics",
		name: "Messages Analytics",
		description: "Show total messages sent/received and the total amount of inbound chats.",
		w: 1,
		h: 6,
		component: MessagesAnalytics,
		props: {
			isCard: true,
			isEnabled: () => LocationService.isMessengerPermissible(),
			isPermissible: () => UserService.get().GroupPermission.view_reports
		}
	},
	messageStates: {
		id: "messageStates",
		name: "Message States",
		description: "Show the messages by date in their state.",
		w: 1,
		h: 6,
		component: MessageStates,
		props: {
			isCard: true,
			isEnabled: () => LocationService.isMessengerPermissible(),
			isPermissible: () => UserService.get().GroupPermission.view_messenger_reports
		}
	},
	failedMessageDeliverability: {
		id: "failedMessageDeliverability",
		name: "Failed Message Deliverability",
		description: "Show messages that failed to be delivered.",
		w: 1,
		h: 6,
		component: FailedMessageDeliverability,
		props: {
			isCard: true,
			isEnabled: () => LocationService.isMessengerPermissible(),
			isPermissible: () => UserService.get().GroupPermission.view_messenger_reports
		}
	},
	reviewSitePerformance: {
		id: "reviewSitePerformance",
		name: "Review Site Performance",
		description: "Show Google reviews, invites, clicks, and replies by day.",
		w: 1,
		h: 6,
		component: ReviewSitePerformance,
		props: {
			isCard: true,
			isEnabled: () => LocationService.isReviewsEnabled(),
			isPermissible: () => UserService.get().GroupPermission.view_reports
		}
	},
	reviewSitesResults: {
		id: "reviewSitesResults",
		name: "Review Site Results",
		description: "Show number of reviews and rating by day for a review site.",
		w: 1,
		h: 6,
		component: ReviewSitesResults,
		props: {
			isCard: true,
			isEnabled: () => LocationService.isReviewsEnabled(),
			isPermissible: () => UserService.get().GroupPermission.view_review_sites
		}
	},
	reviewsByLocation: {
		id: "reviewsByLocation",
		name: "Reviews By Location",
		description: "Show the total number of reviews of all locations.",
		w: 1,
		h: 7,
		component: ReviewsByLocation,
		props: {
			isCard: true,
			isEnabled: () => LocationService.isReviewsEnabled(),
			isPermissible: () => UserService.get().GroupPermission.view_analytic_reports
		}
	},
	ratingsByLocation: {
		id: "ratingsByLocation",
		name: "Ratings By Location",
		description: "Show the average reviews rating of all locations.",
		w: 1,
		h: 7,
		component: RatingsByLocation,
		props: {
			isCard: true,
			isEnabled: () => LocationService.isReviewsEnabled(),
			isPermissible: () => UserService.get().GroupPermission.view_analytic_reports
		}
	},
	reviewsBySite: {
		id: "reviewsBySite",
		name: "Reviews By Site",
		description: "Show the number of reviews for each review site.",
		w: 1,
		h: 7,
		component: ReviewsBySite,
		props: {
			isCard: true,
			isEnabled: () => LocationService.isReviewsEnabled(),
			isPermissible: () => UserService.get().GroupPermission.view_analytic_reports
		}
	},
	ratingsBySite: {
		id: "ratingsBySite",
		name: "Ratings By Site",
		description: "Show the average rating for each review site.",
		w: 1,
		h: 7,
		component: RatingsBySite,
		props: {
			isCard: true,
			isEnabled: () => LocationService.isReviewsEnabled(),
			isPermissible: () => UserService.get().GroupPermission.view_analytic_reports
		}
	},
	reviewCountsOverYear: {
		id: "reviewCountsOverYear",
		name: "Review Counts Over Year",
		description: "Show the total monthly review count for all locations.",
		w: 1,
		h: 6,
		component: ReviewCountsOverYear,
		props: {
			isCard: true,
			isEnabled: () => LocationService.isReviewsEnabled(),
			isPermissible: () => UserService.get().GroupPermission.view_analytic_reports
		}
	},
	locationAnalyticsTable: {
		id: "locationAnalyticsTable",
		name: "Locations Overall Snapshot",
		description: "Show review and review invite data for all locations in a table format.",
		w: 1,
		h: 5,
		component: LocationAnalyticsTable,
		props: {
			isCard: true,
			isEnabled: () => LocationService.isReviewsEnabled(),
			isPermissible: () => UserService.get().GroupPermission.view_analytic_reports
		}
	}
};
