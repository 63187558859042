import React from "react";
import Select from "react-select";
import _ from "lodash";
import * as Icon from "react-feather";

import UserService from "../../../services/UserService";

import Action from "../../../components/common/Action";

import "../../../styles/css/components/commons/condition.css";
import "./reengagements.css";

const OPERATORS = {
	"=": { value: "=", label: "=" },
	"<": { value: "<", label: "<" },
	">": { value: ">", label: ">" },
	"<=": { value: "<=", label: "<=" },
	">=": { value: ">=", label: ">=" },
	"!=": { value: "!=", label: "!=" }
};

class Condition extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			showConfirmDeleteModal: false,
			condition: this.props.condition
		};
	}

	isReadOnly = () => {
		let user = UserService.get();
		return !user.GroupPermission.update_reengagements;
	};

	onConfirmDeleteCondition = () => {
		this.setState({ showConfirmDeleteModal: true });
	};

	onRemoveCondition = confirm => {
		if (confirm && this.props.onRemoveCondition) {
			this.props.onRemoveCondition();
		}
		this.setState({
			showConfirmDeleteModal: false
		});
	};

	onConditionOperatorChange = option => {
		if (this.props.onConditionOperatorChange) {
			this.props.onConditionOperatorChange(option);
		}
	};

	onConditionTypeChange = event => {
		if (this.props.onConditionTypeChange) {
			this.props.onConditionTypeChange(event);
		}
	};

	onParamChange = (conditionId, paramId, param) => {
		let { conditionIndex } = this.props;
		if (this.props.onParamChange) {
			this.props.onParamChange(conditionIndex, conditionId, paramId, param);
		}
	};

	onParamValueChange = (conditionId, paramId, param, value) => {
		param.value = value;
		this.onParamChange(conditionId, paramId, { id: param.id, value: param.value, operator: param.operator });
	};

	onParamOperatorChange = (conditionId, paramId, param, operator) => {
		param.operator = operator.value;
		this.onParamChange(conditionId, paramId, { id: param.id, value: param.value, operator: param.operator });
	};

	onAddParameter = ({ conditionId, paramId }) => {
		let { conditionIndex } = this.props;

		if (this.props.onAddParameter) {
			this.props.onAddParameter({ conditionIndex, conditionId, paramId });
		}
	};

	onRemoveParameter = ({ paramIndex }) => {
		let { conditionIndex } = this.props;

		if (this.props.onRemoveParameter) {
			this.props.onRemoveParameter({ conditionIndex, paramIndex });
		}
	};

	renderParamValue = value => {
		if (typeof value === "object" && value !== null && value !== "") {
			return JSON.stringify(value);
		}
		return value;
	};

	renderParameters() {
		let { condition, conditionInfo } = this.props;

		let parameters = condition.params;

		let paramInfoIds = Object.keys(conditionInfo.params);

		if (!paramInfoIds || paramInfoIds.length < 1) {
			return null;
		}

		return (
			<>
				<div className="condition__parameters">Parameters:</div>
				{parameters.map((param, paramIndex) => {
					let paramInfo = conditionInfo.params[param.id];
					let operators = [...Object.values(OPERATORS)];

					if (paramInfo.operators) {
						operators = operators.filter(op => paramInfo.operators.indexOf(op.value) >= 0);
					}

					return (
						<div className="condition__parameter" key={`${paramInfo.id}-${paramIndex}`}>
							<div className="condition__parameter__name rmm__conditions__condition__parameter__name">
								{paramInfo.required ? <span className="text-danger">*</span> : ""}
								<input
									className="Common__input fnctst-parameter-key rmm__conditions__condition__parameter__name__input"
									value={paramInfo.name}
									placeholder={paramInfo.name}
									disabled={true}
								/>
							</div>
							<div className="condition__parameter__operator">
								<Select
									className="rmm__conditions__condition__parameter__operator__select"
									value={param && param.operator ? OPERATORS[param.operator] : null}
									placeholder="Operator"
									options={Object.values(operators)}
									onChange={option => this.onParamOperatorChange(conditionInfo.id, paramInfo.id, param, option)}
								/>
							</div>
							{paramInfo.type === "number" || paramInfo.type === "string" ? (
								<input
									name={paramInfo.id}
									className="Common__input fnctst-parameter-value"
									value={param && typeof param.value !== "undefined" ? param.value : paramInfo.value}
									placeholder={"value"}
									onChange={event => this.onParamValueChange(conditionInfo.id, paramInfo.id, param, event.target.value)}
									disabled={this.isReadOnly()}
								/>
							) : null}
							{paramInfo.type === "select" && (
								<Select
									id={`${paramInfo.id}`}
									className={"rmm__conditions__condition__parameter__value__select"}
									options={paramInfo.options}
									isMulti={paramInfo.isMulti}
									value={param.value}
									onChange={option => this.onParamValueChange(conditionInfo.id, paramInfo.id, param, option)}
									disabled={this.isReadOnly()}
								/>
							)}
							<div className="condition__parameter__remove">
								<Action
									key={`condition-${conditionInfo.id}-remove-param`}
									id={`condition-${conditionInfo.id}-remove-param`}
									label={"Remove parameter"}
									icon={Icon.MinusCircle}
									onClick={() =>
										this.onRemoveParameter({
											paramIndex
										})
									}
								/>
							</div>
						</div>
					);
				})}

				<div className="condition__parameters__add">
					<Action
						key={`condition-${conditionInfo.id}-add-param`}
						id={`condition-${conditionInfo.id}-add-param`}
						label={"Add parameter"}
						icon={Icon.Plus}
						onClick={() =>
							this.onAddParameter({
								conditionId: conditionInfo.id,
								paramId: paramInfoIds[0]
							})
						}
					/>
				</div>
			</>
		);
	}

	render() {
		let { conditionInfo, condition, conditionIndex, onToggleCondition, reengagementConfig, onConditionChange } = this.props;

		if (!reengagementConfig) {
			return null;
		}

		let conditionOptions = Object.values(reengagementConfig.conditionInfo).map(c => {
			return { label: c.name, value: c.id };
		});

		return (
			<div className="condition rmm__condition">
				<div className="condition__type condition__type--space-between">
					<div className="input rmm__condition__condition__type__input">
						<Select
							className={"rmm__condition__condition__type__input__select"}
							options={conditionOptions}
							value={{ label: conditionInfo.name, value: conditionInfo.id }}
							placeholder="Select condition"
							onChange={option => onConditionChange(conditionIndex, option.value)}
						/>
					</div>
					<div className="condition__type__delete">
						<Action
							id={`remove-${condition.id}-${conditionIndex}`}
							label={"Remove"}
							icon={Icon.X}
							onClick={() => onToggleCondition({ conditionIndex, conditionId: condition.id })}
						/>
					</div>
				</div>

				<div>
					<div className="condition__type__description">{conditionInfo.description}</div>
					{this.renderParameters()}
				</div>
			</div>
		);
	}
}

export default Condition;
