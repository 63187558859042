import React from "react";
import { withRouter } from "react-router-dom";
import * as Icon from "react-feather";
import queryString from "query-string";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate, parseDate } from "react-day-picker/moment";
import moment from "moment";
import { getServices } from "service-fetch";
import ReactTooltip from "react-tooltip";

import Page from "../../../components/common/Page";
import Header from "../../../components/common/Header";
import Action from "../../../components/common/Action";
import List from "../../../components/common/List";
import SearchInput from "../../../components/common/SearchInput";
import SignUpDetailsModal from "./SignUpDetailsModal";
import Select from "../../../components/common/DHSelect";

import GAService from "../../../services/GAService";
import UserService from "../../../services/UserService";
import ToastService from "../../../services/ToastService";

import { SORT_ORDER } from "../../../constants/CommonConstants";
import { SIGN_UP_COLUMNS, SIGN_UP_STATE_FILTERS } from "../../../constants/SignUpConstants";

const { Sign_upsService: SignUpService } = getServices();

import "./sign-ups.css";

class SignUps extends React.Component {
	constructor(props) {
		super(props);

		let { stateFilter, searchTerm, sortField, sortOrder, startDate, endDate } = queryString.parse(this.props.location.search);

		if (startDate) {
			startDate = moment(startDate).toDate();
		}
		if (!startDate) {
			startDate = moment()
				.subtract(30, "days")
				.toDate();
		}

		if (endDate) {
			endDate = moment(endDate).toDate();
		}
		if (!endDate) {
			endDate = moment()
				.add(45, "days")
				.toDate();
		}

		this.state = {
			loading: false,
			loadedAll: true,
			data: [],

			stateFilter: stateFilter ? stateFilter : SIGN_UP_STATE_FILTERS.all,
			startDate: startDate,
			endDate: endDate,
			searchTerm: searchTerm || "",
			sortField: sortField || "id",
			sortOrder: sortOrder || SORT_ORDER.desc,
			limit: 50,
			pageSize: 50,

			showSignUpDetails: false,
			selectedSignUp: null
		};
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });

		this.resetComponent();
	}

	resetComponent = async () => {
		let { limit } = this.state;

		await this.update({
			loading: true
		});

		let data = await this.fetchData();

		await this.update({
			loading: false,
			loadedAll: data ? data.length < limit : true
		});
	};

	fetchData = async () => {
		let { searchTerm, stateFilter, startDate, endDate, sortField, sortOrder, limit } = this.state;

		let states = [];

		if (stateFilter && stateFilter.value && stateFilter.value !== SIGN_UP_STATE_FILTERS.all.value) {
			states = [stateFilter.value];
		}

		let { data, error } = await SignUpService.fetchSignUps({
			query: {
				searchTerm,
				states,
				startDate: startDate,
				endDate: endDate,
				sortField,
				sortOrder,
				limit
			}
		});

		if (error) {
			ToastService.error("Error fetching sign ups. Please try again.");
			this.update({ loading: false, data: [] });
			return;
		}

		if (!data) {
			ToastService.info("No Sign Ups found.");
			this.update({ loading: false, data: [] });
			return;
		}

		await this.update({
			data
		});
		return data;
	};

	onSearchChange = async value => {
		await this.update({
			searchTerm: value
		});
		this.setUrlParams();
		await this.fetchData();
	};

	onLoadMore = async () => {
		let { limit, pageSize } = this.state;

		await this.update({
			limit: limit + pageSize
		});

		await this.fetchData();
	};

	onRecordClicked = item => {};

	getHeaders() {
		let headers = {
			items: SIGN_UP_COLUMNS,
			sortBy: this.sortBy
		};

		return headers;
	}

	sortBy = async sortField => {
		let { sortOrder } = this.state;
		sortOrder = sortOrder === SORT_ORDER.asc ? SORT_ORDER.desc : SORT_ORDER.asc;
		await this.update({ sortField, sortOrder });
		this.setUrlParams();
		await this.fetchData();
	};

	handleFromChange = async startDate => {
		// Change the from date and focus the "to" input field
		await this.update({ startDate });
		this.setUrlParams();
		this.fetchData();
	};
	handleToChange = async endDate => {
		await this.update({ endDate });
		this.showFromMonth;
		this.setUrlParams();
		this.fetchData();
	};
	showFromMonth() {
		const { startDate, endDate } = this.state;
		if (!startDate) {
			return;
		}
		if (moment(endDate).diff(moment(startDate), "months") < 2) {
			this.datePickerTo.getDayPicker().showMonth(startDate);
		}
	}

	setUrlParams() {
		if (!this.props.history) {
			return;
		}

		let { stateFilter, searchTerm, sortField, sortOrder, startDate, endDate } = this.state;

		let params = {};

		if (stateFilter && stateFilter.value) {
			params.stateFilter = stateFilter.value;
		}

		if (searchTerm) {
			params.searchTerm = searchTerm;
		}

		if (sortField) {
			params.sortField = sortField;
		}

		if (sortOrder) {
			params.sortOrder = sortOrder;
		}

		if (startDate) {
			params.startDate = startDate;
		}

		if (endDate) {
			params.endDate = endDate;
		}

		params = new URLSearchParams(params);
		this.props.history.replace({
			pathname: this.props.location.pathname,
			search: params.toString()
		});
	}

	onShowSignUpDetails = recordData => {
		this.update({
			selectedSignUp: recordData,
			showSignUpDetails: true
		});
	};

	onDetailsModalClose = shouldRefreshData => {
		this.update({ showSignUpDetails: false });
		if (shouldRefreshData) {
			this.fetchData();
		}
	};

	onStateFilterChange = async stateFilter => {
		await this.update({ stateFilter });
		this.setUrlParams();
		this.fetchData();
	};

	renderRecord = recordData => {
		try {
			let locationFields = recordData.location_fields;

			return [
				recordData.legal_business_name,
				<div className="msu-state__text">{recordData.state.replace("_", " ")}</div>,
				recordData.contact_name,
				locationFields.country,
				// use icon
				recordData.is_free_trial ? "Yes" : "No",
				<div className="dh-tip" tip={recordData.start_date}>
					{recordData.start_date}
				</div>,
				<div className="dh-tip" tip={recordData.created_at}>
					{moment(recordData.created_at).format("YYYY-MM-DD")}
				</div>,
				this.renderActions(recordData)
			];
		} catch (error) {
			console.log(error);
		}
		return null;
	};

	renderActions = recordData => {
		let canManage = UserService.isSuperAdminOrCustomerSuccessOrAccountOwner();

		if (!canManage) {
			return null;
		}

		return (
			<div className="dh-list__actions">
				<Action
					key={`edit-${recordData.id}`}
					id={`edit-${recordData.id}`}
					label={"Edit Sign Up"}
					icon={Icon.Edit}
					onClick={() => this.props.history.push(`/customer-success/sign-ups/${recordData.id}`)}
				/>

				<Action
					key={`Details-${recordData.id}`}
					id={`Details-${recordData.id}`}
					label={"See Details"}
					icon={Icon.MoreHorizontal}
					onClick={() => this.onShowSignUpDetails(recordData)}
				/>
			</div>
		);
	};

	renderDateRangeFilter = () => {
		let { startDate, endDate } = this.state;

		const modifiers = { dateRangeStart: startDate, endDate: endDate };

		return (
			<div className="dh-page__advances-filters__datepicker">
				<div className="dh-page__advances-filters__datepicker__text" data-tip data-for="date-range-selector-rtt">
					Date Range <Icon.Info size={13} />
					<ReactTooltip id="date-range-selector-rtt" className="mb-react-tooltip" arrowColor="#333" type="info" effect="solid" place="bottom">
						Filter on Start Date
					</ReactTooltip>
				</div>
				<div className="input-group">
					<div className="InputFromTo">
						<DayPickerInput
							value={startDate}
							placeholder={" From"}
							format="ll"
							formatDate={formatDate}
							parseDate={parseDate}
							dayPickerProps={{
								selectedDays: [startDate, { from: startDate, to: endDate }],
								disabledDays: { after: endDate },
								toMonth: endDate,
								modifiers,
								numberOfMonths: 2,
								onDayClick: () => this.datePickerTo.getInput().focus()
							}}
							onDayChange={this.handleFromChange}
						/>{" "}
						<span>
							<DayPickerInput
								ref={el => (this.datePickerTo = el)}
								value={endDate}
								placeholder={" To"}
								format="ll"
								formatDate={formatDate}
								parseDate={parseDate}
								dayPickerProps={{
									selectedDays: [startDate, { from: startDate, to: endDate }],
									disabledDays: { before: startDate },
									modifiers,
									month: startDate,
									fromMonth: startDate,
									numberOfMonths: 2
								}}
								onDayChange={this.handleToChange}
							/>
						</span>
					</div>
				</div>
			</div>
		);
	};

	render = () => {
		let { data, loading, loadedAll, searchTerm, stateFilter, sortField, sortOrder, selectedSignUp, showSignUpDetails } = this.state;

		let canCreate = UserService.isSuperAdminOrCustomerSuccessOrAccountOwner();

		return (
			<Page>
				<Header title="Sign Ups">
					{canCreate && (
						<Action
							id="create-sign-up"
							label={"Create Sign Up"}
							icon={Icon.Plus}
							onClick={() => this.props.history.push(`/customer-success/sign-ups/create`)}
						/>
					)}
				</Header>
				<div className="Common__search">
					<SearchInput placeholder={"Search ..."} onChange={this.onSearchChange} initValue={searchTerm} />
				</div>

				<div className="dh-page__advances-filters">
					{this.renderDateRangeFilter()}

					<Select
						id="status-filter"
						name="status-filter"
						label="Status"
						className="dh-page__advances-filters__select"
						value={stateFilter}
						defaultValue={stateFilter}
						options={Object.values(SIGN_UP_STATE_FILTERS)}
						onChange={this.onStateFilterChange}
						isClearable={true}
					/>
				</div>

				<List
					items={data}
					loading={loading}
					loadedAll={loadedAll}
					sortField={sortField}
					sortOrder={sortOrder}
					headers={this.getHeaders()}
					renderRecord={this.renderRecord}
					onRecordClicked={this.onRecordClicked}
					onLoadMore={this.onLoadMore}
					noDataTitle={"No sign ups found..."}
					noDataIcon={<Icon.AlertCircle />}
				/>

				<SignUpDetailsModal show={showSignUpDetails} signUpId={selectedSignUp ? selectedSignUp.id : null} onClose={this.onDetailsModalClose} />
			</Page>
		);
	};
}

export default withRouter(SignUps);
