import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import * as Icon from "react-feather";
import c3 from "c3";
import { CSVLink } from "react-csv";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate, parseDate } from "react-day-picker/moment";

import UserService from "../../../services/UserService";
import { AnalyticsService } from "../../../services/AnalyticsService";
import { CsvService } from "../../../services/CsvService";
import GAService from "../../../services/GAService";
import UtilityService from "../../../services/UtilityService";

import Spinners from "../../../components/common/Spinners";
import withLocation from "../../../components/common/WithLocation";
import Action from "../../../components/common/Action";
import DashboardCard from "../../Dashboard/DashboardCard";

import { CHART_COLORS } from "../../../constants/CommonConstants";
import { DASHBOARD_CARDS } from "../../../constants/Dashboard";

import "../../../styles/css/scenes/leadSourceAnalytics.css";

class LeadsByHour extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			start: moment()
				.subtract(30, "days")
				.startOf("day")
				.toDate(),
			end: moment()
				.endOf("day")
				.toDate(),
			totalData: [],
			leadsByHour: [],
			leadsByHourCsv: null
		};
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.refreshData();
	}

	onLocationChanged = location => {
		this.refreshData();
	};

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	handleFromChange = start => {
		// Change the from date and focus the "to" input field
		this.setState({ start });
	};

	handleToChange = end => {
		this.setState({ end }, this.showFromMonth);
	};

	showFromMonth() {
		const { start, end } = this.state;
		if (!start) {
			return;
		}
		if (moment(end).diff(moment(start), "months") < 2) {
			this.to.getDayPicker().showMonth(start);
		}
	}

	handleUpdateDate() {
		this.refreshData();
	}

	async refreshData() {
		const { start, end } = this.state;

		await this.update({ loading: true });
		let leadsByHour = await AnalyticsService.getLeadSourceTimeOfDayAnalytics({ locationId: UserService.getActiveLocation().id, start, end });

		await this.update({ loading: false, leadsByHour });
		this.updateCharts();
	}

	async updateCharts() {
		let { leadsByHour } = this.state;

		if (!leadsByHour) {
			return;
		}

		// Generate a color pattern for other charts
		// if there are more lead sources than colors, generate some random colors!
		let colors = [...CHART_COLORS];
		let length = leadsByHour.length > colors.length ? leadsByHour.length - colors.length : 0;
		colors = UtilityService.generateColorsArray({ colors, length });

		this.generateLeadsByHourChart(colors);
	}

	generateLeadsByHourChart = colors => {
		let { leadsByHour, leadsByHourCsv } = this.state;
		if (!leadsByHour) {
			return;
		}

		let hours = leadsByHour.map(item => item.hour);
		hours.unshift("x");

		let leadSources = Object.keys(leadsByHour[0]);
		delete leadSources["hour"];

		// make an array for each lead source
		let statsPerSource = {};
		for (const item of leadsByHour) {
			for (const key in item) {
				if (key === "hour") {
					continue;
				}

				const count = item[key];
				if (!statsPerSource[key]) {
					statsPerSource[key] = [`${key}`]; // key is what will be shown on the graph
				}
				statsPerSource[key].push(count);
			}
		}

		let columns = [];
		columns.push(hours);
		for (const source in statsPerSource) {
			const stat = statsPerSource[source];
			columns.push(stat);
		}
		this.generateChart(
			"#lead-source-analytics-time-of-day-chart",
			{
				x: "x",
				columns: columns,
				type: "bar"
			},
			undefined,
			{
				pattern: colors
			}
		);

		this.setState({
			leadsByHourCsv
		});
	};

	createRandomColor() {
		var randomColor = "#000000".replace(/0/g, function() {
			return (~~(Math.random() * 16)).toString(16);
		});
		return randomColor;
	}

	generatePieChart(id, columns, color) {
		c3.generate({
			bindto: id,
			data: {
				columns: columns,
				type: "pie"
			},
			color
		});
	}

	generateChart(id, data, axis = {}, color = {}, legend = {}) {
		try {
			c3.generate({
				bindto: id,
				data: data,
				axis: axis,
				size: {
					height: 260
				},
				color: color,
				legend: legend
			});
		} catch (error) {
			console.log(`Error generate a chart - ${error.stack}`);
		}
	}

	renderHeader = () => {
		const { start, end, leadsByHourCsv } = this.state;
		const { cardName } = this.props;
		const modifiers = { start, end };
		const locationName = CsvService.filterActiveLocationName();

		return (
			<>
				<div className="dashboard__card__header dashboard__card__header--full-width">
					<div className="dashboard__card__header__name">{cardName ? cardName : "Leads By Hour (24H)"}</div>
					<div className="dashboard__card__header__actions">
						{leadsByHourCsv && (
							<CSVLink data={leadsByHourCsv} filename={`${locationName}-contact_leads_by_hour_sources.csv`} target="_self">
								<Action label="Download" icon={Icon.Download} className="Common__csv-link">
									<Icon.Download />
								</Action>
							</CSVLink>
						)}
					</div>
				</div>
				<div className="dashboard__card___datepicker">
					<label>Date Range&nbsp;&nbsp;</label>
					<div className="input-group" id="datepicker">
						<div className="InputFromTo">
							<DayPickerInput
								value={start}
								placeholder=" From"
								format="LL"
								formatDate={formatDate}
								parseDate={parseDate}
								dayPickerProps={{
									selectedDays: [start, { from: start, to: end }],
									disabledDays: { after: end },
									toMonth: end,
									modifiers,
									numberOfMonths: 2,
									onDayClick: () => this.to.getInput().focus()
								}}
								onDayChange={start => this.handleFromChange(start)}
							/>{" "}
							<span className="InputFromTo-to">
								<DayPickerInput
									ref={el => (this.to = el)}
									value={end}
									placeholder=" To"
									format="LL"
									formatDate={formatDate}
									parseDate={parseDate}
									dayPickerProps={{
										selectedDays: [start, { from: start, to: end }],
										disabledDays: { before: start },
										modifiers,
										month: start,
										fromMonth: start,
										numberOfMonths: 2
									}}
									onDayChange={this.handleToChange}
								/>
							</span>
						</div>
					</div>{" "}
					<div id="date-update" className="mb-button" onClick={e => this.handleUpdateDate(e)}>
						Update Date
					</div>
				</div>
			</>
		);
	};

	renderBody = () => {
		const { loading } = this.state;
		const { isEnabled, isPermissible } = this.props;

		if (isEnabled && !isEnabled()) {
			return <div className="text-center">Contacts is not enabled. Contact support to get started.</div>;
		}

		if (isPermissible && !isPermissible()) {
			return <div className="text-center">Contact data restricted.</div>;
		}

		if (loading) {
			return (
				<div className="analytics__section__chart__spinner">
					<Spinners type="tail-fade" loading={true} size="60px" />
				</div>
			);
		}

		return (
			<div>
				<div id="lead-source-analytics-time-of-day-chart" />
			</div>
		);
	};

	render() {
		return (
			<DashboardCard className={DASHBOARD_CARDS.leadsByHour.className} height={DASHBOARD_CARDS.leadsByHour.h}>
				{this.renderHeader()}

				{this.renderBody()}
			</DashboardCard>
		);
	}
}

export default withRouter(withLocation(LeadsByHour));
