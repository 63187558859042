import React from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import * as Icon from "react-feather";
import ContentLoader from "react-content-loader";
import { Link } from "react-router-dom";

import ToastService from "../../services/ToastService";
import UserService from "../../services/UserService";
import DashboardService from "../../services/DashboardService";

import Page from "../../components/common/Page";
import Header from "../../components/common/Header";
import Action from "../../components/common/Action";
import UnsavedChanges from "../../components/common/UnsavedChanges";
import AddDashboardCardModal from "./AddDashboardCardModal";
import Input from "../../components/common/Input";

import { DASHBOARD_CARDS } from "../../constants/Dashboard";

import "../../../node_modules/react-grid-layout/css/styles.css";
import "../../../node_modules/react-resizable/css/styles.css";

const ResponsiveGridLayout = WidthProvider(Responsive);

class ManageDashboard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			dashboard: {},

			loading: false,
			saving: false,
			changesMade: false,

			onShowAddCardModal: false
		};
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	componentDidMount() {
		this.resetComponent();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.dashboardId !== this.props.dashboardId) {
			this.resetComponent();
		}
	}

	resetComponent = async () => {
		let dashboardId = this.getDashboardId();

		this.update({ loading: true });

		if (this.isCreateMode()) {
			return;
		}

		let dashboard = await DashboardService.fetchDashboard({
			locationId: UserService.getActiveLocation().id,
			dashboardId: dashboardId
		});

		if (!dashboard) {
			ToastService.error("Error fetching dashboards");
			this.update({ loading: false });

			return;
		}

		if (dashboard) {
			dashboard.layout = JSON.parse(dashboard.layout);
		}

		this.update({ dashboard, loading: false, changesMade: false });
	};

	isViewMode() {
		return this.props.dashboardId;
	}

	isCreateMode() {
		let dashboardId = this.getDashboardId();

		if (dashboardId === "create") {
			return true;
		}

		return false;
	}

	getDashboardId() {
		if (typeof this.props.dashboardId !== "undefined") {
			return this.props.dashboardId;
		}

		if (this.props.match && this.props.match.params) {
			return this.props.match.params.dashboardId;
		}

		return false;
	}

	onShowAddCardModal = () => {
		this.update({ onShowAddCardModal: true });
	};

	onHideAddCardModal = () => {
		this.update({ onShowAddCardModal: false });
	};

	onAddCard = async cardIds => {
		let { dashboard } = this.state;

		dashboard.layout = [...dashboard.layout];

		for (let i = 0; i < cardIds.length; i++) {
			const cardId = cardIds[i];

			let card = DASHBOARD_CARDS[cardId];

			dashboard.layout.push({
				i: card.id,
				x: 0,
				y: Infinity, // puts it at the bottom
				w: card.w,
				h: card.h
			});
		}

		await this.update({ dashboard, changesMade: true });
	};

	onLayoutChange = layout => {
		let { dashboard } = this.state;

		let changesMade = JSON.stringify(dashboard.layout) !== JSON.stringify(layout);

		dashboard.layout = layout;

		this.update({ dashboard, changesMade });
	};

	handleGenericEventHandler = event => {
		let { loading, dashboard } = this.state;
		if (loading) {
			return;
		}
		dashboard.name = event.target.value;

		this.update({ dashboard, changesMade: true });
	};

	onCancelChanges = () => {
		this.resetComponent();
	};

	onConfirmDefault = async () => {
		let { dashboard } = this.state;

		let dashboardId = this.getDashboardId();

		let result = await DashboardService.update({ locationId: UserService.getActiveLocation().id, dashboardId, isDefault: true });

		if (!result) {
			ToastService.error(`Failed to update ${dashboard ? dashboard.name : ""} dashboard. Please try again.`);
			return;
		}

		ToastService.info(`${dashboard ? dashboard.name : ""} dashboard set as default.`);
		this.resetComponent();
	};

	onSave = () => {
		let { dashboard } = this.state;

		this.update({ saving: true });

		if (this.isCreateMode()) {
			return;
		}

		let updated = DashboardService.update({
			locationId: UserService.getActiveLocation().id,
			dashboardId: dashboard.id,
			name: dashboard.name,
			isDefault: dashboard.is_default,
			layout: dashboard.layout
		});

		if (!updated) {
			ToastService.error("Error updating Dashboard.");
			this.update({ saving: false });
			return;
		}

		this.update({ saving: false, changesMade: false });
		ToastService.info("Dashboard updated.");
	};

	isAllowedUpdate = () => {
		let user = UserService.get();
		let allowUpdate = !this.isViewMode() && user.GroupPermission.update_reports;
		return allowUpdate;
	};

	renderLoading = () => {
		let { dashboard, loading } = this.state;
		let allowUpdate = this.isAllowedUpdate();

		if (!loading && dashboard && dashboard.layout) {
			return null;
		}

		return (
			<>
				<div className={`manage-dashboard__loading ${allowUpdate ? "" : "manage-dashboard__loading--view"}`}>
					<ContentLoader viewBox="0 0 100% 550" height={580} width={"100%"}>
						<rect x="0" y="0" rx="5" ry="5" width="100%" height="100" />
						<rect x="0" y="120" rx="5" ry="5" width="100%" height="100" />
						<rect x="0" y="240" rx="5" ry="5" width="100%" height="100" />
						<rect x="0" y="360" rx="5" ry="5" width="100%" height="100" />
						<rect x="0" y="480" rx="5" ry="5" width="100%" height="100" />
					</ContentLoader>
				</div>
			</>
		);
	};

	renderHeaderTitle = () => {
		let { dashboard } = this.state;

		if (!this.isViewMode()) {
			return (
				<>
					<Link to="/dashboards">
						<Icon.ArrowLeftCircle size={28} className="manage-dashboard__back" />
					</Link>
					Manage Dashboard - {dashboard.name}
				</>
			);
		}

		return <>{dashboard.name}</>;
	};

	renderHeader = () => {
		let { dashboard, loading, changesMade } = this.state;
		let dashboardId = this.getDashboardId();

		let allowUpdate = this.isAllowedUpdate();

		if (loading || !dashboard || !dashboard.layout) {
			return (
				<Header title={this.renderHeaderTitle()} center={true}>
					{allowUpdate && (
						<ContentLoader viewBox="0 0 70 30" height={30} width={70}>
							<rect x="0" y="0" rx="5" ry="5" width="70" height="30" />
						</ContentLoader>
					)}
				</Header>
			);
		}

		return (
			<Header title={this.renderHeaderTitle()} center={true}>
				{allowUpdate && (
					<>
						{!this.isCreateMode() && !changesMade && (
							<Action id={`default-${dashboardId}`} label="Set as Default" icon={Icon.CheckCircle} onClick={() => this.onConfirmDefault()} />
						)}
						<Action id="add-card" label="Add Card" icon={Icon.Plus} onClick={() => this.onShowAddCardModal()} />
					</>
				)}
			</Header>
		);
	};

	renderBody = () => {
		let { dashboard, loading } = this.state;

		let allowUpdate = this.isAllowedUpdate();

		if (loading || !dashboard || Object.keys(dashboard).length < 1 || !dashboard.layout) {
			return null;
		}

		return (
			<>
				{allowUpdate && (
					<div className="manage-dashboard__layout">
						<Input
							id="name"
							name="name"
							type="name"
							label={"Dashboard Name"}
							placeholder={"Dashboard name..."}
							value={dashboard.name}
							onChange={this.handleGenericEventHandler}
						/>
					</div>
				)}
				<div className="manage-dashboard__layout">
					<ResponsiveGridLayout
						className=""
						layouts={{
							lg: dashboard.layout
						}}
						breakpoints={{ lg: 2000, md: 996, sm: 768, xs: 480, xxs: 0 }}
						cols={{ lg: 1, md: 1, sm: 1, xs: 1, xxs: 1 }}
						rowHeight={50}
						isResizable={false}
						isDraggable={allowUpdate}
						onLayoutChange={this.onLayoutChange}
						margin={[20, 20]}
						style={{
							minWidth: 600,
							width: "100%",
							maxWidth: 1200,
							paddingBottom: 200,
							marginBottom: 200
						}}
					>
						{dashboard.layout.map((dashboardItem, index) => {
							let cardInfo = DASHBOARD_CARDS[dashboardItem.i];

							if (!cardInfo) {
								console.log(`${dashboardItem.i} dashboard item not found.`);
								return null;
							}

							let Card = cardInfo.component;

							return (
								<div key={dashboardItem.i} className={`manage-dashboard__card ${!this.isViewMode() ? "manage-dashboard__card--manage" : ""}`}>
									{allowUpdate && <div className={`dashboard__card--overlay `} />}

									<Card {...cardInfo.props} />

									{allowUpdate && (
										<Action
											className="manage-dashboard__card__remove"
											onClick={() => {
												dashboard.layout = [...dashboard.layout];
												dashboard.layout.splice(index, 1);
												this.update({ dashboard, changesMade: true });
											}}
											icon={Icon.Trash2}
											label="Remove Card"
										/>
									)}
								</div>
							);
						})}
					</ResponsiveGridLayout>
				</div>
			</>
		);
	};

	render = () => {
		let { dashboard, onShowAddCardModal, changesMade, saving } = this.state;

		let allowUpdate = this.isAllowedUpdate();

		return (
			<Page className="manage-dashboard">
				{this.renderHeader()}

				{this.renderLoading()}
				{this.renderBody()}

				<AddDashboardCardModal show={onShowAddCardModal} layout={dashboard.layout} onHide={this.onHideAddCardModal} onAddCard={this.onAddCard} />

				{changesMade && allowUpdate && (
					<UnsavedChanges>
						<div className="unsaved-changes__buttons">
							<div className="mb-button" onClick={() => this.onSave()}>
								{saving ? "Saving" : "Save"}
							</div>
							<div className="mb-button mb-button--cancel" onClick={() => this.onCancelChanges()}>
								Cancel
							</div>
						</div>
					</UnsavedChanges>
				)}
			</Page>
		);
	};
}

export default ManageDashboard;
