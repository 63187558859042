import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import c3 from "c3";
import * as Icon from "react-feather";
import { CSVLink } from "react-csv";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate, parseDate } from "react-day-picker/moment";

import UserService from "../../../services/UserService";
import { CsvService } from "../../../services/CsvService";
import { AnalyticsService } from "../../../services/AnalyticsService";
import LocationService from "../../../services/LocationService";
import ReviewService from "../../../services/ReviewService";

import Spinners from "../../../components/common/Spinners";
import withLocation from "../../../components/common/WithLocation";
import Action from "../../../components/common/Action";
import DashboardCard from "../../Dashboard/DashboardCard";

import { REVIEW_SITES, REVIEW_SITE_IDS_BY_NAME } from "../../../constants/ReviewSitesConstants";
import { DASHBOARD_CARDS } from "../../../constants/Dashboard";

import "../../../App.css";
import "react-day-picker/lib/style.css";
import "../../../styles/css/scenes/analytics.css";

class ReviewSitePerformance extends Component {
	constructor(props) {
		super(props);

		this.state = {
			noSitesFound: true,
			startDate: moment()
				.subtract(14, "days")
				.toDate(),
			endDate: moment().toDate(),
			siteId: REVIEW_SITE_IDS_BY_NAME.google,
			loading: true,
			reviewPerformaceCsv: null
		};
	}

	componentDidMount() {
		this.fetchAllReviewSites();
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	handleUpdateDate = event => {
		event.preventDefault();
		event.stopPropagation();

		this.updateData();
	};

	onLocationChanged = async () => {
		await this.update({
			startDate: moment()
				.subtract(14, "days")
				.toDate(),
			endDate: moment().toDate(),
			noSitesFound: true,
			siteId: REVIEW_SITE_IDS_BY_NAME.google, // support other review sites?
			loading: true
		});
		this.fetchAllReviewSites();
	};

	/**
	 * Get all the review sites available from database. We need this as the review_sites
	 * fetched from location table does not have review site name
	 */
	async fetchAllReviewSites() {
		try {
			const allReviewSitesResponse = await ReviewService.getAllReviewSites();

			this.getReviewSitesForLocation(allReviewSitesResponse);
		} catch (error) {
			this.update({ loading: false });
			console.log(error);
		}
	}
	/**
	 *
	 * @param {object} allReviewSites
	 * Get all the review sites configured for a location
	 */
	async getReviewSitesForLocation(allReviewSites) {
		try {
			const locationId = UserService.getActiveLocation().id;

			let data = await LocationService.fetchLocation(locationId);

			const reviewSites = [];

			if (data) {
				const reviewSitesForLocation = JSON.parse(data.review_sites || "[]");
				allReviewSites.forEach(aReviewSite => {
					const found = reviewSitesForLocation.find(aReviewSiteForLocation => aReviewSiteForLocation.site_id === aReviewSite.id);
					if (found) {
						reviewSites.push(aReviewSite);
					}
				});
			}

			if (reviewSites.length === 0) {
				this.update({ noSitesFound: true, loading: false });
				return;
			}

			const google = reviewSites.find(aReviewSite => aReviewSite.id === REVIEW_SITES.google.id);

			await this.update({ reviewSites, siteId: google ? google.id : reviewSites[0].id, noSitesFound: false });

			this.updateData();
		} catch (error) {
			console.log(error);
			this.update({ loading: false });
		}
	}

	async getDailyData() {
		try {
			const { startDate, endDate, siteId } = this.state;

			let data = await AnalyticsService.fetchReviewInsights({ locationId: UserService.getActiveLocation().id, startDate, endDate, siteId });

			return data;
		} catch (error) {
			console.log(error);
		}
	}
	async getMonthlyData() {
		try {
			const { startDate, endDate, siteId } = this.state;

			let data = await AnalyticsService.fetchMonthlyReviewInsites({ locationId: UserService.getActiveLocation().id, startDate, endDate, siteId });

			return data;
		} catch (error) {
			console.log(error);
		}
	}

	/**
	 * update review performance chart
	 */
	updateData = async () => {
		try {
			const { startDate, endDate } = this.state;
			this.update({ loading: true });
			let insights = [],
				daysOrMonths = ["x"],
				invites = ["invites"],
				clicks = ["clicks"],
				reviews = ["reviews"],
				replies = ["replies"],
				time = "";
			if (moment(endDate).diff(moment(startDate), "days") > 90) {
				insights = await this.getMonthlyData();
				time = "Month";
			} else {
				insights = await this.getDailyData();
				time = "Day";
			}
			insights.invites.forEach(anInvite => {
				daysOrMonths.push(anInvite.time);
				invites.push(anInvite.invites);
			});

			insights.clicks.forEach(element => {
				clicks.push(element.clicks);
			});

			insights.reviews.forEach(element => {
				reviews.push(element.reviews);
			});

			insights.replies.forEach(element => {
				replies.push(element.replies);
			});

			let dateColumn = [...daysOrMonths];
			dateColumn[0] = "Date";
			let csvData = [dateColumn, invites, clicks, reviews, replies];
			csvData = csvData[0].map((col, i) => csvData.map(row => row[i])); // transpose the data

			this.update({
				reviewPerformaceCsv: csvData,
				loading: false
			});

			c3.generate({
				bindto: "#review-performance-analytics-chart",
				data: {
					x: "x",
					columns: [daysOrMonths, invites, clicks, reviews, replies],
					type: "bar"
				},
				bar: {
					width: {
						ratio: 0.5 // this makes bar width 50% of length between ticks
					}
					// or
					//width: 100 // this makes bar width 100px
				},
				axis: {
					x: {
						label: { text: time, position: "outer-bottom" },
						type: "timeseries",
						tick: {
							format: function(x) {
								//let monthString = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
								//return monthString[x.getMonth()];
								if (time === "Day") {
									return "" + x.getFullYear() + "-" + (x.getMonth() + 1) + "-" + x.getDate();
								}
								let monthString = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
								return monthString[x.getMonth()] + " " + x.getFullYear();
							},
							rotate: window.innerWidth > 768 ? 0 : 75,
							multiline: false
						}
					},
					y: {
						label: { text: "Invites", position: "outer-top" }
					}
				},
				size: {
					height: 260
				}
			});
		} catch (error) {
			// Make an API call to get all the chats for the user's location
			console.log(error);
			this.update({ loading: false });
		}
	};

	showFromMonth() {
		const { startDate, endDate } = this.state;
		if (!startDate) {
			return;
		}

		if (moment(endDate).diff(moment(startDate), "months") < 2) {
			this.to.getDayPicker().showMonth(startDate);
		}
	}

	handleFromChange = startDate => {
		// Change the from date and focus the "to" input field
		this.update({ startDate });
	};

	handleToChange = endDate => {
		this.update({ endDate }, this.showFromMonth);
	};

	renderDatePicker = () => {
		const { endDate, startDate } = this.state;
		const modifiers = { start: startDate, end: endDate };

		return (
			<div className="dashboard__card___datepicker">
				<label>Date Range&nbsp;&nbsp;</label>
				<div className="input-group" id="datepicker">
					<div className="InputFromTo">
						<DayPickerInput
							value={startDate}
							placeholder=" From"
							format="LL"
							formatDate={formatDate}
							parseDate={parseDate}
							dayPickerProps={{
								selectedDays: [startDate, { from: startDate, to: endDate }],
								disabledDays: { after: endDate },
								toMonth: endDate,
								modifiers,
								numberOfMonths: 2,
								onDayClick: () => this.to.getInput().focus()
							}}
							onDayChange={this.handleFromChange}
						/>{" "}
						<span className="InputFromTo-to">
							<DayPickerInput
								ref={el => (this.to = el)}
								value={endDate}
								placeholder=" To"
								format="LL"
								formatDate={formatDate}
								parseDate={parseDate}
								dayPickerProps={{
									selectedDays: [startDate, { from: startDate, to: endDate }],
									disabledDays: { before: startDate },
									modifiers,
									month: startDate,
									fromMonth: startDate,
									numberOfMonths: 2
								}}
								onDayChange={this.handleToChange}
							/>
						</span>
					</div>
				</div>{" "}
				<button id="date-update" className="mb-button" onClick={this.handleUpdateDate}>
					Update Date
				</button>
			</div>
		);
	};

	renderHeader = () => {
		const { reviewPerformaceCsv } = this.state;
		const { cardName } = this.props;

		const locationName = CsvService.filterActiveLocationName();

		return (
			<>
				<div className="dashboard__card__header dashboard__card__header--full-width">
					<div className="dashboard__card__header__name">{cardName ? cardName : "Review Performance"}</div>
					<div className="dashboard__card__header__actions">
						{reviewPerformaceCsv && (
							<CSVLink data={reviewPerformaceCsv} filename={`${locationName}-review_performance.csv`} target="_self">
								<Action label="Download" icon={Icon.Download} className="Common__csv-link">
									<Icon.Download />
								</Action>
							</CSVLink>
						)}
					</div>
				</div>

				{this.renderDatePicker()}
			</>
		);
	};

	renderBody = () => {
		const { noSitesFound, loading } = this.state;
		const { isEnabled, isPermissible } = this.props;

		if (isEnabled && !isEnabled()) {
			return <div className="text-center">Reviews is not enabled. Contact support to get started.</div>;
		}

		if (isPermissible && !isPermissible()) {
			return <div className="text-center">Review data is restricted.</div>;
		}

		if (loading) {
			return (
				<div className="analytics__section__chart__spinner">
					<Spinners type="tail-fade" loading={true} size="60px" />
				</div>
			);
		}

		if (noSitesFound) {
			return (
				<div className="text-center">
					Sorry, no data was found{" "}
					<span role="img" aria-label="sad-face">
						😞
					</span>
				</div>
			);
		}

		return (
			<div>
				<div id="review-performance-analytics-chart" />
			</div>
		);
	};

	render() {
		return (
			<DashboardCard className={DASHBOARD_CARDS.reviewSitePerformance.className} height={DASHBOARD_CARDS.reviewSitePerformance.h}>
				{this.renderHeader()}
				{this.renderBody()}
			</DashboardCard>
		);
	}
}

export default withRouter(withLocation(ReviewSitePerformance));
