import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import * as Icon from "react-feather";
import moment from "moment";

import List from "../../../components/common/List";

import GAService from "../../../services/GAService";
import { DATE_FORMAT, SHORT_TIME_FORMAT, SORT_ORDER } from "../../../constants/CommonConstants";
import { FAILED_MESSAGES_COLUMNS } from "../../../constants/AnalyticsConstants";

import "../../../styles/css/components/commons/react-table.css";

class FailedMessagesTable extends Component {
	state = {
		sortField: FAILED_MESSAGES_COLUMNS.sent_at.sortField,
		sortOrder: SORT_ORDER.asc
	};

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
	}

	getHeaders = () => {
		let headers = {
			items: FAILED_MESSAGES_COLUMNS,
			sortBy: this.sortBy
		};

		return headers;
	};

	sortBy = async sortField => {
		let { sortOrder } = this.state;
		sortOrder = sortOrder === SORT_ORDER.asc ? SORT_ORDER.desc : SORT_ORDER.asc;
		await this.update({ sortField, sortOrder });

		if (this.props.sortBy) {
			this.props.sortBy(sortField, sortOrder);
		}
	};

	onRecordClicked = () => {
		// nothing for now
	};

	renderRecord = recordData => {
		try {
			return [
				recordData.contact_name,
				recordData.contact_phone,
				recordData.content,
				<div>{moment(recordData.sent_at).format(DATE_FORMAT) + " at " + moment(recordData.sent_at).format(SHORT_TIME_FORMAT)}</div>,
				recordData.sender_user_name,
				recordData.tags
			];
		} catch (error) {
			console.log(error);
		}
		return null;
	};

	render() {
		const { messages, loading } = this.props;
		let { sortField, sortOrder } = this.state;

		return (
			<List
				items={messages}
				loading={loading}
				loadedAll={true}
				sortField={sortField}
				sortOrder={sortOrder}
				headers={this.getHeaders()}
				renderRecord={this.renderRecord}
				onRecordClicked={this.onRecordClicked}
				noDataTitle="No data has been found..."
				noDataIcon={<Icon.AlertCircle />}
				maxHeight={220}
				height={220}
			/>
		);
	}
}
export default withRouter(FailedMessagesTable);
