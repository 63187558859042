import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Redirect } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import UserService from "../../services/UserService";
import GAService from "../../services/GAService";
import { AnalyticsService } from "../../services/AnalyticsService";

import Spinners from "../../components/common/Spinners";
import Insight from "../../components/common/Insight";
import Page from "../../components/common/Page";
import Header from "../../components/common/Header";
import Filters from "../../components/common/Filters";
import withLocation from "../../components/common/WithLocation";
import DashboardCard from "../../scenes/Dashboard/DashboardCard";

import "../../App.css";
import "../../styles/css/scenes/review-insights.css";

const FILTERS = {
	thisWeek: {
		id: "thisWeek",
		display: "This Week",
		value: "this_week",
		label: "thisWeek",
		order: 1
	},
	thisMonth: {
		id: "thisMonth",
		display: "This Month",
		value: "this_month",
		label: "thisMonth",
		order: 2
	},
	lastMonth: {
		id: "lastMonth",
		display: "Last Month",
		value: "last_month",
		label: "lastMonth",
		order: 3
	},
	allTime: {
		id: "allTime",
		display: "All Time",
		value: "all_time",
		label: "allTime",
		order: 4
	}
};

class ReviewsInsights extends Component {
	constructor(props) {
		super(props);
		this.state = {
			locationData: null,
			positiveInsights: [],
			negativeInsights: [],
			showReviewsModal: false,
			reviewsFromInsight: [],
			reportType: FILTERS.allTime.value,
			loading: false,
			selectedFilter: FILTERS.allTime.id
		};
	}

	async componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });

		console.log("Icons made by: https://www.flaticon.com/authors/freepik and licensed by: http://creativecommons.org/licenses/by/3.0/");
		await this.update({ loading: true });

		await this.refreshPositiveInsights();
		await this.refreshNegativeInsights();

		this.update({ loading: false });
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	onLocationChanged = location => {
		this.refreshPositiveInsights();
		this.refreshNegativeInsights();
	};

	async refreshPositiveInsights() {
		let user = UserService.get();

		if (!user) {
			return;
		}

		let data = await AnalyticsService.fetchReviewInsightData({
			location: UserService.getActiveLocation().id,
			sentiment: 1,
			reportType: this.state.reportType
		});

		if (!data) {
			return;
		}

		this.setState({ positiveInsights: data });
	}

	async refreshNegativeInsights() {
		let user = UserService.get();

		if (!user) {
			return;
		}

		let location = UserService.getActiveLocation();

		let data = await AnalyticsService.fetchReviewInsightData({
			location: location.id,
			sentiment: -1,
			reportType: this.state.reportType
		});

		this.setState({ negativeInsights: data });
	}

	async refreshDateRange(reportTypeEnum) {
		await this.update({ reportType: reportTypeEnum });

		await this.refreshPositiveInsights();
		await this.refreshNegativeInsights();
	}

	onFilterSelect = async item => {
		await this.update({ loading: true, selectedFilter: item.id });
		await this.refreshDateRange(FILTERS[item.id].value);
		await this.update({ loading: false });
	};

	isFilterSelected = item => {
		return item === this.state.selectedFilter;
	};

	getFilters = () => {
		let items = Object.keys(FILTERS)
			.map(item => {
				return { id: FILTERS[item].id, value: FILTERS[item].display, order: FILTERS[item].order };
			})
			.sort((a, b) => a.order - b.order);
		let filters = {
			statuses: {
				title: "",
				items: items,
				onClick: this.onFilterSelect,
				isSelected: this.isFilterSelected
			}
		};
		return filters;
	};

	renderLoading = () => {
		return (
			<DashboardCard>
				<div className="analytics__section__chart__spinner">
					<Spinners type="tail-fade" loading={true} size="60px" />
				</div>
			</DashboardCard>
		);
	};

	renderBody = () => {
		let { negativeInsights, positiveInsights } = this.state;

		if (this.state.loading) {
			return this.renderLoading();
		}

		if (negativeInsights.length === 0 && positiveInsights.length === 0) {
			return (
				<DashboardCard>
					<h3>
						Sorry, no data was found{" "}
						<span role="img" aria-label="sad-face">
							😞
						</span>
					</h3>
				</DashboardCard>
			);
		}

		return (
			<DashboardCard>
				<div className="ReviewsInsights__positiveInsights--title">
					<h3>
						Here's what your customers are talking about the most &nbsp;&nbsp;
						<i data-tip data-for="review-insights-tooltip" className="ReviewInsights__information--icon fa fa-question-circle" aria-hidden="true" />
						<ReactTooltip className="mb-react-tooltip" arrowColor="#333" id="review-insights-tooltip" type="info" effect="solid" place="right" multiline="true">
							This page shows you the most <br /> frequently used words customers <br /> have used to describe your business, <br /> and also throws light on
							their <br /> associated sentiments!
						</ReactTooltip>
					</h3>
				</div>
				{positiveInsights.length > 0
					? positiveInsights.map(insight => {
							return <Insight key={insight.id} baseInsight={insight} sentiment="1" />;
					  })
					: null}
				{negativeInsights.length > 0
					? negativeInsights
							.slice(0)
							.reverse()
							.map(insight => {
								return <Insight key={insight.id} baseInsight={insight} sentiment="-1" />;
							})
					: null}
			</DashboardCard>
		);
	};

	render() {
		let { loading } = this.state;

		let location = UserService.getActiveLocation();

		if (!location) {
			// No locations have been configured
			return <Redirect to="/dashboard" />;
		}

		return (
			<Page>
				<Header title="Insights" />

				<Filters filters={this.getFilters()} />

				<div className="dh-page__body">{this.renderBody()}</div>
			</Page>
		);
	}
}

export default withRouter(withLocation(ReviewsInsights));
