import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import queryString from "query-string";
import config from "../../config/app/web-app.config";
import { withTranslation } from "react-i18next";

import ContentLoader from "react-content-loader";
import { Route, Switch } from "react-router-dom";

import PaymentService from "../../services/PaymentService";
import InvoiceList from "./Invoices/InvoiceList";
import PaymentRequestList from "./PaymentRequests/PaymentRequestList";
import VersionService from "../../services/VersionService";
import GAService from "../../services/GAService";
import SupportChatService from "../../services/SupportChatService";
import UserService from "../../services/UserService";
import NotificationService from "../../services/NotificationService";

import Alert from "../../components/common/Alert";
import Page from "../../components/common/Page";
import Header from "../../components/common/Header";
import Tabs from "../../components/common/Tabs";
import withLocation from "../../components/common/WithLocation";
import LandingPage from "../LandingPage/LandingPage";

import { PAYMENT_TABS } from "../../constants/Payments";

import "./payments.css";

class Payments extends Component {
	constructor(props) {
		super(props);

		let tab = this.props.location.pathname === PAYMENT_TABS.invoices.route ? PAYMENT_TABS.invoices.id : PAYMENT_TABS.paymentRequests.id;

		this.state = {
			loading: true,
			enabled: false,
			connected: false,
			error: false,
			errorDescription: "",
			tab: tab,
			clientId: config.STRIPE.CLIENT_ID,
			showBlockedAlert: false,
			locationId: null
		};
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	onLocationChanged = async location => {
		await this.resetComponent();
	};

	onTabSelect = async tab => {
		await this.update({ tab: tab.id });
		this.props.history.push(PAYMENT_TABS[tab.id].route);
	};

	async componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		await this.resetComponent();

		NotificationService.subscribe("onUrlChange", ({ location, action }) => {
			let tab = location.pathname === PAYMENT_TABS.invoices.route ? PAYMENT_TABS.invoices.id : PAYMENT_TABS.paymentRequests.id;

			this.setState({
				tab
			});
		});
	}

	resetComponent = async () => {
		// We do this fetch for the latest location data since we check the UserService.getActiveLocation() object to see if they have connected their stripe account.
		// This won't work for Super admin acting on behalf of companies they don't belong to
		await VersionService.updateUserData();
		let location = UserService.getActiveLocation();

		await this.update({
			loading: true,
			enabled: false,
			connected: false,
			clientId: config.STRIPE.CLIENT_ID,
			error: false,
			errorDescription: "",
			showBlockedAlert: false,
			locationId: location.id
		});

		let enabled = PaymentService.isPaymentsEnabled();
		await this.update({ enabled: enabled });
		if (!enabled) {
			await this.update({ loading: false });
			return;
		}

		let connected = location.stripe_account_id && location.stripe_account_id.length > 0;
		if (connected) {
			await this.update({
				connected: true
			});
		} else {
			await this.confirmNewStripeAccount();
		}

		await this.update({ loading: false });
	};

	async confirmNewStripeAccount() {
		let { t } = this.props;
		let location = UserService.getActiveLocation();

		if (this.props.location && this.props.location.search) {
			if (this.props.location && this.props.location.search) {
				let params = queryString.parse(this.props.location.search);

				if (params.code) {
					await PaymentService.confirmStripeAccount(location.id, params.code);

					await this.update({
						connected: true
					});
				}

				if (params.error) {
					let errorDescription = t("Stripe Error: {{stripeError}}. Please contact your DemandHub Representative.", { stripeError: params.error_description });

					await this.update({
						error: true,
						errorDescription
					});
				}
			}
		}
	}

	onTriggerStripe = () => {
		let { clientId } = this.state;
		let savedUrl = window.location.href;
		window.open(`https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${clientId}&scope=read_write&redirect_uri=${savedUrl}`);
	};

	onTriggerContactUsModal = () => {
		this.update({ showBlockedAlert: true });
	};

	onBlockedFeatureAlertClose = async confirm => {
		if (confirm) {
			SupportChatService.showNewMessage();
		}

		await this.update({
			showBlockedAlert: false
		});
	};

	onCloseStripeError = () => {
		this.update({ error: false, errorDescription: "" });
	};

	render() {
		let { connected, enabled, tab, error, errorDescription, loading, showBlockedAlert, locationId } = this.state;
		let { t } = this.props;

		if (!this.props.match.params.tab) {
			return <Redirect to="/payments/payment-request" />;
		}

		if (loading) {
			return (
				<Page>
					<div className="payments">
						<ContentLoader height={"100%"} width={"100%"}>
							{/* The title */}
							<rect x="40" y="30" rx="5" ry="5" width="25%" height="40" />

							{/* The search */}
							<rect x="40" y="90" rx="5" ry="5" width="25%" height="40" />

							{/* The tabs */}
							<rect x="40" y="150" rx="5" ry="5" width="50%" height="40" />

							{/* The table */}
							<rect x="40" y="210" rx="5" ry="5" width="90%" height="40" />
							<rect x="40" y="260" rx="5" ry="5" width="90%" height="40" />
							<rect x="40" y="310" rx="5" ry="5" width="90%" height="40" />
							<rect x="40" y="360" rx="5" ry="5" width="90%" height="40" />
							<rect x="40" y="410" rx="5" ry="5" width="90%" height="40" />
							<rect x="40" y="460" rx="5" ry="5" width="90%" height="40" />
							<rect x="40" y="510" rx="5" ry="5" width="90%" height="40" />
							<rect x="40" y="560" rx="5" ry="5" width="90%" height="40" />
							<rect x="40" y="610" rx="5" ry="5" width="90%" height="40" />
						</ContentLoader>
					</div>
				</Page>
			);
		}

		return (
			<Page>
				{(!connected || !enabled) && (
					<LandingPage
						title={t("DemandHub Payments")}
						text={t("Collect payments via SMS for your business, securely and contact free.")}
						imageUrl="https://cdn.demandhub.co/mobile-app/customers.png"
						buttonText={t("Get Started")}
						onGetStartedClicked={enabled ? this.onTriggerStripe : this.onTriggerContactUsModal}
						beta={false}
					/>
				)}
				{enabled && connected && (
					<>
						<Header title={t("Payments")} />
						<Tabs onSelect={this.onTabSelect} selected={tab} routing={true} tabs={Object.values(PAYMENT_TABS)}>
							<Switch>
								<Route key="payment-reqeuest" path={PAYMENT_TABS.paymentRequests.route}>
									<PaymentRequestList locationId={locationId} {...this.props} />
								</Route>

								<Route key="invoices" path={PAYMENT_TABS.invoices.route}>
									<InvoiceList {...this.props} />
								</Route>
							</Switch>
						</Tabs>
					</>
				)}

				<Alert type="info" show={error} title={t("Stripe Error")} confirm={t("Okay")} onClose={this.onCloseStripeError}>
					{errorDescription}
				</Alert>
				<Alert type="info" show={showBlockedAlert} title={t("Upgrade Subscription")} confirm={t("Chat with us")} onClose={this.onBlockedFeatureAlertClose}>
					{t("To enable Payments, please contact support@demandhub.co or chat with us.")}
				</Alert>
			</Page>
		);
	}
}

export default withRouter(withLocation(withTranslation(null, { withRef: true })(Payments)));
