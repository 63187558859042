// Libraries
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { withTranslation } from "react-i18next";
import posed from "react-pose";

// Services
import GAService from "../../services/GAService";
import NotificationService from "../../services/NotificationService";
import LocationService from "../../services/LocationService";
import ReengagementService from "../../services/ReengagementService";

// Components
import LegacyPage from "../../components/common/LegacyPage";
import NotFound from "../../components/layouts/NotFound";

// Route Components
import AnalyticsSummary from "./AnalyticsSummary";
import LocationAnalytics from "./Location/LocationAnalytics";
import ReviewSitesAnalytics from "./ReviewSites/ReviewSitesAnalytics";
import ReviewsInsights from "./ReviewsInsights";
import GmbLocationStatsAnalytics from "./GmbLocationStatsAnalytics";
import MessengerAnalytics from "./Messenger/MessengerAnalytics";
import CampaignAnalytics from "./CampaignAnalytics";
import ContactAnalytics from "./Contacts/ContactAnalytics";
import UserService from "../../services/UserService";
import KeywordAnalytics from "./KeywordAnalytics";
import WorkflowService from "../../services/WorkflowService";
import WidgetAnalyticsPage from "./WidgetAnalyticsPage";
import ConversionAnalytics from "./ConversionAnalytics";
import Leaderboard from "../Leaderboard/Leaderboard";

// Styles
import "../../scenes/MessengerBeta/Navbar/navbar.css";
import "../../styles/css/scenes/settings.css";

const Container = posed.div({
	visible: {
		y: 0,
		opacity: 1
	},
	hidden: {
		y: 0,
		opacity: 0
	}
});

class Analytics extends Component {
	constructor(props) {
		super(props);

		this.state = {
			currentRoute: ""
		};
	}

	async componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });

		let currentRoute = this.getDefaultRoute();

		await this.update({ currentRoute });

		if (this.props.location.pathname === "/analytics") {
			this.props.history.push(currentRoute);
		}

		NotificationService.subscribe("onUrlChange", async ({ location }) => {
			let newRoute = location.pathname === "/analytics" ? this.getDefaultRoute() : location.pathname;

			if (location.pathname !== this.state.currentRoute) {
				await this.update({ currentRoute: newRoute });
				this.props.history.push(newRoute);
			}
		});
	}

	getDefaultRoute = () => {
		if (ReengagementService.canViewReengagements()) {
			return "/analytics/general/conversions";
		} else if (LocationService.isMessengerPermissible()) {
			return "/analytics/general/widgets";
		} else if (LocationService.isReviewsEnabled()) {
			return "/analytics/reviews/summary";
		}
	};

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	onClick = ({ route }) => {
		this.setState({ currentRoute: route });
		this.props.history.push(route);
	};

	MenuItem = ({ name, route, isEnabled }) => {
		let { currentRoute } = this.state;

		if (!isEnabled) {
			return;
		}

		let isSelected = currentRoute === route;

		let styles = ["settings__navbar__list__item"];

		if (isSelected) {
			styles.push("settings__navbar__list__item--active");
		}

		return (
			<div className={styles.join(" ")} onClick={() => this.onClick({ route })}>
				{name}
			</div>
		);
	};

	render() {
		let { t } = this.props;

		return (
			<Container className="settings" initialPose="hidden" pose="visible">
				<div className="settings__navbar">
					<div className="settings__navbar__header">GENERAL</div>
					<div className="settings__navbar__list">
						<this.MenuItem name="Conversions" route="/analytics/general/conversions" isEnabled={UserService.isSuperAdminOrCustomerSuccessOrAccountOwner} />
						<this.MenuItem name="Widgets" route="/analytics/general/widgets" isEnabled={LocationService.isMessengerPermissible} />
					</div>
					<div className="settings__navbar__header">REVIEWS</div>
					<div className="settings__navbar__list">
						<this.MenuItem name="Summary" route="/analytics/reviews/summary" isEnabled={LocationService.isReviewsEnabled} />
						<this.MenuItem name="Location" route="/analytics/reviews/location" isEnabled={LocationService.isReviewsEnabled} />
						<this.MenuItem name="Sites" route="/analytics/reviews/sites" isEnabled={LocationService.isReviewsEnabled} />
						<this.MenuItem name="Insights" route="/analytics/reviews/insights" isEnabled={LocationService.isReviewsEnabled} />
						<this.MenuItem name="GMB" route="/analytics/reviews/gmbstats" isEnabled={LocationService.isReviewsEnabled} />
					</div>

					<div className="settings__navbar__header">INBOX</div>
					<div className="settings__navbar__list">
						<this.MenuItem name="Overview" route="/analytics/inbox" isEnabled={LocationService.isMessengerPermissible} />
						<this.MenuItem name="Contacts" route="/analytics/inbox/contacts" isEnabled={UserService.get().GroupPermission.view_contacts} />
						<this.MenuItem name="Campaigns" route="/analytics/inbox/campaigns" isEnabled={LocationService.isScheduledMessagesEnabled} />
						<this.MenuItem name="Keywords" route="/analytics/inbox/keywords" isEnabled={WorkflowService.isKeywordsEnabled} />
						<this.MenuItem name="Leaderboard" route="/analytics/inbox/leaderboard" isEnabled={LocationService.isLeaderboardEnabled} />
					</div>
				</div>
				<Switch>
					{LocationService.isMessengerPermissible() && <Route exact path="/analytics/general/widgets" render={props => <WidgetAnalyticsPage {...props} />} />}
					{ReengagementService.canViewReengagements() && (
						<Route exact path="/analytics/general/conversions" render={props => <ConversionAnalytics {...props} />} />
					)}

					{LocationService.isReviewsEnabled() && <Route exact path="/analytics/reviews/summary" render={props => <AnalyticsSummary {...props} />} />}
					{LocationService.isReviewsEnabled() && <Route exact path="/analytics/reviews/location" render={props => <LocationAnalytics {...props} />} />}
					{LocationService.isReviewsEnabled() && <Route exact path="/analytics/reviews/sites" render={props => <ReviewSitesAnalytics {...props} />} />}
					{LocationService.isReviewsEnabled() && <Route exact path="/analytics/reviews/insights" render={props => <ReviewsInsights {...props} />} />}
					{LocationService.isReviewsEnabled() && <Route exact path="/analytics/reviews/gmbstats" render={props => <GmbLocationStatsAnalytics {...props} />} />}

					{LocationService.isMessengerPermissible() && <Route exact path="/analytics/inbox" render={props => <MessengerAnalytics {...props} />} />}

					{LocationService.isScheduledMessagesEnabled() && <Route exact path="/analytics/inbox/campaigns" render={props => <CampaignAnalytics {...props} />} />}

					{UserService.get().GroupPermission.view_contacts && (
						<Route exact path="/analytics/inbox/contacts" render={props => <ContactAnalytics {...props} />} />
					)}

					{LocationService.isLeaderboardEnabled() && <Route exact path="/analytics/inbox/leaderboard" render={props => <Leaderboard {...props} />} />}
					{WorkflowService.isKeywordsEnabled() && <Route exact path="/analytics/inbox/keywords" render={props => <KeywordAnalytics {...props} />} />}

					<Route key={"not-found"} path={"/*"}>
						<NotFound />
					</Route>
				</Switch>
			</Container>
		);
	}
}

export default withTranslation(null, { withRef: true })(Analytics);
