import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import c3 from "c3";
import * as Icon from "react-feather";
import { CSVLink } from "react-csv";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate, parseDate } from "react-day-picker/moment";

import UserService from "../../../services/UserService";
import { CsvService } from "../../../services/CsvService";
import { AnalyticsService } from "../../../services/AnalyticsService";
import LocationService from "../../../services/LocationService";
import GmbService from "../../../services/GmbService";
import ReviewService from "../../../services/ReviewService";

import Spinners from "../../../components/common/Spinners";
import withLocation from "../../../components/common/WithLocation";
import Action from "../../../components/common/Action";
import DashboardCard from "../../Dashboard/DashboardCard";

import { REVIEW_SITES, REVIEW_SITE_IDS_BY_NAME } from "../../../constants/ReviewSitesConstants";
import { DASHBOARD_CARDS } from "../../../constants/Dashboard";

import "../../../App.css";
import "react-day-picker/lib/style.css";
import "../../../styles/css/scenes/analytics.css";

class ReviewSitesResults extends Component {
	constructor(props) {
		super(props);

		this.state = {
			noSitesFound: true,
			startDate: moment()
				.subtract(14, "days")
				.toDate(),
			endDate: moment().toDate(),
			siteId: REVIEW_SITE_IDS_BY_NAME.google,
			siteName: "",
			reviewSites: [],
			loading: true,
			fbActive: false,
			gmbActive: false,
			googleResultsCsv: null
		};
	}

	componentDidMount() {
		this.fetchAllReviewSites();
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	handleUpdateDate = event => {
		event.preventDefault();
		event.stopPropagation();

		this.updateData();
	};

	onLocationChanged = async () => {
		await this.update({
			startDate: moment()
				.subtract(14, "days")
				.toDate(),
			endDate: moment().toDate(),
			noSitesFound: true,
			siteId: REVIEW_SITE_IDS_BY_NAME.google,
			siteName: "",
			reviewSites: [],
			loading: true
		});

		this.fetchAllReviewSites();
	};
	handleReviewSiteOnChange = e => {
		const index = e.target.selectedIndex;
		const { value } = e.target;
		this.setState({ siteId: value, siteName: e.target[index].text }, () => this.updateData());
	};
	/**
	 * Get all the review sites available from database. We need this as the review_sites
	 * fetched from location table does not have review site name
	 */
	async fetchAllReviewSites() {
		try {
			const locationId = UserService.getActiveLocation().id;
			const allReviewSitesResponse = await ReviewService.getAllReviewSites();

			let gmbData = await GmbService.checkGmbAndFbStatus(locationId);

			this.setState({
				fbActive: gmbData.fb_active,
				gmbActive: gmbData.gmb_active
			});
			this.getReviewSitesForLocation(allReviewSitesResponse);
		} catch (error) {
			this.setState({ loading: false });
			console.log(error);
		}
	}
	/**
	 *
	 * @param {object} allReviewSites
	 * Get all the review sites configured for a location
	 */
	async getReviewSitesForLocation(allReviewSites) {
		const locationId = UserService.getActiveLocation().id;
		try {
			let data = await LocationService.fetchLocation(locationId);

			const reviewSites = [];

			if (data) {
				const reviewSitesForLocation = JSON.parse(data.review_sites || "[]");
				allReviewSites.forEach(aReviewSite => {
					const found = reviewSitesForLocation.find(aReviewSiteForLocation => aReviewSiteForLocation.site_id === aReviewSite.id);
					if (found) {
						reviewSites.push(aReviewSite);
					}
				});
			}

			if (reviewSites.length === 0) {
				this.setState({ noSitesFound: true, loading: false });
				return;
			}

			const google = reviewSites.find(aReviewSite => aReviewSite.id === REVIEW_SITES.google.id);

			await this.update({
				reviewSites,
				siteName: google ? google.name : reviewSites[0].name,
				siteId: google ? google.id : reviewSites[0].id,
				noSitesFound: false
			});

			this.updateData();
		} catch (error) {
			console.log(error);
			this.setState({ loading: false });
		}
	}
	/**
	 * update site results chart
	 * @param {object} insights
	 */
	async updateSiteData(insights) {
		await this.update({ loading: false });

		const { startDate, endDate } = this.state;

		const daysOrMonths = ["x"],
			siteAvgRating = ["rating"],
			siteReviewCount = ["reviews"];
		let time = "";
		if (moment(endDate).diff(moment(startDate), "days") > 90) {
			time = "Month";
		} else {
			time = "Day";
		}
		insights.site_ratings.forEach(aRating => {
			daysOrMonths.push(aRating.time);
			siteAvgRating.push(aRating.avg_rating);
			siteReviewCount.push(aRating.review_count);
		});

		let dateColumn = [...daysOrMonths];
		dateColumn[0] = "Date";

		let csvData = [dateColumn, siteReviewCount, siteAvgRating];
		csvData = csvData[0].map((col, i) => csvData.map(row => row[i])); // transpose the data

		this.setState({
			googleResultsCsv: csvData
		});

		c3.generate({
			bindto: "#review-site-results-ratings-chart",
			data: {
				x: "x",
				columns: [daysOrMonths, siteReviewCount, siteAvgRating],
				type: "bar",
				types: {
					rating: "line"
				},
				axes: {
					reviews: "y",
					rating: "y2"
				}
			},
			bar: {
				width: {
					ratio: 0.5 // this makes bar width 50% of length between ticks
				}
				// or
				//width: 100 // this makes bar width 100px
			},
			axis: {
				x: {
					label: {
						text: time,
						position: "outer-right"
					},
					type: "timeseries",
					tick: {
						format: function(x) {
							if (time === "Day") {
								return "" + x.getFullYear() + "-" + (x.getMonth() + 1) + "-" + x.getDate();
							}
							let monthString = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
							return monthString[x.getMonth()] + " " + x.getFullYear();
						},
						rotate: window.innerWidth > 768 ? 0 : 75,
						multiline: false
						//format: '%Y' // format string is also available for timeseries data
					}
				},
				y: {
					label: {
						text: "Review Count",
						position: "outer-top"
					}
				},
				y2: {
					show: true,
					label: { text: "Avg. Rating", position: "outer-top" },
					min: 0,
					max: 5,
					padding: { top: 0, bottom: 0 }
				}
			},
			size: {
				height: 260
			}
		});
	}
	async getDailyData() {
		try {
			const { startDate, endDate, siteId } = this.state;

			let data = await AnalyticsService.fetchReviewInsights({ locationId: UserService.getActiveLocation().id, startDate, endDate, siteId });

			return data;
		} catch (error) {
			console.log(error);
		}
	}
	async getMonthlyData() {
		try {
			const { startDate, endDate, siteId } = this.state;

			let data = await AnalyticsService.fetchMonthlyReviewInsites({ locationId: UserService.getActiveLocation().id, startDate, endDate, siteId });

			return data;
		} catch (error) {
			console.log(error);
		}
	}
	/**
	 * update review performance chart
	 */
	updateData = async () => {
		try {
			const { startDate, endDate } = this.state;

			this.setState({ loading: true });
			let insights = [];

			if (moment(endDate).diff(moment(startDate), "days") > 90) {
				insights = await this.getMonthlyData();
			} else {
				insights = await this.getDailyData();
			}

			this.updateSiteData(insights);
		} catch (error) {
			// Make an API call to get all the chats for the user's location
			console.log(error);
		}
		this.setState({ loading: false });
	};

	showFromMonth() {
		const { startDate, endDate } = this.state;
		if (!startDate) {
			return;
		}
		if (moment(endDate).diff(moment(startDate), "months") < 2) {
			this.to.getDayPicker().showMonth(startDate);
		}
	}

	handleFromChange = startDate => {
		// Change the from date and focus the "to" input field
		this.setState({ startDate });
	};

	handleToChange = endDate => {
		this.setState({ endDate }, this.showFromMonth);
	};

	renderDatePicker = () => {
		const { endDate, startDate } = this.state;
		const modifiers = { start: startDate, end: endDate };

		return (
			<div className="dashboard__card___datepicker" style={{ marginBottom: 0 }}>
				<label>Date Range&nbsp;&nbsp;</label>
				<div className="input-group" id="datepicker">
					<div className="InputFromTo">
						<DayPickerInput
							value={startDate}
							placeholder=" From"
							format="LL"
							formatDate={formatDate}
							parseDate={parseDate}
							dayPickerProps={{
								selectedDays: [startDate, { from: startDate, to: endDate }],
								disabledDays: { after: endDate },
								toMonth: endDate,
								modifiers,
								numberOfMonths: 2,
								onDayClick: () => this.to.getInput().focus()
							}}
							onDayChange={this.handleFromChange}
						/>{" "}
						<span className="InputFromTo-to">
							<DayPickerInput
								ref={el => (this.to = el)}
								value={endDate}
								placeholder=" To"
								format="LL"
								formatDate={formatDate}
								parseDate={parseDate}
								dayPickerProps={{
									selectedDays: [startDate, { from: startDate, to: endDate }],
									disabledDays: { before: startDate },
									modifiers,
									month: startDate,
									fromMonth: startDate,
									numberOfMonths: 2
								}}
								onDayChange={this.handleToChange}
							/>
						</span>
					</div>
				</div>{" "}
				<button id="date-update" className="mb-button" onClick={this.handleUpdateDate}>
					Update Date
				</button>
			</div>
		);
	};

	renderHeader = () => {
		const { siteName, siteId, reviewSites, googleResultsCsv } = this.state;
		const { cardName } = this.props;

		const locationName = CsvService.filterActiveLocationName();

		return (
			<>
				<div className="dashboard__card__header dashboard__card__header--full-width">
					<div className="dashboard__card__header__name">
						{cardName ? cardName : `${siteName} Results`}
						<div>
							{siteName === REVIEW_SITES.google.name && !this.state.gmbActive && (
								<div style={{ fontSize: 15 }}>Please connect to GMB now to get accurate data.</div>
							)}
							{siteName === REVIEW_SITES.facebook.name && !this.state.fbActive && (
								<div style={{ fontSize: 15 }}>Please connect to Facebook now to get accurate data.</div>
							)}
						</div>
					</div>
					<div className="dashboard__card__header__actions">
						{googleResultsCsv && (
							<CSVLink data={googleResultsCsv} filename={`${locationName}-google_results.csv`} target="_self">
								<Action label="Download" icon={Icon.Download} className="Common__csv-link">
									<Icon.Download />
								</Action>
							</CSVLink>
						)}
					</div>
				</div>

				<div className="analytics__section__chart--review-sites-results dashboard__card___datepicker">
					{this.renderDatePicker()}
					<div className="pull-right Analytics__graph--site">
						<select className="form-control ReviewSitesAnalytics__site--dropdown" value={siteId} onChange={this.handleReviewSiteOnChange}>
							{reviewSites &&
								reviewSites.map(aReviewSite => (
									<option name={aReviewSite.name} key={aReviewSite.name} value={aReviewSite.id}>
										{aReviewSite.name}
									</option>
								))}
						</select>
					</div>
				</div>
			</>
		);
	};

	renderBody = () => {
		const { noSitesFound, loading } = this.state;
		const { isEnabled, isPermissible } = this.props;

		if (isEnabled && !isEnabled()) {
			return <div className="text-center">Reviews is not enabled. Contact support to get started.</div>;
		}

		if (isPermissible && !isPermissible()) {
			return <div className="text-center">Review data is restricted.</div>;
		}

		if (loading) {
			return (
				<div className="analytics__section__chart__spinner">
					<Spinners type="tail-fade" loading={true} size="60px" />
				</div>
			);
		}

		if (noSitesFound) {
			return (
				<div>
					Sorry, no data was found{" "}
					<span role="img" aria-label="sad-face">
						😞
					</span>
				</div>
			);
		}

		return (
			<div>
				<div id="review-site-results-ratings-chart" />
			</div>
		);
	};

	render() {
		return (
			<DashboardCard className={DASHBOARD_CARDS.reviewSitesResults.className} height={DASHBOARD_CARDS.reviewSitesResults.h}>
				{this.renderHeader()}
				{this.renderBody()}
			</DashboardCard>
		);
	}
}

export default withRouter(withLocation(ReviewSitesResults));
