import React from "react";
import * as Icon from "react-feather";
import ContentLoader from "react-content-loader";

import { AnalyticsService } from "../../services/AnalyticsService";
import UserService from "../../services/UserService";
import SupportChatService from "../../services/SupportChatService";
import CrmIntegrationsService from "../../services/CrmIntegrationsService";

import withLocation from "../../components/common/WithLocation";
import DashboardCard from "./DashboardCard";
import List from "../../components/common/List";

import { DASHBOARD_CARDS } from "../../constants/Dashboard";

class ReengagementAttribution extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			data: [],
			hasCrmIntegrations: false
		};
	}

	componentDidMount() {
		this.resetComponent();
	}

	resetComponent = async () => {
		await this.fetchData();
	};

	onLocationChanged = () => {
		this.resetComponent();
	};

	fetchData = async () => {
		await this.update({ loading: true });

		let locationId = UserService.getActiveLocation().id;
		let attributionData = await AnalyticsService.getReengagementAttributionAnalytics({ locationId });

		let crmIntegrations = await CrmIntegrationsService.fetch({
			locationId,
			params: { limit: 1, status: ["active"] }
		});

		let data = [];
		if (attributionData) {
			data = attributionData;
		}

		await this.update({ data, hasCrmIntegrations: crmIntegrations && crmIntegrations.length > 0 ? true : false, loading: false });
	};

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	onGetStarted = () => {
		SupportChatService.showNewMessage("Hi, I would like to enable Reengagements. Would you be able to help?");
	};

	onRecordClicked = async item => {};

	getHeaders = () => {
		let headers = {
			items: {
				name: {
					id: "name",
					value: "Name",
					sortable: true,
					sortField: "name",
					width: 1,
					notClickable: true
				},
				message_count: {
					id: "message_count",
					value: "Messages Sent",
					sortable: true,
					sortField: "message_count",
					width: 1,
					notClickable: true
				},
				synced_appt_count: {
					id: "synced_appt_count",
					value: "Appointments",
					sortable: true,
					sortField: "synced_appt_count",
					width: 1,
					notClickable: true
				}
			}
		};

		return headers;
	};

	renderRecord = data => {
		return [data.name, data.message_count, data.synced_appt_count];
	};

	renderHeader = () => {
		const { cardName } = this.props;

		return (
			<div className="dashboard__card__header">
				<div className="dashboard__card__header__icon">
					<Icon.RefreshCw size="42" />
				</div>
				<div className="dashboard__card__header__name dashboard__card__header__name--margin-left">{cardName ? cardName : "Reengagement Attribution"}</div>
			</div>
		);
	};

	renderBody = () => {
		let { data, hasCrmIntegrations, loading } = this.state;
		const { isEnabled, isPermissible } = this.props;

		if (loading) {
			return (
				<ContentLoader height={190} width={"100%"}>
					<rect x="0" y="0" rx="5" ry="5" width="100%" height="190" />
				</ContentLoader>
			);
		}

		if ((isEnabled && !isEnabled()) || !hasCrmIntegrations) {
			return (
				<>
					<div className="dashboard__card--payments">
						<div className="dashboard__widget__reengagement-attribution dashboard__widget__reengagement-attribution--get-started">
							<div className="mb-button" onClick={this.onGetStarted}>
								Get Started
							</div>
						</div>
					</div>
					<div className="dashboard__widget__reengagement-attribution__text">Sync data with your CRM and send automated reengagement messages.</div>
				</>
			);
		}

		if (isPermissible && !isPermissible()) {
			return (
				<div className="dashboard__card--payments">
					<div className="dashboard__widget__reengagement-attribution dashboard__widget__reengagement-attribution--get-started">
						<div className="dashboard__widget__reengagement-attribution__text">Reengagement data restricted.</div>
					</div>
				</div>
			);
		}

		return (
			<div className="dashboard__widget__reengagement-attribution">
				<List
					items={data}
					loading={true}
					loadedAll={true}
					headers={this.getHeaders()}
					renderRecord={this.renderRecord}
					onRecordClicked={this.onRecordClicked}
					minHeight={145}
				/>
			</div>
		);
	};

	render() {
		return (
			<DashboardCard className={DASHBOARD_CARDS.reengagementAttribution.className} height={DASHBOARD_CARDS.reengagementAttribution.h}>
				{this.renderHeader()}
				{this.renderBody()}
			</DashboardCard>
		);
	}
}

export default withLocation(ReengagementAttribution);
