import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import c3 from "c3";
import { CSVLink } from "react-csv";
import * as Icon from "react-feather";

import { DATE_FORMAT } from "../../../constants/CommonConstants";
import Spinners from "../../../components/common/Spinners";
import withLocation from "../../../components/common/WithLocation";
import Action from "../../../components/common/Action";

import UserService from "../../../services/UserService";
import { CsvService } from "../../../services/CsvService";
import CompanyService from "../../../services/CompanyService";
import { AnalyticsService } from "../../../services/AnalyticsService";

import "../../../styles/css/scenes/analytics.css";
import { DASHBOARD_CARDS } from "../../../constants/Dashboard";
import DashboardCard from "../../Dashboard/DashboardCard";

class ReviewCountsOverYear extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			locations: [],
			chartData: [],
			reviewCountCsv: null
		};
	}

	resetComponent() {
		this.setAllLocationsForCompany();
	}

	componentDidMount() {
		this.resetComponent();
	}

	onLocationChanged = location => {
		this.resetComponent();
	};

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	/**
	 * for each location, get monthly review counts for chartData
	 */

	async getReviewCountChartData() {
		const { locations } = this.state;

		const allMonths = moment.monthsShort();
		const dates = [];

		for (let i = 1; i < allMonths.length; i++) {
			dates.push(
				moment()
					.subtract(1, "year")
					.add(i, "month")
					.endOf("month")
					.format(DATE_FORMAT)
			);
		}

		dates.push(moment().format(DATE_FORMAT));

		const ratingsChartDataPromiseList = [];

		locations.forEach(aLocation => {
			try {
				let data = AnalyticsService.getReviewCountsForDates({ locationId: aLocation.id, dates });
				if (data) {
					ratingsChartDataPromiseList.push(data);
				}
			} catch (error) {
				ratingsChartDataPromiseList.push({ failed: true, error });
			}
		});

		const ratingsChartDataPromiseListResolve = await Promise.all(ratingsChartDataPromiseList);

		let chartData = [];

		for (let i = 0; i < ratingsChartDataPromiseListResolve.length; i++) {
			if (ratingsChartDataPromiseListResolve[i].failed) {
				console.log(ratingsChartDataPromiseListResolve[i].error);
				continue;
			}

			const counts = [locations[i].name];
			ratingsChartDataPromiseListResolve[i].forEach(aData => {
				counts.push(aData.review_count);
			});

			chartData.push(counts);
		}

		await this.update({ chartData, loading: false });
		this.updateChart();
	}

	updateChart() {
		const allMonths = moment.monthsShort();
		const months = ["x"];

		//get last year's last date of month
		for (let i = 1; i <= allMonths.length; i++) {
			months.push(
				moment()
					.subtract(1, "year")
					.add(i, "month")
					.endOf("month")
					.toDate()
			);
		}

		this.setReviewCountCsv(months);

		c3.generate({
			bindto: "#review-count-over-year-chart",
			data: {
				x: "x",
				xFormat: "%MMM",
				columns: [months, ...this.state.chartData],
				type: "line"
			},
			axis: {
				x: {
					label: "Month",
					type: "timeseries",
					tick: {
						format: function(x) {
							return allMonths[x.getMonth()];
						}
					}
				},
				y: {
					label: "Reviews"
				}
			}
		});
	}

	setReviewCountCsv = months => {
		try {
			let dateColumn = [];
			for (let i = 0; i < months.length; i++) {
				let month = months[i];
				if (month === "x") {
					dateColumn.push("Date");
					continue;
				}
				month = moment(month).format("MMM");
				dateColumn.push(month);
			}
			this.setState({
				reviewCountCsv: [dateColumn, ...this.state.chartData]
			});
		} catch (error) {
			console.log(error);
		}
	};

	/**
	 * get all the locations for the company
	 */
	async setAllLocationsForCompany() {
		const locations = [];
		try {
			await this.update({ loading: true });

			let data = await CompanyService.fetchLocations({ companyId: UserService.getActiveCompany().id });

			if (!data) {
				data = [];
			}

			data.forEach(aLocation => locations.push({ id: aLocation.id, name: aLocation.name }));

			await this.update({ locations });
			await this.getReviewCountChartData();
		} catch (error) {
			console.log(error);
		}
	}

	renderHeader = () => {
		const { reviewCountCsv, loading } = this.state;
		const { isCard, cardName } = this.props;
		const locationName = CsvService.filterActiveLocationName();

		return (
			<div className="dashboard__card__header dashboard__card__header--full-width">
				<div className="dashboard__card__header__name">{cardName ? cardName : "Review Counts Over the Year"}</div>
				<div className="dashboard__card__header__actions">
					{reviewCountCsv && (
						<CSVLink data={reviewCountCsv} filename={`${locationName}-review_count_over_year.csv`} target="_self">
							<Action label="Download" icon={Icon.Download} className="Common__csv-link">
								<Icon.Download />
							</Action>
						</CSVLink>
					)}
				</div>
			</div>
		);
	};

	renderBody = () => {
		const { loading } = this.state;
		const { isEnabled, isPermissible } = this.props;

		if (isEnabled && !isEnabled()) {
			return <div className="text-center">Reviews is not enabled. Contact support to get started.</div>;
		}

		if (isPermissible && !isPermissible()) {
			return <div className="text-center">Review data is restricted.</div>;
		}

		if (loading) {
			return (
				<div className="analytics__section__chart__spinner">
					<Spinners type="tail-fade" loading={true} size="60px" />
				</div>
			);
		}

		return (
			<div>
				<div id="review-count-over-year-chart" />
			</div>
		);
	};

	render() {
		return (
			<DashboardCard className={DASHBOARD_CARDS.reviewCountsOverYear.className} height={DASHBOARD_CARDS.reviewCountsOverYear.h}>
				{this.renderHeader()}
				{this.renderBody()}
			</DashboardCard>
		);
	}
}

export default withRouter(withLocation(ReviewCountsOverYear));
