import React from "react";

import Page from "../../../components/common/Page";
import Header from "../../../components/common/Header";
import Tabs from "../../../components/common/Tabs";
import Tab from "../../../components/common/Tab";

import LeadSourceAnalytics from "../LeadSource/LeadSourceAnalytics";
import SubscriberAnalytics from "./SubscriberAnalytics";

import GAService from "../../../services/GAService";

import { CONTACT_ANALYTICS_TABS } from "../../../constants/Contacts";
import { GA_CATEGORIES, GA_ACTIONS } from "../../../constants/GAConstants";

class ContactAnalytics extends React.Component {
	state = {
		selectedTab: CONTACT_ANALYTICS_TABS.leadSource.id
	};

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
	}

	onTabSelect = tab => {
		GAService.GAEvent({
			category: GA_CATEGORIES.analytics.sections.contactAnalytics,
			action: GA_ACTIONS.generic.tabSelect,
			label: `Selected tab: ${tab.value}`
		});
		this.setState({ selectedTab: tab.id });
	};

	render() {
		let { selectedTab } = this.state;

		return (
			<Page>
				<Header title="Contact Analytics" />

				<Tabs onSelect={this.onTabSelect} selected={selectedTab}>
					<Tab id={CONTACT_ANALYTICS_TABS.leadSource.id} value={CONTACT_ANALYTICS_TABS.leadSource.value} />
					<Tab id={CONTACT_ANALYTICS_TABS.subscriber.id} value={CONTACT_ANALYTICS_TABS.subscriber.value} />
				</Tabs>
				{CONTACT_ANALYTICS_TABS.leadSource.id === selectedTab && <LeadSourceAnalytics />}
				{CONTACT_ANALYTICS_TABS.subscriber.id === selectedTab && (
					<div className="dh-page__cards">
						<SubscriberAnalytics />
					</div>
				)}
			</Page>
		);
	}
}

export default ContactAnalytics;
